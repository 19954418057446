import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/catalog.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import categoryHttpService from "../../../services/http/products/category.service";
import brandHttpService from "../../../services/http/products/brand.service";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
const CatalogList = () => {
  const { hasPermission } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const { auth } = store.getState();
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [eanFilter, seteanFilter] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const roles = auth.roles ?? [];
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getCatalogList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .catalogList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      ean: eanFilter,
      category_id: selectedCategoryId?.value,
      brand_id: selectedBrandId?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getCatalogList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getCatalogList();
    fetchCategory();
    fetchBrand();
  }, []);
  async function fetchCategory() {
    try {
      const Category = await categoryHttpService.categoriesList();
      setCategory(Category?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchBrand() {
    try {
      const brand = await brandHttpService.brandsList();
      setBrand(brand?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      setNameFilter("");
      seteanFilter("");
      setSelectedCategoryId(null);
      setSelectedBrandId(null);
      const filters = {};
      await getCatalogList(paginationParams.pagination, filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteCatalog(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("catalog.catalogDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getCatalogList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewCatalog = () => {
    navigate(all_routes.catalogCreate);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      sorter: true,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.main_product_image}
            alt={t("tableColumnName.productName")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.name}
        </div>
      ),
      sorter: true,
    },
    {
      title: t("tableColumnName.brand"),
      dataIndex: "catalog.brand.name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.brand?.image}
            alt={t("tableColumnName.brand")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {text}
        </div>
      ),
    },
    {
      title: t("tableColumnName.category"),
      dataIndex: "category_id",
      render: (text, record) => record?.category?.name,
      sorter: true,
    },
    ...(hasPermission(["supplier-view", "supplier-edit", "supplier-delete"])
      ? [
          {
            title: t("tableColumnName.actions"),
            key: "actions",
            render: (text, record) => (
              <td className="action-table-data">
                <div className="edit-delete-action">
                  {roles[0].permissions.some(
                    (permission) => permission.name === "catalog-view"
                  ) && (
                    <a
                      className="p-2"
                      onClick={() => navigate(`/catalog/${record.id}/details`)}
                    >
                      <i className="feather-eye"></i>
                    </a>
                  )}
                  {roles[0].permissions.some(
                    (permission) => permission.name === "catalog-edit"
                  ) && (
                    <a
                      className="p-2"
                      onClick={() => navigate(`/catalog/${record.id}/edit`)}
                    >
                      <i className="feather-edit-2 text-black"></i>
                    </a>
                  )}
                  {roles[0].permissions.some(
                    (permission) => permission.name === "catalog-delete"
                  ) && (
                    <ConfirmationAlert
                      onConfirm={() => onConfirmDelete(record.id)}
                    />
                  )}
                </div>
              </td>
            ),
          },
        ]
      : []),
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("catalog.catalog"), active: true },
  ];
  const categoryOptions = [
    { value: null, label: t("select.all") },
    ...(category.map((category) => ({
      value: category.id,
      label: category.name,
    })) || []),
  ];

  const brandOptions = [
    { value: null, label: t("select.all") },
    ...(brand.map((brand) => ({
      value: brand.id,
      label: brand.name,
    })) || []),
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("catalog.productCatalogs")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {roles[0].permissions.some(
              (permission) => permission.name === "catalog-add"
            ) && (
              <div className="page-btn">
                <button onClick={addNewCatalog} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterName")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder={t("inputField.placeholder.enterEAN")}
                          className="form-control form-control-sm formsearch-custom ps-2"
                          value={eanFilter}
                          onChange={(e) => seteanFilter(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleTableChange();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="input-blocks">
                        <Select
                          options={categoryOptions}
                          className="select custom-select"
                          placeholder={t(
                            "inputField.placeholder.chooseCategory"
                          )}
                          value={selectedCategoryId}
                          onChange={(selectedOption) =>
                            setSelectedCategoryId(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="input-blocks">
                        <Select
                          options={brandOptions}
                          className="select custom-select"
                          placeholder={t("inputField.placeholder.chooseBrand")}
                          value={selectedBrandId}
                          onChange={(selectedOption) =>
                            setSelectedBrandId(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default CatalogList;
