import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createCountry = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.country.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateCountry = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.country.update}`,
    true,
    data,
    json?false:true,
  );
};
const updateCountrySetting = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.settings.country.updateSetting}`,
    true,
    data,
    true,
  );
};
const countryList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.country.list}`, true, true, data);
};
const deleteCountry = async (id) => {
  const url = `${ENDPOINT.settings.country.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getCountry = async (id) => {
  const url = `${ENDPOINT.settings.country.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getCountry,
  createCountry,
  updateCountry,
  countryList,
  deleteCountry,
  updateCountrySetting
};
