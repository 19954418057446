import React, { useState } from "react";

const UnlimitToggle = (props) => {
  const { id, column,toggleActive,isActive,disabled } = props;
  return (
    <div className="unlimit-uikit-input">
      <div
        className={`status-toggle modal-status d-flex ${
          column ? "flex-column" : "align-items-center"
        }`}
      >
        <div className={`${column ? "mt-2" : "ms-2"}`}>
          <input
            className="check"
            type="checkbox"
            id={id}
            checked={isActive}
            onChange={toggleActive}
            disabled={disabled}
          />
          <label htmlFor={id} className="checktoggle" />
        </div>
      </div>
    </div>
  );
};

export default UnlimitToggle;
