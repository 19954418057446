import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/peoples/contact.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
const ContactList = () => {
  const { hasPermission,roleType } = useRoleAndPerm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  async function fetchOutlet() {
    try {
      const outlet = await outletHttpService.outletList();
      setOutlets(outlet?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  }

  const getContactList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .contactList(data)
      .then((response) => {
        if (response.status == 200) {
          setContacts(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      outlet_id: selectedOutletId?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getContactList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  
  useEffect(() => {
    fetchOutlet();
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);

    if (isFilterVisible) {
      setNameFilter("");
      setSelectedOutletId(null);
      const filters = {};
      await getContactList(paginationParams.pagination, filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteContact(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("contactList.contactListDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getContactList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewContact = () => {
    navigate(all_routes.contactCreate);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("inputField.label.phone"),
      dataIndex: "phone",
    },
    {
      title: t("inputField.label.email"),
      dataIndex: "email",
    },
    {
      title: t("inputField.label.contactType"),
      dataIndex: "phone",
      render: (text, record) => (record.contact ? record.contact.name : null),
    },
    {
      title: t("tableColumnName.outlets"),
      dataIndex: "outlet.name",
      hidden: roleType === "outlet",
      render: (text, record) => (
        <Link
        to={`/outlets/${record.outlet.id}/details`}
        className="me-2 edit-icon p-2 d-flex align-items-center me-2"
      >
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.outlet.image}
            alt={t("tableColumnName.vendor")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.outlet.name}
        </div>
        </Link>
      ),
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <Link
    //       to={`/vendor/${record.vendor.id}/view`}
    //       className=" d-flex align-items-center edit-icon p-2"
    //     >
    //       <div className="d-flex align-items-center">
    //         <ImageWithBasePath
    //           type="link"
    //           src={record.vendor.logo}
    //           alt={t("general.button.upload")}
    //           style={{ width: 30, height: 30, marginRight: 10 }}
    //         />
    //         {record.vendor.business_name}
    //       </div>
    //     </Link>
    //   ),
    // },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["contact-edit", "contact-delete"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["contact-edit"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/contact/${record.id}/edit`)}
              >
                <i className="feather-edit-2 text-black"></i>
              </a>
            )}
            {hasPermission(["contact-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("contactList.contactList"), active: true },
  ];

  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));

  const outletOptions = [
    { value: null, label: t("select.all") },
    ...(outlets?.map((outlet) => ({
      value: outlet.id,
      label: outlet.name,
    })) || []),
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("contactList.contactList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["contact-add"]) && (
              <div className="page-btn">
                <button onClick={addNewContact} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
            <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 col-12 unlimit-uikit-input">
                      <input
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterContactName"
                        )}
                        className="form-control form-control-sm formsearch-custom ps-2 "
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12">
                      <div className="unlimit-uikit-select">
                        <Select
                          options={outletOptions}
                          className="select "
                          placeholder={t("inputField.label.selectSupplier")}
                          value={selectedOutletId}
                          onChange={(selectedOption) =>
                            setSelectedOutletId(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto"
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={contacts}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default ContactList;
