import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export default function Error403(){
    const navigate = useNavigate();
    return(
        <>
        <div className="page-wrapper">
        <Result
        status="403"
        title="403"
        className="mt-md-5 mt-3"
        subTitle={
            <>
            <p className="fs-5 fw-bold mb-2">Access Denied</p>
        <p className="mb-2 fw-bold">You dont have permission to access this page.</p>
        <p>If you believe this is an error, please contact the site administrator.</p>
            </>
        }
        extra={<Button type="primary" onClick={()=>navigate("/")}>Home Page</Button>}
        />
        </div>
        </>
    )
}