import React from "react";

const UnlimitButton = ({ text,isLoading, onClick, disabled }) => {
  return (
    <button
      className="btn btn-submit me-2"
      disabled={isLoading || disabled}
      onClick={onClick}
    >
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      <span className="ms-1">{text}</span>
    </button>
  );
};

export default UnlimitButton;
