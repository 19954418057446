import React from "react";
import { Link } from "react-router-dom/dist";

const UnlimitHyperlink = (props) => {
  const {
    isLoading,
    label,
    onClick,
    disabled,
    type,
    to,
    className,
    style,
    ...attrs
  } = props;
  let template;
  if (type === "external-link") {
    template = (
      <a className={`w-100 ${className}`} href={to} {...attrs}>
        label
      </a>
    );
  } else if (type === "router-link") {
    template = (
      <Link
        className={`w-100 ${className}`}
        {...attrs}
        to={to}
        disabled={disabled}
      ></Link>
    );
  } else if (type === "button")
    template = (
      <button
        className={`w-100 ${className}`}
        disabled={disabled}
        onClick={onClick}
        {...attrs}
      ></button>
    );
  return (
    <div className="unlimit-uikit-hyperlink" style={style}>
      {template}
    </div>
  );
};

export default UnlimitHyperlink;
