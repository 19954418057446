import React from 'react';
import { base_path } from "../../environment";
const UnlimitImageWithDetails = ({ src, name, size, removeFunction,isPDF }) => {
  return (
    <div className="border rounded d-flex justify-content-between align-items-center p-md-3 py-md-2" style={{ width: "fit-content" }}>
      {src && (
        <img
          src={!isPDF ? src : `${base_path}assets/img/pdf-icon.webp`}
          alt="Attachment"
          className="img-fluid p-2"
          style={{ maxWidth: "70px" }}
        />
      )}
      <div className="d-flex flex-column ms-2">
        <span className="fw-bold fs-6">{name}</span>
        <span>{size}</span>
      </div>
      <span className="text-red fs-5 ms-md-5 ms-3 cursor-pointer" onClick={removeFunction}>x</span>
    </div>
  );
};

export default UnlimitImageWithDetails;
