import React from "react";

const PDFViewer = ({ pdfUrl }) => {
  return (
    <div>
      <iframe
        src={pdfUrl}
        width="100%"
        height="600px"
        frameBorder="0"
        title="PDF Viewer"
      ></iframe>
    </div>
  );
};

export default PDFViewer;
