import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import httpService from "../services/http/peoples/vendor.service";
const ConfirmationAlertDeleteAttachments = ({id,get}) => {
  const onConfirm = async () => {
    await httpService
      .deletAttachment(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Document has been delete successfully.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          get()
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <a
      className="confirm-text p-2"
      onClick={() => {
        showConfirmationAlert();
      }}
    >
      <i className="feather-trash-2"></i>
    </a>
  );
};

export default ConfirmationAlertDeleteAttachments;
