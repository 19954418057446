import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createPurchase = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.purchase.purchase.create}`,
    true,
    data,
    true,
  );
};
const uploadPurchaseCSV = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.purchase.purchase.upload}`,
    true,
    data,
    false,
  );
};
const updatePurchase = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.purchase.purchase.update}`,
    true,
    data,
    true,
  );
};
const purchaseList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.purchase.purchase.list}`,
    true,
    true,
    data,
  );
};
const deletePurchase = async (id) => {
  const url = `${ENDPOINT.purchase.purchase.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getPurchase = async (id) => {
  const url = `${ENDPOINT.purchase.purchase.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const getPurchaseItems = async (id) => {
  const url = `${ENDPOINT.purchase.purchase.showItems}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getPurchase,
  createPurchase,
updatePurchase,
  purchaseList,
  deletePurchase,
  uploadPurchaseCSV,
  getPurchaseItems
};
