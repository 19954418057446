import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createPriceLine = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.priceLine.create}`,
    true,
    data,
    true,
  );
};
const createPriceLineFromPurchase = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.priceLine.createFromPurchase}`,
    true,
    data,
    false,
  );
};
const updatePriceLine = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.priceLine.update}`,
    true,
    data,
    true,
  );
};
const priceLineList = async (data) => {
  return await API.getMethod(`${ENDPOINT.products.priceLine.list}`, true, true, data);
};
const deletePriceLine = async (id) => {
  const url = `${ENDPOINT.products.priceLine.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getPriceLineItems = async (data,id) => {
  const url = `${ENDPOINT.products.priceLine.show}`.replace(":id", id);
  return await API.getMethod(url, true,true,data);
};
export default {
  getPriceLineItems,
  createPriceLine,
  updatePriceLine,
  priceLineList,
  deletePriceLine,
  createPriceLineFromPurchase
};
