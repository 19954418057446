import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  Filter,
  Plus,
  PlusCircle,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import { DatePicker } from "antd";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/product-group.service";
import { useNavigate } from "react-router-dom";
import usePagination from "../../../custom-hooks/usePagination";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import ConfirmationAlert from "../../../components/confirm-alert";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useSelector } from "react-redux";
const ProductGroupList = () => {
  const { hasPermission,roleType } = useRoleAndPerm();
  const { productGroupCreate } = all_routes;
  const [nameFilter, setNameFilter] = useState("");
  const [numberFilter, setNumberFilter] = useState("");
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  /*const [tableParams, setTableParams] = useState({
                pagination: {
                  current: 1,
                  pageSize: 10,
                },
              });*/
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      setNumberFilter("");
      setNameFilter("");
      const filters = {};
      await getProductGroupsList(paginationParams.pagination, filters);
    }
  };

  const navigate = useNavigate();
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.groupName"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("tableColumnName.Number"),
      dataIndex: "number",
    },
    {
      title: t("tableColumnName.productCount"),
      dataIndex: "productCount",
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <Link
    //       to={`/vendor/${record.vendor?.id}/view`}
    //       className=" d-flex align-items-center edit-icon p-2"
    //     >
    //       <ImageWithBasePath
    //         type="link"
    //         src={record.vendor?.logo}
    //         alt="Contact"
    //         style={{ width: 30, height: 30, marginRight: 10 }}
    //       />
    //       {record?.vendor?.business_name}
    //     </Link>
    //   ),
    // },
    ...(hasPermission(["product-group-edit", "product-group-delete"])
      ? [
          {
            title: t("tableColumnName.actions"),
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
              <td className="action-table-data">
                <div className="edit-delete-action">
                  {hasPermission(["product-group-edit"]) && (
                    <a
                      className="p-2"
                      onClick={() => navigate(`/product-group/${record.id}/details`)}
                    >
                      <i className="feather-eye"></i>
                    </a>
                   )}
                  {hasPermission(["product-group-edit"]) && (
                    <a
                      className="me-2 p-2"
                      onClick={() =>
                        navigate(`/product-group/${record.id}/edit`)
                      }
                    >
                      <i className="feather-edit-2"></i>
                    </a>
                  )}
                  {hasPermission(["product-group-delete"]) && (
                    <ConfirmationAlert
                      onConfirm={() => onConfirmDelete(record.id)}
                    />
                  )}
                </div>
              </td>
            ),
          },
        ]
      : []),
  ];
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(false);
  const [productGroups, setProductGroups] = useState([]);
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteProductGroup(id)
      .then((response) => {
        if (response.status == 200) {
          MySwal.fire({
            title: t("general.prompts.deleted"),
            text: t("productGroup.productGroupDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getProductGroupsList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getProductGroupsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .productGroupList(data)
      .then((response) => {
        if (response.status == 200) {
          setProductGroups(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      number: numberFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getProductGroupsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("productGroup.productGroup"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("productGroup.productGroup")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["product-group-add"]) && (
              <div className="page-btn">
                <Link to={productGroupCreate} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </Link>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
            <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterName")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder={t("inputField.placeholder.enterNumber")}
                          className="form-control form-control-sm formsearch-custom ps-2"
                          value={numberFilter}
                          onChange={(e) => setNumberFilter(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleTableChange();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={productGroups}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGroupList;
