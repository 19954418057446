import React, { useEffect, useState } from "react";
import distributionHttpService from "../../../services/http/inventory/distribution.service";
import purchaseHttpService from "../../../services/http/purchases/purchase.service";
import { Link, useNavigate } from "react-router-dom";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitButton from "../../../components/ui-kit/unlimit-button";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../Router/all_routes";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
const DistributionFromMainStockSearch = ({
  setPurchaseItemsList,
  setFilteredItemsList,
  setErrMessage,
  setOutletId,
}) => {
  const [loading, setLoading] = useState(false);
  const { roleType } = useRoleAndPerm();
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseIsLoading, setPurchaseIsLoading] = useState(false);
  const [purchase, setPurchase] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const { register, handleSubmit, formState, control, setValue, watch } =
    useForm();
  const vendors = useSelector((state) => state.vendors.vendors);
  const { errors } = formState;
  const { t } = useTranslation();

  const getPurchaseList = async (id) => {
    setLoading(true);
    await distributionHttpService
      .getMainStockItems(id, selectedVendor.id)
      .then((response) => {
        if (response.status === 200) {
          setPurchaseItemsList(response.data?.data?.data);
          setErrMessage("No results found");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function fetchPurchase(vendorID) {
    const data = {
      vendor_id: vendorID,
    };
    try {
      const purchase = await purchaseHttpService.purchaseList(data);
      setPurchase(purchase?.data?.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setPurchaseIsLoading(false);
    }
  }

  useEffect(() => {
    setVendor(vendors?.data?.data);
    if (roleType === "outlet") {
      setPurchaseIsLoading(true);
      fetchPurchase();
    }
  }, []);

  const onSubmitForm = async (data) => {
    setOutletId(data.outlet_id);
    try {
      setIsLoading(true);
      await getPurchaseList(data.purchase_id?.id);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedPurchaseId = watch("purchase_id");

  useEffect(() => {
    setFilteredItemsList([]);
  }, [selectedPurchaseId, purchase]);
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setPurchaseIsLoading(true);
    fetchPurchase(selectedOption.id);
  };
  const purchaseOptions = purchase?.map((purchase) => ({
    ...purchase,
    logo:purchase?.supplier?.image,
    value: purchase.id,
    label: purchase.order_no + " " +"(" +moment(purchase?.date).format("DD MMM YYYY") + ")",
  }));
  return (
    <div>
      {/* /Search */}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="card">
          <div className="card-body">
            <div className="row mb-2">
            {roleType !== "outlet" && (
                <div className="col-md-4">
                  <UnlimitSelect
                    label={t("vendor.vendor")}
                    id="vendor_id"
                    errors={errors}
                    control={control}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    placeholder={t("inputField.placeholder.chooseVendor")}
                    options={vendor}
                    showLogo={true}
                    onChange={handleVendorChange}
                    optionLabel="business_name"
                    optionValue="id"
                  />
                </div>
              )}

              <div className="col-md-4">
                <UnlimitSelect
                  label={t("tableColumnName.purchaseRefernce")}
                  id="purchase_id"
                  errors={errors}
                  control={control}
                  register={register}
                  isLoading={purchaseIsLoading}
                  options={purchaseOptions}
                  showLogo={true}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t(
                    "inputField.placeholder.selectpurchaseRefernce"
                  )}
                />
              </div>
              <div className="col-md-4">
                <UnlimitSelect
                  label={t("outlet.outlet")}
                  id="outlet_id"
                  errors={errors}
                  control={control}
                  register={register}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t("inputField.placeholder.chooseOutlet")}
                  options={selectedVendor?.outlet}
                  isDisabled={
                    !(selectedVendor.outlet && selectedVendor.outlet.length > 0)
                  }
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
            </div>
            <Link to={all_routes.distributionList}>
              <button type="button" className="btn btn-cancel me-2">
                {t("general.button.cancel")}
              </button>
            </Link>
            <UnlimitButton
              text={t("general.button.search")}
              isLoading={isLoading}
            />
            {/* /Filter */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DistributionFromMainStockSearch;
