import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import {
  Filter,
  Plus,
  PlusCircle,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/uom.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import { store } from "../../../core/redux/store";
import useRoleAndPerm from "../../../utils/permissionFunction";
const UomList = () => {
  const { hasPermission } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [uomList, setUomList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const { t } = useTranslation();
  const { locations, auth } = store.getState();
  const roles = auth.roles ?? [];
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} ${t('pagination.of')} ${total} ${t('pagination.items')}`,
    },
  });
  const navigate = useNavigate();
  const getUOMList = async (pagination = paginationParams.pagination,filters,sorter = {}) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .uomList(data)
      .then((response) => {
        if (response.status == 200) {
          setUomList(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getUOMList(pagination,nonNullFilters,sorter);
  };
  useEffect(() => {
    getUOMList();
  }, []);

  const { uomCreate } = all_routes;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if(isFilterVisible){
      setNameFilter("")
      const filters = {};
      await getUOMList(paginationParams.pagination,filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteUOM(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("UOM.UOMDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getUOMList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const columns = [
    {
      title: t('tableColumnName.srNo'),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: "ID",
      dataIndex: "id",
      sorter:true
    },
    {
      title: t('tableColumnName.UOMName'),
      dataIndex: "name",
      sorter:true
    },
    {
      title: t('tableColumnName.shortCode'),
      dataIndex: "code",
      sorter:true
    },
    {
      title: t('tableColumnName.createdAt'),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t('tableColumnName.createdBy'),
      dataIndex: "created_by",
      render: (outlet, record) =>
        record.created_by ? record.created_by.name : null,
    },
    {
      title: t('tableColumnName.status'),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t('general.actions.activate')}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t('general.actions.deactive')}</a>
            </span>
          );
        }
      },
    },
    ...(hasPermission(["uom-edit", "uom-delete"]) ? [{
      title: t('tableColumnName.actions'),
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
          {hasPermission(["uom-edit"]) && (
            <a
              className="me-2 p-2"
              onClick={() => navigate(`/uom/${record.id}/edit`)}
            >
              <i className="feather-edit-2"></i>
            </a>
            )}
            {hasPermission(["uom-delete"]) && (
            <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    }]:[])
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('UOM.UOM'), active: true },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t('UOM.UOM')}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["uom-add"]) && (
            <div className="page-btn">
              <Link to={uomCreate} className="btn btn-added">
                <Plus className="me-2 iconsize" />
                {t('general.button.addNew')}
              </Link>
            </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                  <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t('inputField.placeholder.enterUOMName')}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={uomList}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default UomList;
