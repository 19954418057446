import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const salesList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.sales.sales.list}`,
    true,
    true,
    data,
  );
};
const getSales = async (id) => {
  const url = `${ENDPOINT.sales.sales.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const getSalesItems = async (id) => {
  const url = `${ENDPOINT.sales.sales.showItems}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getSales,
  salesList,
  getSalesItems
};
