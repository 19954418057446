import React, { useState } from "react";

const UnlimitStepper = ({ steps, activeStep , formData,setFormData ,setActiveStep ,existingData,isLoading }) => {


  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepSubmit = (data) => {
    if (activeStep < steps.length - 1) {
      handleNext();
    }
  };

  // Function to remove "active" class from the last step's before pseudo-element
  const removeActiveClassFromBefore = () => {
    const tabs = document.querySelectorAll(".nav-link");
    tabs.forEach((tab, index) => {
      if (index === steps.length - 1) {
        tab.classList.remove("active-before");
      }
    });
  };

  return (
    <div>
      <ul className="nav nav-tabs justify-content-center mb-5 tab-style-3">
        {steps.map((step, index) => (
          <li className="nav-item me-5" role="presentation" key={index}>
            <button
              className={`nav-link ${activeStep >= index ? "active" : ""}`}
              // data-bs-toggle="tab"
              // onClick={() => {
              //   setActiveStep(index);
              //   removeActiveClassFromBefore();
              // }}
              data-tab-number={(index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2})}
            >
              {step.title}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {steps.map((step, index) => (
          <div
            className={`tab-pane fade ${
              activeStep === index ? "show active" : ""
            }`}
            role="tabpanel"
            key={index}
          >
            <step.component
              onSubmit={handleStepSubmit}
              handleNext={handleNext}
              formData={formData}
              setFormData={setFormData}
              handlePrevious={handlePrevious}
              existingData={existingData}
              isLoading={isLoading}
            />
          </div>
        ))}
      </div>
      <div>
        {/* {activeStep !== 0 && <button className="btn btn-cancel" onClick={handlePrevious}>Previous</button>} */}
        {/* {activeStep !== steps.length - 1 && (
          <button className="btn btn-submit ms-3" onClick={handleNext}>Next</button>
        )} */}
      </div>
    </div>
  );
};

export default UnlimitStepper;
