import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import UnlimitHyperlink from "./ui-kit/unlimit-hyperlink";
import { useTranslation } from "react-i18next";

const ConfirmationAlertDeactivate = ({
  title,
  text,
  onConfirm,
  onCancel,
  isActive,
}) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: title || t("general.prompts.confirmationTitle"),
      text: text || t("general.prompts.confirmationText", { action: isActive ? t("general.actions.activate") : t("general.actions.deactivate") }),
      showCancelButton: true,
      cancelButtonText: t("general.button.cancel"),
      confirmButtonText: t("general.button.confirm"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      } else {
        onCancel && onCancel();
        MySwal.close();
      }
    });
  };

  return (
    <UnlimitHyperlink
      className={`btn ${isActive ? "btn-primary" : "btn-danger"}`}
      onClick={showConfirmationAlert}
      type="button"
      style={{ flex: 1 }}
    >
      {isActive ? t("general.actions.activate") : t("general.actions.deactivate")}
    </UnlimitHyperlink>
  );
};

export default ConfirmationAlertDeactivate;
