import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  SystemLangauge: [],
};

export const systemLanguagesSlice = createSlice({
  name: "systemLanguages",
  initialState,
  reducers: {
    setSystemLangauge: (state, action) => {
      state.SystemLangauge = action.payload;
    },
  },
});

export const { setSystemLangauge, syncSystemLangauge } = systemLanguagesSlice.actions;

export default systemLanguagesSlice.reducer;
