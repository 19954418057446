import React, { useState } from "react";
import httpService from "../../../services/http/products/price-line.service";
import usePagination from "../../../custom-hooks/usePagination";
import { Link, useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useForm } from "react-hook-form";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { useTranslation } from "react-i18next";
import PriceLineFromPurchaseSearch from "./price-line-from-purchase-search";
import { hydrateErrors } from "../../../utils/error.utils";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

const PriceLineFromPurchase = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseItemsList, setPurchaseItemsList] = useState([]);
  const [prices, setPrices] = useState({});
  const { control, handleSubmit, register, formState, setError } = useForm();
  const { errors } = formState;
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();

  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  const handlePriceChange = (index, value) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [`price_${index}`]: value,
    }));
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt={t("tableColumnName.productName")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.catalog?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.quantity"),
      dataIndex: "quantity",
      align:"center"
    },
    {
      title: t("tableColumnName.unitCost"),
      dataIndex: "unit_cost",
      align: 'right',
      render: (text, record) =>  Number(text)?.toFixed(2),
    },
    {
      title: t("tableColumnName.discount"),
      dataIndex: "discount",
      align: 'right',
      render: (text, record) =>  Number(text)?.toFixed(2),
    },
    {
      title: t("inputField.label.vat"),
      dataIndex: "vat",
      align: 'center',
      render: (text, record) =>  Number(text)?.toFixed(2),
    },
    {
      title: t("tableColumnName.unitSalePrice"),
      dataIndex: "price",
      render: (text, record, index) => (
        <UnlimitInput
          id={`price_${index}`}
          type="number"
          defaultValue={record?.sale_price}
          register={register}
          rules={{
            required: t("inputField.validation.required"),
            maxLength: {
              value: 9,
              message: t("inputField.validation.maxLength", { value: 9 }),
            },
          }}
          error={errors?.[`price_${index}`]}
          errorMessage={errors?.[`price_${index}`]?.message}
          onChange={(e) => handlePriceChange(index, e.target.value)}
          min="0"
          step="0.0001"
        />
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("priceLine.priceLine"), link: "/price-line-list" },
    { label: t("priceLine.addPriceLineFromPurchase"), active: true },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const priceline = purchaseItemsList.map((item, index) => {
      const price = prices[`price_${index}`] || item?.sale_price;
      return {
        ean: item.ean,
        purchase_item_id: item.id,
        sale_price: price,
      };
    });

    const payload = {
      priceline: priceline,
    };

    setPurchasePrices(JSON.stringify(payload));
  };

  const setPurchasePrices = async (formData) => {
    try {
      await httpService
        .createPriceLineFromPurchase(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              t("priceLine.priceLineCreateSuccess")
            );
            navigate(all_routes.priceLinelist);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("priceLine.priceLine")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <PriceLineFromPurchaseSearch
            setPurchaseItemsList={setPurchaseItemsList}
            setLoading={setLoading}
          />
          {/* /Search */}
          {purchaseItemsList?.length > 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card table-list-card">
                <div className="card-body">
                  {/* /Filter */}
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={purchaseItemsList}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <Link to={all_routes.priceLinelist} className="me-2">
                  <button type="button" className="btn btn-cancel">
                    {t("general.button.cancel")}
                  </button>
                </Link>
                <UnlimitSubmitButton
                  isLoading={isLoading}
                  label={t("general.button.submit")}
                />
              </div>
            </form>
          )}
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default PriceLineFromPurchase;
