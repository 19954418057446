import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";

const ConfirmationAlert = ({ title, text, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = () => {
    MySwal.fire({
      title: title || t("general.actions.areyousure"),
      text: text || t("general.prompts.confirmRevert"),
      showCancelButton: true,
      cancelButtonText: t("general.button.cancel"),
      confirmButtonText: t("general.button.confirm"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm && onConfirm();
      } else {
        onCancel && onCancel();
        MySwal.close();
      }
    });
  };

  return (
    <a
      className="confirm-text p-2"
      onClick={() => {
        showConfirmationAlert();
      }}
    >
      <i className="feather-trash-2"></i>
    </a>
  );
};

export default ConfirmationAlert;
