export const API_URL = `${process.env.REACT_APP_BASE_URL}/api`;

export const ENDPOINT = {
  login: `${API_URL}/login`,
  users: {
    signUp: `${API_URL}/users`,
    forgetPassword: `${API_URL}/users/forgot_password`,
    resetPassword: `${API_URL}/users/reset_user_password`,
    updatePassword: `${API_URL}/update-password`,
    sendOtp: `${API_URL}/send/otp`,
    verifyOtp: `${API_URL}/verify/otp`,
    setNewPassword: `${API_URL}/new/password`,
    currentUserProfile: `${API_URL}/users/open_current_user`,
    verificationOtp: `${API_URL}/users/verify_otp`,
    updateUser: `${API_URL}/users/update_user`,
  },
  logout: `${API_URL}/logout`,
  locations: {
    countries_list: `${API_URL}/location/country/list`,
    cities_list: `${API_URL}/location/country/city/list`,
    cities_by_country: `${API_URL}/location/country/city/listByCountry/:id`,
  },
  products: {
    brands: {
      create: `${API_URL}/brand/create`,
      update: `${API_URL}/brand/update`,
      list: `${API_URL}/brand/list`,
      delete: `${API_URL}/brand/delete/:id`,
      show: `${API_URL}/brand/show/:id`,
    },
    product: {
      create: `${API_URL}/product/create`,
      fromCatalog: `${API_URL}/product/from/catalog`,
      update: `${API_URL}/product/update`,
      list: `${API_URL}/product/list`,
      delete: `${API_URL}/product/delete/:id`,
      show: `${API_URL}/product/show/:id`,
      exportAs: `${API_URL}/product/export`,
    },
    campaign: {
      create: `${API_URL}/campaign/create`,
      fromCatalog: `${API_URL}/campaign/from/catalog`,
      update: `${API_URL}/campaign/update`,
      list: `${API_URL}/campaign/list`,
      delete: `${API_URL}/campaign/delete/:id`,
      show: `${API_URL}/campaign/show/:id`,
    },
    productType: {
      create: `${API_URL}/product/type/create`,
      update: `${API_URL}/product/type/update`,
      list: `${API_URL}/product/type/list`,
      delete: `${API_URL}/product/type/delete/:id`,
      show: `${API_URL}/product/type/show/:id`,
    },
    tagType: {
      create: `${API_URL}/tag/type/create`,
      update: `${API_URL}/tag/type/update`,
      list: `${API_URL}/tag/type/list`,
      delete: `${API_URL}/tag/type/delete/:id`,
      show: `${API_URL}/tag/type/show/:id`,
    },
    pledgePrice: {
      create: `${API_URL}/pledge/type/create`,
      update: `${API_URL}/pledge/type/update`,
      list: `${API_URL}/pledge/type/list`,
      delete: `${API_URL}/pledge/type/delete/:id`,
      show: `${API_URL}/pledge/type/show/:id`,
    },
    variants: {
      create: `${API_URL}/variant/create`,
      update: `${API_URL}/variant/update`,
      list: `${API_URL}/variant/list`,
      delete: `${API_URL}/variant/delete/:id`,
      show: `${API_URL}/variant/show/:id`,
    },
    tag: {
      create: `${API_URL}/tag/type/create`,
      update: `${API_URL}/tag/type/update`,
      list: `${API_URL}/tag/type/list`,
      delete: `${API_URL}/tag/type/delete/:id`,
      show: `${API_URL}/tag/type/show/:id`,
    },
    catalog: {
      create: `${API_URL}/catalog/create`,
      update: `${API_URL}/catalog/update`,
      updateAttachment: `${API_URL}/catalog/attachment/update`,
      list: `${API_URL}/catalog/list`,
      GS1list: `${API_URL}/catalog/gs1/list`,
      search: `${API_URL}/catalog/search`,
      delete: `${API_URL}/catalog/delete/:id`,
      show: `${API_URL}/catalog/show/:id`,
      updateSalePrice: `${API_URL}/price_line/:id/update-price`,
    },
    categories: {
      create: `${API_URL}/category/create`,
      update: `${API_URL}/category/update`,
      list: `${API_URL}/category/list`,
      delete: `${API_URL}/category/delete/:id`,
      show: `${API_URL}/category/show/:id`,
    },
    UOM: {
      create: `${API_URL}/uom/create`,
      update: `${API_URL}/uom/update`,
      list: `${API_URL}/uom/list`,
      delete: `${API_URL}/uom/delete/:id`,
      show: `${API_URL}/uom/show/:id`,
    },
    priceLine: {
      create: `${API_URL}/price_line/create`,
      createFromPurchase: `${API_URL}/price_line/import`,
      update: `${API_URL}/price_line/update`,
      list: `${API_URL}/price_line/list`,
      delete: `${API_URL}/price_line/delete/:id`,
      show: `${API_URL}/price_line/list-by-catalog/catalog/:id`,
    },
    attributes: {
      create: `${API_URL}/attribute/create`,
      update: `${API_URL}/attribute/update`,
      list: `${API_URL}/attribute/list`,
      delete: `${API_URL}/attribute/delete/:id`,
      show: `${API_URL}/attribute/show/:id`,
    },
    productGroup: {
      create: `${API_URL}/product/group/create`,
      update: `${API_URL}/product/group/update`,
      list: `${API_URL}/product/group/list`,
      delete: `${API_URL}/product/group/delete/:id`,
      show: `${API_URL}/product/group/show/:id`,
    },
    productGroupList: {
      create: `${API_URL}/product/group/list/create`,
      update: `${API_URL}/product/group/list/update`,
      list: `${API_URL}/product/group/list/list`,
      delete: `${API_URL}/product/group/list/delete/:id`,
      show: `${API_URL}/product/group/list/show/:id`,
    },
  },
  thirdParty: {
    thirdParty: {
      create: `${API_URL}/third-party/create`,
      update: `${API_URL}/third-party/update`,
      list: `${API_URL}/third-party/list`,
      delete: `${API_URL}/third-party/delete/:id`,
      show: `${API_URL}/third-party/show/:id`,
    },
  },
  loyalty: {
    loyaltySettings: {
      create: `${API_URL}/loyalty-point-setting/create`,
      update: `${API_URL}/loyalty-point-setting/update`,
      list: `${API_URL}/loyalty-point-setting/list`,
      delete: `${API_URL}/loyalty-point-setting/delete/:id`,
      show: `${API_URL}/loyalty-point-setting/show/:id`,
    },
  },
  peoples: {
    designations: {
      create: `${API_URL}/designation/create`,
      update: `${API_URL}/designation/update`,
      list: `${API_URL}/designation/list`,
      delete: `${API_URL}/designation/delete/:id`,
      show: `${API_URL}/designation/show/:id`,
    },
    supplier: {
      create: `${API_URL}/supplier/create`,
      createAttachments: `${API_URL}/supplier/add/attachment`,
      update: `${API_URL}/supplier/update`,
      list: `${API_URL}/supplier/list`,
      delete: `${API_URL}/supplier/delete/:id`,
      show: `${API_URL}/supplier/show/:id`,
    },
    employees: {
      create: `${API_URL}/employee/create`,
      createAttachments: `${API_URL}/employee/add/attachment`,
      update: `${API_URL}/employee/update`,
      list: `${API_URL}/employee/list`,
      delete: `${API_URL}/employee/delete/:id`,
      show: `${API_URL}/employee/show/:id`,
      document: {
        create: `${API_URL}/employee/document/create`,
        update: `${API_URL}/employee/document/update`,
        list: `${API_URL}/employee/document/list`,
        delete: `${API_URL}/employee/delete/document/:id`,
        show: `${API_URL}/employee/show/document/:id`,
      },
    },
    contact: {
      create: `${API_URL}/contact/create`,
      update: `${API_URL}/contact/update`,
      list: `${API_URL}/contact/list`,
      delete: `${API_URL}/contact/delete/:id`,
      show: `${API_URL}/contact/show/:id`,
    },
    customer: {
      create: `${API_URL}/customer/create`,
      update: `${API_URL}/customer/update`,
      list: `${API_URL}/customer/list`,
      delete: `${API_URL}/customer/delete/:id`,
      show: `${API_URL}/customer/show/:id`,
    },
    vendors: {
      create: `${API_URL}/vendor/create`,
      createAttachment: `${API_URL}/vendor/add/attachment`,
      update: `${API_URL}/vendor/update`,
      list: `${API_URL}/vendor/list`,
      delete: `${API_URL}/vendor/delete/:id`,
      show: `${API_URL}/vendor/show/:id`,
      deleteAttachment: `${API_URL}/attachment/delete/:id`,
    },
  },
  settings: {
    vat: {
      create: `${API_URL}/vat/create`,
      update: `${API_URL}/vat/update`,
      list: `${API_URL}/vat/list`,
      delete: `${API_URL}/vat/delete/:id`,
      show: `${API_URL}/vat/show/:id`,
    },
    notification: {
      create: `${API_URL}/notifications/create`,
      update: `${API_URL}/notifications/update`,
      list: `${API_URL}/notifications/list`,
      delete: `${API_URL}/notifications/delete/:id`,
      show: `${API_URL}/notifications/show/:id`,
    },
    timezone: {
      create: `${API_URL}/timezone/create`,
      update: `${API_URL}/timezone/update`,
      list: `${API_URL}/timezone/list`,
      delete: `${API_URL}/timezone/delete/:id`,
      show: `${API_URL}/timezone/show/:id`,
    },
    country: {
      create: `${API_URL}/location/country/create`,
      update: `${API_URL}/location/country/update`,
      updateSetting: `${API_URL}/country/update`,
      list: `${API_URL}/location/country/list`,
      delete: `${API_URL}/location/country/delete/:id`,
      show: `${API_URL}/location/country/show/:id`,
    },
    systemLanguage: {
      create: `${API_URL}/system/language/create`,
      update: `${API_URL}/system/language/update`,
      jsonUpdate: `${API_URL}/json/translation/save`,
      list: `${API_URL}/system/language/list`,
      delete: `${API_URL}/system/language/delete/:id`,
      show: `${API_URL}/system/language/show/:id`,
      jsonShow: `${API_URL}/json/translation/:id/:type`,
      jsonShowAPP: `${API_URL}/json/translation/get/:id/:type/:vendorId`,
    },
    currency: {
      create: `${API_URL}/currency/create`,
      update: `${API_URL}/currency/update`,
      list: `${API_URL}/currency/list`,
      delete: `${API_URL}/currency/delete/:id`,
      show: `${API_URL}/currency/show/:id`,
    },
    logs: {
      list: `${API_URL}/logs/files`,
      show: `${API_URL}/logs/log/:name`,
    },
  },
  outlet: {
    outlet: {
      create: `${API_URL}/outlet/create`,
      createAttachments: `${API_URL}/outlet/add/attachment`,
      update: `${API_URL}/outlet/update`,
      list: `${API_URL}/outlet/list`,
      delete: `${API_URL}/outlet/delete/:id`,
      show: `${API_URL}/outlet/show/:id`,
    },
  },
  support: {
    ticket: {
      create: `${API_URL}/ticket/create`,
      createAttachments: `${API_URL}/ticket/add/attachment`,
      update: `${API_URL}/ticket/update`,
      list: `${API_URL}/ticket/list`,
      delete: `${API_URL}/ticket/delete/:id`,
      show: `${API_URL}/ticket/show/:id`,
    },
  },
  purchase: {
    purchase: {
      create: `${API_URL}/purchase/create`,
      upload: `${API_URL}/purchase/import`,
      update: `${API_URL}/purchase/update`,
      list: `${API_URL}/purchase/list`,
      delete: `${API_URL}/purchase/delete/:id`,
      show: `${API_URL}/purchase/show/:id`,
      showItems: `${API_URL}/purchase/items/list/:id`,
    },
  },
  sales: {
    sales: {
      list: `${API_URL}/sale/list`,
      show: `${API_URL}/sale/show/:id`,
      showItems: `${API_URL}/sale/items/list/:id`,
    },
  },
  userManage: {
    role: {
      create: `${API_URL}/role/create`,
      update: `${API_URL}/role/update`,
      list: `${API_URL}/role/list`,
      delete: `${API_URL}/role/delete/:id`,
      show: `${API_URL}/role/show/:id`,
    },
    permission: {
      create: `${API_URL}/permission/create`,
      update: `${API_URL}/permission/update`,
      list: `${API_URL}/permission/list`,
      delete: `${API_URL}/permission/delete/:id`,
      show: `${API_URL}/permission/show/:id`,
    },
    user: {
      create: `${API_URL}/user/create`,
      update: `${API_URL}/user/update`,
      list: `${API_URL}/user/list`,
      delete: `${API_URL}/user/delete/:id`,
      show: `${API_URL}/user/show/:id`,
    },
  },
  inventory: {
    distribution: {
      create: `${API_URL}/distribution/create`,
      update: `${API_URL}/distribution/update`,
      list: `${API_URL}/distribution/list`,
      delete: `${API_URL}/distribution/delete/:id`,
      show: `${API_URL}/distribution/show/:id`,
      showMainStockList: `${API_URL}/main/stock/list/purchase/:id/vendor/:vendorId`,
    },
    mainStock: {
      create: `${API_URL}/main/stock/create`,
      update: `${API_URL}/main/stock/update`,
      list: `${API_URL}/main/stock/list`,
      delete: `${API_URL}/main/stock/delete/:id`,
      show: `${API_URL}/main/stock/show/:id`,
    },
    outletStock: {
      create: `${API_URL}/outlet/stock/create`,
      update: `${API_URL}/outlet/stock/update`,
      list: `${API_URL}/outlet/stock/list`,
      listByOutlet: `${API_URL}/distribution/list/outlet/:id`,
      delete: `${API_URL}/outlet/stock/delete/:id`,
      show: `${API_URL}/outlet/stock/show/:id`,
      export: `${API_URL}/outlet/stock/:id/export-delfi-prices`,
    },
  },
};
