import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/sales/sales.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../components/add-documents";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import notificationService from "../../../services/notification.service";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function SalesDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [sale, setSale] = useState([]);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getSale = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getSales(id);
      if (response && response.status === 200) {
        setSale(response.data.data);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            total: response.data.data.length,
          },
        });
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSale();
    }
  }, [id]);

  const handleTableChange = async (pagination, filters, sorter) => {
    updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => record?.product?.catalog?.ean,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.product?.catalog?.main_product_image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.product?.catalog?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.quantity"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: t("tableColumnName.unitSalePrice"),
      dataIndex: "item_price",
      align: "right",
      render: (text, record) => Number(text)?.toFixed(2),
    },
    {
      title: t("Sales.totalAmount"),
      dataIndex: "item_sub_total",
      align: "right",
      render: (text, record) => Number(text).toFixed(2),
    },
    {
      title: t("inputField.label.vat") + "%",
      dataIndex: "vat",
      align: "center",
      render: (text, record) =>
        Number(record?.vat)?.toFixed(2),
    },
    {
      title: t("inputField.label.vat") + " Amount",
      dataIndex: "vat",
      align: "right",
      render: (text, record) =>
        Number(
          (record?.vat * record?.item_sub_total) / 100
        )?.toFixed(2),
    },
  ];

  // Paginate data client-side
  const paginate = (data, current, pageSize) => {
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data?.slice(startIndex, endIndex);
  };

  const paginatedData = paginate(
    sale.sale_items,
    paginationParams.pagination.current,
    paginationParams.pagination.pageSize
  );

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("Sales.Sales"), link: "/sales-list" },
    { label: t("Sales.SalesDetails"), active: true },
  ];

  const onHideModal = async () => {
    console.log("hide modal");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("Sales.SalesDetails")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            sale && (
              <>
                <div className="">
                  <div className="p-3 card">
                    <div className="row mb-md-3">
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("Sales.SalesID")}
                          details={sale.order_code}
                        />
                      </div>
                      {/* <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("dashboard.salesAmount")}
                          details={sale.total_amount}
                        />
                      </div> */}
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={"Date"}
                          details={moment(sale.created_at).format(
                            "DD MMM YYYY - HH:mm:ss"
                          )}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("outlet.outlet")}
                          details={
                            <div className="d-flex align-items-center w-100 justify-content-between">
                            <div className="d-flex align-items-center">
                              <ImageWithBasePath
                                type="link"
                                src={sale.outlet?.image_url}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              {sale?.outlet?.name}
                            </div>
                              <ImageWithBasePath
                                type="link"
                                src={sale.vendor?.logo_url}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-md-3">
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("Sales.customerName")}
                          details={sale?.customer?.name}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("customer.loyaltyID")}
                          details={sale?.customer?.loyalty_id}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.pointsEarned")}
                          details={sale?.earn_points}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={paginatedData}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-2 mt-2">
                          <span className="fw-bold">
                            {t("Sales.subTotal")}:
                          </span>
                          <span>{Number(sale?.sub_total).toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2 mt-2">
                          <span className="fw-bold">
                            {t("tableColumnName.usedPoints")}:
                          </span>
                          <span>{sale?.redeem_points}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2 mt-2">
                          <span className="fw-bold">
                            {t("tableColumnName.discount")}:
                          </span>
                          <span>{Number(sale?.discount).toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between mb-2 mt-2">
                          <span className="fw-bold">
                            {t("inputField.label.vat")}:
                          </span>
                          <span>{Number(sale?.sub_total_vat).toFixed(2)}</span>
                        </div>
                        <hr className="m-0" />
                        <div className="d-flex justify-content-between mb-2 mt-2">
                          <span className="fw-bold fs-5">
                            {t("tableColumnName.total")}:
                          </span>
                          <span className="fw-bold fs-5">{Number(sale?.total).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <AddDocuments onHide={onHideModal} />
      </div>
    </>
  );
}
