import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createProductGroupList = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.productGroupList.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateProductGroupList = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.productGroupList.update}`,
    true,
    data,
    true,
  );
};
const productGroupListList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.productGroupList.list}`,
    true,
    true,
    data,
  );
};
const deleteProductGroupList = async (id) => {
  const url = `${ENDPOINT.products.productGroupList.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getProductGroupList = async (id) => {
  const url = `${ENDPOINT.products.productGroupList.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getProductGroupList,
  createProductGroupList,
  updateProductGroupList,
  productGroupListList,
  deleteProductGroupList,
};
