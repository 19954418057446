import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const logsList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.settings.logs.list}`,
    true,
    true,
    data,
  );
};
const getLogs = async (name) => {
  const url = `${ENDPOINT.settings.logs.show}`.replace(":name", name);
  return await API.getMethod(url, true);
};
export default {
  logsList,
  getLogs
};
