import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createAttribute = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.attributes.create}`,
    true,
    data,
    true,
  );
};
const updateAttribute = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.attributes.update}`,
    true,
    data,
    true,
  );
};
const attributesList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.attributes.list}`,
    true,
    true,
    data,
  );
};
const deleteAttribute = async (id) => {
  const url = `${ENDPOINT.products.attributes.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getAttribute = async (id) => {
  const url = `${ENDPOINT.products.attributes.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getAttribute,
  createAttribute,
  updateAttribute,
  attributesList,
  deleteAttribute,
};
