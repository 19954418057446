import React, { useEffect, useRef, useState } from "react";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import httpService from "../../../services/http/products/catalog.service";
import notificationService from "../../../services/notification.service";
import { hydrateErrors } from "../../../utils/error.utils";
import { useTranslation } from "react-i18next";

const ProductSalePriceDialog = ({ onHide, id, salePrice }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, setValue, setError, reset } =
    useForm();
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef();
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const response = await httpService.updateProductSalePrice(
        {
          sale_price: data.price,
        },
        id
      );
      if (response && response.status === 200) {
        notificationService.successMessage(t("Price updated successfully."));
        onHide();
        reset();
        modalHide();
      }
    } catch (error) {
      console.log("error  ", error);
      if (error.validation_error && error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const modalHide = () => {
    const cancelButton = document.querySelector(".close");
    if (cancelButton) {
      cancelButton.click();
    }
  };
  useEffect(() => {
    setValue("price", Number(salePrice).toFixed(2));
  }, [id]);

  useEffect(() => {
    const modalElement = modalRef.current;
    const handleShow = () => {
      console.log("Handle show");
    };
    const handleHide = () => {
      reset();
      onHide();
    };
    modalElement.addEventListener("show.bs.modal", handleShow);
    modalElement.addEventListener("hide.bs.modal", handleHide);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShow);
      modalElement.removeEventListener("hide.bs.modal", handleHide);
    };
  }, [onHide]);
  return (
    <div>
      <div className="modal fade" id="edit-product-price" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div>
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("general.button.updatePrice")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <UnlimitInput
                            label={t("inputField.label.price")}
                            id="price"
                            type="number"
                            placeholder={t("inputField.placeholder.enterPrice")}
                            register={register}
                            defaultValue={Number(salePrice).toFixed(2)}
                            rules={{
                              required: t("inputField.validation.required"),
                              maxLength: {
                                value: 9,
                                message: t("inputField.validation.maxLength", {
                                  value: 9,
                                }),
                              },
                            }}
                            error={errors.price}
                            errorMessage={errors.price?.message}
                            min="0"
                            step="0.0001"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <UnlimitSubmitButton
                        label={t("general.button.submit")}
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSalePriceDialog;
