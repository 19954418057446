import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createVendor = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.vendors.create}`,
    true,
    data,
    true,
  );
};
const createVendorAttachment = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.vendors.createAttachment}`,
    true,
    data,
    true,
  );
};
const updateVendor = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.vendors.update}`,
    true,
    data,
    json ? false :true,
  );
};
const vendorsList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.peoples.vendors.list}`,
    true,
    true,
    data,
  );
};
const deleteVendor = async (id) => {
  const url = `${ENDPOINT.peoples.vendors.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const deletAttachment = async (id) => {
  const url = `${ENDPOINT.peoples.vendors.deleteAttachment}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const getVendor = async (id) => {
  const url = `${ENDPOINT.peoples.vendors.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getVendor,
  createVendor,
  updateVendor,
  vendorsList,
  deleteVendor,
  deletAttachment,
  createVendorAttachment,
};
