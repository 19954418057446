import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";
import Papa from "papaparse";

import { Link } from "react-router-dom/dist";
import httpService from "../../../services/http/purchases/purchase.service";
import supplierHttpService from "../../../services/http/peoples/supplier.service";
import notificationService from "../../../services/notification.service";
import { all_routes } from "../../../Router/all_routes";
import { store } from "../../../core/redux/store";

import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { hydrateErrors } from "../../../utils/error.utils";
import { useTranslation } from "react-i18next";

const PurchaseUpload = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [supplier, setSupplier] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvColumns, setCsvColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [columnValues, setColumnValues] = useState([]);
  const [vendor, setVendor] = useState([]);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm();

  const vendors = useSelector((state) => state.vendors.vendors);
  const { auth } = store.getState();

  useEffect(() => {
    const fetchInitialData = async () => {
      const supplierResponse = await supplierHttpService.supplierList();
      const activeSuppliers = supplierResponse?.data?.data?.data?.filter(supplier => supplier.is_active == '1');
      setSupplier(activeSuppliers);
      setSupplierOptions(activeSuppliers);
      setVendor(
        [...(vendors?.data?.data ?? [])]?.sort((a, b) => {
          if (a?.business_name?.toLowerCase() === "near") return -1;
          if (b?.business_name?.toLowerCase() === "near") return 1;
          return 0;
        })
      );
    };
    fetchInitialData();
  }, [id, vendors]);

  const onSubmitForm = async (data) => {
    if (selectedColumns.length !== fieldsOrder.length) {
      notificationService.warningMessage(t("general.prompts.selectAllColumns"));
      return;
    }
    setIsLoading(true);
    setPageLoading(true);
    const grandTotal = columnValues.reduce((total, item) => {
      const unitCost = Number(item.unit_cost);
      const quantity = Number(item.quantity);
      const itemTotal = unitCost * quantity;
      return total + itemTotal;
    }, 0);
    try {
      const requestData = {
        purchase_date: data.purchase_date,
        order_number: data.order_no,
        supplier_id: data.supplier_id.id,
        vendor_id: data.vendor_id.id,
        purchase_type_id: 1,
        grand_total: grandTotal,
        file_data: JSON.stringify(columnValues)
      };
      
      await createPurchase(requestData);
    } finally {
      setIsLoading(false);
      setPageLoading(false);
    }
  };

  const createPurchase = async (formData) => {
    try {
      const response = await httpService.uploadPurchaseCSV(formData);
      if (response && response.status === 200) {
        notificationService.successMessage(t("purchase.purchaseCreateSuccess"));
        navigate(all_routes.purchaseList);
      }
    } catch (error) {
      console.error("Error:", error);
      // if (error.validation_error?.errors) {
      //   hydrateErrors(error.validation_error.errors, setError);
      // }
    }
  };

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "text/csv" || file.type === "application/vnd.ms-excel")) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data.length > 5000) {
            notificationService.errorMessage(t("general.prompts.CSVcolumns"));
            event.target.value = null;
            return;
          }
          const columns = Object.keys(results.data[0]).map((columnName) =>
            generateColumn(columnName)
          );
          setCsvColumns(columns);
          setCsvData(results.data);
          resetSelections();
        },
      });
    } else {
      notificationService.errorMessage(t("general.prompts.validCSV"));
    }
  };

  const fieldsOrder = [
    "ean",
    "quantity",
    "unit_cost",
    "discount",
    "vat",
    "sale_price",
  ];
  const userFriendlyLabels = {
    ean: "EAN",
    quantity: "Quantity",
    unit_cost: "Unit Cost Price",
    discount: "Discount Recieved",
    vat: "VAT Paid",
    sale_price: "Unit Sale Price",
  };
  const validationLabels = {
    ean: t("inputField.validation.fieldNumber"),
    quantity: t("inputField.validation.fieldNumber"),
    unit_cost: "",
    discount: "",
    vat: "",
    sale_price: "",
  };
  const handleColumnSelection = (columnName) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.includes(columnName)) {
        return prevSelectedColumns.filter((col) => col !== columnName);
      } else if (prevSelectedColumns.length < fieldsOrder.length) {
        return [...prevSelectedColumns, columnName];
      }
      return prevSelectedColumns;
    });
  };

  useEffect(() => {
    const newValues = csvData.map((row) => {
      const newRow = {};
      selectedColumns.forEach((column, index) => {
        newRow[fieldsOrder[index]] = row[column];
      });
      return newRow;
    });
    setColumnValues(newValues);
  }, [selectedColumns, csvData]);

  const resetSelections = () => {
    setSelectedColumns([]);
    setColumnValues([]);
  };

  const isChecked = useCallback(
    (columnName) => selectedColumns.includes(columnName),
    [selectedColumns]
  );

  const generateColumn = (columnName) => ({
    title: (
      <div className="d-flex align-items-center">
        <Checkbox
          checked={isChecked(columnName)}
          onChange={() => handleColumnSelection(columnName)}
          className="me-2"
          disabled={
            selectedColumns.length >= fieldsOrder.length &&
            !isChecked(columnName)
          }
        />
        {userFriendlyLabels[columnName] || columnName}
      </div>
    ),
    dataIndex: columnName,
    key: columnName,
    // render: (text, record) =>  Number(text)?.toFixed(2),
  });

  useEffect(() => {
    setCsvColumns((prevColumns) =>
      prevColumns.map((col) => ({
        ...col,
        title: (
          <div
            className="d-flex align-items-center"
            onClick={() => handleColumnSelection(col.dataIndex)}
          >
            <Checkbox
              checked={isChecked(col.dataIndex)}
              className="me-2"
              disabled={
                selectedColumns.length >= fieldsOrder.length &&
                !isChecked(col.dataIndex)
              }
            />
            {userFriendlyLabels[col.dataIndex] || col.dataIndex}
          </div>
        ),
      }))
    );
  }, [selectedColumns]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("purchase.purchases"), link: "/purchase-list" },
    { label: t("purchase.addNewPurchase"), active: true },
  ];
  const handleVendorChange = (selectedOption) => {
    setSupplierOptions(supplier.filter((sup)=>sup?.vendor?.id === selectedOption.id))
  };
  const today = new Date().toISOString().split('T')[0];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("purchase.purchases")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0 mb-3 row align-items-center">
              <div className="col-md-4">
                <UnlimitSelect
                  label={t("vendor.vendor")}
                  id="vendor_id"
                  errors={errors}
                  control={control}
                  register={register}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t("inputField.placeholder.chooseVendor")}
                  options={vendor}
                  onChange={handleVendorChange}
                  showLogo={true}
                  optionLabel="business_name"
                  optionValue="id"
                />
              </div>
              <div className="col-md-4 ">
                <UnlimitSelect
                  label={t("supplier.supplier")}
                  id="supplier_id"
                  errors={errors}
                  control={control}
                  register={register}
                  options={supplierOptions}
                  showLogo={true}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t("inputField.label.selectSupplier")}
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div className="col-md-4" style={{ marginTop: "10px" }}>
                <UnlimitInput
                  label={t("tableColumnName.purchaseDate")}
                  id="purchase_date"
                  type="date"
                  max={today}
                  placeholder={t("inputField.placeholder.selectStartDate")}
                  register={register}
                  rules={{ required: t("inputField.validation.required") }}
                  error={errors.purchase_date}
                  errorMessage={errors.purchase_date?.message}
                />
              </div>
              <div className="col-md-4">
                <UnlimitInput
                  label={t("tableColumnName.purchaseRefernce")}
                  id="order_no"
                  type="number"
                  placeholder={t(
                    "inputField.placeholder.enterpurchaseRefernce"
                  )}
                  register={register}
                  rules={{
                    required: t("inputField.validation.required"),
                    maxLength: {
                      value: 8,
                      message: t("inputField.validation.maxLength", {
                        value: 8,
                      }),
                    },
                  }}
                  error={errors.order_no}
                  errorMessage={errors.order_no?.message}
                />
              </div>
              <div className="col-md-4 mb-2">
                <label className="mb-2">
                  {t("inputField.label.selectCSVfile")}
                </label>
                <input
                  type="file"
                  accept=".csv"
                  style={{padding:"8.5px"}}
                  onChange={handleCsvUpload}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="d-flex flex-column">
                      <p className="fw-bold fs-6 ms-2">
                        {t("inputField.label.selectColumnCsv")}
                      </p>
                      {fieldsOrder.map((field, index) => (
                        <div key={index}>
                          {columnValues[0]?.[field] && <span>✔️</span>}{" "}
                          {index + 1 + ":" + " "}
                          {userFriendlyLabels[field] ||
                            field.charAt(0).toUpperCase() + field.slice(1)}
                          <small className="ms-2">
                          {validationLabels[field]}
                          </small>
                        </div>
                      ))}
                      {columnValues.length === 0 && (
                        <span>{t("general.prompts.noColumnsAvailable")}</span>
                      )}
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary mb-3 mt-2"
                          onClick={resetSelections}
                        >
                          {t("general.button.reset")}
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <UnlimitTable
                        columns={csvColumns}
                        dataSource={csvData}
                        rowKey={(record) => record.id || record.key}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.purchaseList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PurchaseUpload;
