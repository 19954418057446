import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { base_path } from "./environment.jsx";
import "../src/style/css/feather.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/scss/main.scss";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
import './i18nConfig.jsx'
const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <React.StrictMode>
          <BrowserRouter basename={base_path}>
            <AllRoutes />
          </BrowserRouter>
        </React.StrictMode>
      </PersistGate>
    </Provider>,
  );
} else {
  console.error("Element with id 'root' not found.");
}
