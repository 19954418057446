import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";

const messages = new Set();
const removeMessage = (message) => {

  setTimeout(() => {
    messages.delete(message);
  }, 6000);
}; 
 
const successMessage = (message, title = "Success!") => {
  if (!messages.has(message)) {
    messages.add(message);
    removeMessage(message);
    NotificationManager.success(message, title, 3000);
  }
};

const errorMessage = (message, title = "Error!") => {
  if (!messages.has(message)) {
    messages.add(message);
    removeMessage(message);
    NotificationManager.error(message, title, 3000);
  }
};

const warningMessage = (message, title = "Warning!") => {
  if (!messages.has(message)) {
    messages.add(message);
    removeMessage(message);
    NotificationManager.warning(message, title, 3000);

  }
};

const infoMessage = (message, title = "Info!") => {
  if (!messages.has(message)) {
    messages.add(message);
    removeMessage(message);
    NotificationManager.info(message, title, 3000);
  }
};



export default { successMessage, errorMessage, warningMessage, infoMessage };
