import { Canvas, useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

import eagleObj from "./eagle.obj";
import { Suspense } from "react";
import * as THREE from "three";

const Scene = ({ objPath }) => {
  //const materials = useLoader(MTLLoader, eagleMtl);
  const obj = useLoader(OBJLoader, objPath);

  // Calculate object dimensions
  const bbox = new THREE.Box3().setFromObject(obj);
  const objectWidth = bbox.max.x - bbox.min.x;
  const objectHeight = bbox.max.y - bbox.min.y;
  const objectDepth = bbox.max.z - bbox.min.z;

  // Calculate a scaling factor based on the largest dimension of the object
  const maxDimension = Math.max(objectWidth, objectHeight, objectDepth);
  const scaleFactor = 1 / (maxDimension * 5); // Scale factor to make the largest dimension fit within 1 unit

  // Apply the scaling factor to the object
  const scaledObj = obj.clone(); // Clone the object to avoid modifying the original
  scaledObj.scale.set(scaleFactor, scaleFactor, scaleFactor);


  // Return the scaled object as a primitive
  // eslint-disable-next-line react/no-unknown-property
  return <primitive object={scaledObj} />;
};
export default function ThreedFilePage() {
  return (
    <div>
      <div className="page-wrapper">
        <div
          style={{
            width: "400px",
            height: "400px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "20px",
            margin: "40px",
          }}
        >
          <Canvas style={{ width: "350px", height: "350px" }}>
            <Suspense fallback={null}>
              {/* <ambientLight intensity={0.7} />
              <spotLight
                intensity={0.5}
                angle={0.1}
                penumbra={1}
                position={[10, 15, 10]}
                castShadow
              />*/}

              <Scene objPath={eagleObj} />
              <OrbitControls />
              <Environment preset="city" />
            </Suspense>
          </Canvas>
        </div>
      </div>
    </div>
  );
}
