import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createTicket = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.support.ticket.create}`,
    true,
    data,
    true,
  );
};
const updateTicket = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.support.ticket.update}`,
    true,
    data,
    true,
  );
};
const ticketList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.support.ticket.list}`,
    true,
    true,
    data,
  );
};
const deleteTicket = async (id) => {
  const url = `${ENDPOINT.support.ticket.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getTicket = async (id) => {
  const url = `${ENDPOINT.support.ticket.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const createTicketDocument = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.support.ticket.createAttachments}`,
    true,
    data,
    true,
  );
};
export default {
  getTicket,
  createTicket,
  updateTicket,
  ticketList,
  deleteTicket,
  createTicketDocument
};
