import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/settings/country.service";
import { useEffect, useState } from "react";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import notificationService from "../../../services/notification.service";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import { store } from "../../../core/redux/store";
import moment from "moment";
import ConfirmationAlertDeactivate from "../../../components/confirm-deactivate";
import ReactCountryFlag from "react-country-flag";
import { useGetCountries } from "../../../services/http/side-Effects/location";

export default function CountryDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const fetchCountries = useGetCountries();
  const { id } = useParams();
  const [country, setCountry] = useState(null);
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const navigate = useNavigate();

  const getCountry = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getCountry(id);
      if (response && response.status === 200) {
        setCountry(response.data.data);
        setIsActive(response.data.data.flag == 0 ? true : false);
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCountry();
    }
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Country", link: "/country-list" },
    { label: "Country Details", active: true },
  ];

  const updateCountry = async () => {
    const formData = new FormData();
    formData.append("flag", isActive ? 1 : 0);
    if (id) formData.append("id", id);
    await httpService
      .updateCountry(formData)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage("Country updated successfully!");
          setIsActive(!isActive);
        }
        fetchCountries()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const formatTimeZone = (timezones) => {
    return timezones
      .map((timezone) => {
        const offsetHours = timezone.gmtOffset / 3600;
        const offsetString = `GMT${offsetHours >= 0 ? "+" : ""}${offsetHours}`;
        return `${offsetString} (${timezone.gmtOffsetName}) ${timezone.zoneName}`;
      })
      .join(", ");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">Country</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            country && (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <div className="border card p-3 mb-3">
                      <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                        <span
                          className={`badge align-self-end ${
                            !isActive ? "badge-linesuccess" : "badge-danger"
                          }`}
                        >
                          <a>{!isActive ? "Active" : "Deactive"}</a>
                        </span>
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                          <ReactCountryFlag
                            style={{
                              width: "164px",
                              height: "176px",
                            }}
                            svg
                            className="emojiFlag"
                            countryCode={country.iso2}
                          />
                          <div className="mt-4 text-center">
                            <div className="text-black text-center fs-6">
                              <strong>{country?.name}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex w-100 mt-4 gap-3">
                        {roles[0].permissions.some(
                          (permission) => permission.name === "country-edit"
                        ) && (
                          <UnlimitHyperlink
                            to={`/country/${country?.id}/edit`}
                            className="btn btn-primary"
                            type="router-link"
                            style={{ flex: 1 }}
                          >
                            Edit
                          </UnlimitHyperlink>
                        )}
                        {roles[0].permissions.some(
                          (permission) => permission.name === "country-delete"
                        ) && (
                          <ConfirmationAlertDeactivate
                            onConfirm={() => updateCountry()}
                            isActive={isActive}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="p-3 card">
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"ISO3"}
                            details={country.iso3}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Numeric Code"}
                            details={country.numeric_code}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"ISO2"}
                            details={country.iso2}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Phone Code"}
                            details={country.phonecode}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Capital"}
                            details={country.capital}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Currency"}
                            details={country.currency}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Currency Name"}
                            details={country.currency_name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Currency Symbol"}
                            details={country.currency_symbol}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"TLD"}
                            details={country.tld}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Native"}
                            details={country.native}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Region"}
                            details={country.region}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Sub Region"}
                            details={country.subregion}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Latitude"}
                            details={country.latitude}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Longitude"}
                            details={country.longitude}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Time Zone"}
                            details={formatTimeZone(
                              JSON.parse(country.timezones)
                            )}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Emoji"}
                            details={country.emoji}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"EmojiU"}
                            details={country.emojiU}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Wiki Data ID"}
                            details={country.wikiDataId}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Created By"}
                            details={"Admin"} // Assuming this is hardcoded
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Created At"}
                            details={moment(country.created_at).format(
                              "DD MMM YYYY - HH:mm:ss"
                            )}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"VAT Percentage"}
                            details={country.vat_percentage || "N/A"}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={"Flag"}
                            details={country.flag ? "Active" : "Inactive"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
}
