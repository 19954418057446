import { setCountries } from "../../../core/redux/reducers/locations.slice";
import locationService from "../../../services/http/location.service";
import { useDispatch } from "react-redux";

export const getCountries = async (dispatch) => {
  try {
    const response = await locationService.getCountries();
    if (response.data.data?.data) {
      dispatch(setCountries(response.data.data?.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const useGetCountries = () => {
  const dispatch = useDispatch();
  return () => getCountries(dispatch);
};