import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createProductType = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.productType.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateProductType = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.productType.update}`,
    true,
    data,
    json?false:true,
  );
};
const productTypeList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.productType.list}`,
    true,
    true,
    data,
  );
};
const deleteProductType = async (id) => {
  const url = `${ENDPOINT.products.productType.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getProductType = async (id) => {
  const url = `${ENDPOINT.products.productType.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getProductType,
  createProductType,
  updateProductType,
  productTypeList,
  deleteProductType,
};
