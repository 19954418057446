import React from "react";

const UnlimitTextarea = (props) => {
  const { label, id, register, rules, error, errorMessage,numOfRows,placeholder } = props;
  return (
    <div className="unlimit-uikit-textarea input-blocks summer-description-box transfer">
      <label className="form-label" htmlFor={id}>
        {label}
      </label>
      <textarea
        id={id}
        {...register(id, rules)}
        className="form-control h-100"
        placeholder={placeholder}
        rows={numOfRows || 5}
      />
      {error && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default UnlimitTextarea;
