import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/support/ticket.service";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import Select from "react-select";
import userhttpService from "../../../services/http/user-managment/user.service";
import "./TicketDetails.css";
import UnlimitTicketDetails from "../../../components/unlimit-ticket-details";
import { X } from "feather-icons-react/build/IconComponents";
import moment from "moment";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { useTranslation } from "react-i18next";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";

const TicketDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const [selectedPriorty, setSelectedPriorty] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [user, setUsers] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;

  useEffect(() => {
    if (id) {
      getTicket();
    }
    fetchUsers();
  }, [id]);

  const getTicket = async () => {
    try {
      setPageLoading(true);
      await httpService.getTicket(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          setTicketDetails(data);
          setSelectedPriorty(
            priortyOptions.find(
              (option) => option.value === response.data.data.priority
            )
          );
          setSelectedStatus(
            statusOptions.find(
              (option) => option.value === response.data.data.status
            )
          );
          setSelectedAssignee({
            value: response.data.data.user.id,
            label: response.data.data.user.name,
          });
        }
      });
    } finally {
      setPageLoading(false);
    }
  };
  // Create new ticket
  const createTicket = async (formData) => {
    await httpService
      .createTicket(formData)
      .then((response) => {
        if (response && response.status === 200) {
          notificationService.successMessage("Ticket created successfully!");
          navigate(all_routes.ticketList);
        }
      })
      .catch((error) => {
        if (error.validation_error.errors) {
          hydrateErrors(error.validation_error.errors, setError);
        }
      });
  };
  const handleReplyClick = () => {
    setShowReplyInput(true);
  };

  const handleSendClick = async (priority, status, assignee) => {
    // Handle the API call to send the reply and attachment
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("vendor_id", ticketDetails?.vendor?.id);
    formData.append("user_id", ticketDetails?.user?.id);
    if (assignee) formData.append("assignee", assignee.value);
    if (status) formData.append("status", status.value);
    if (priority) formData.append("priority", priority.value);
    if (replyText) formData.append("comment", replyText);
    if (attachments) {
      attachments.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file);
      });
    }

    // Example API call to send reply
    await httpService
      .updateTicket(formData)
      .then((response) => {
        if (response && response.status === 200) {
          notificationService.successMessage("Reply sent successfully!");
          setShowReplyInput(false);
          setReplyText("");
          setAttachments([]);
        }
      })
      .catch((error) => {
        console.error("Error sending reply:", error);
        notificationService.errorMessage("Failed to send reply");
      })
      .finally(() => {
        setButtonLoading(false);
        getTicket();
      });
  };
  const handlePriorityChange = (selectedOption) => {
    setSelectedPriorty(selectedOption);
    handleSendClick(selectedOption, selectedStatus, selectedAssignee);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    handleSendClick(selectedPriorty, selectedOption, selectedAssignee);
  };

  const handleAssigneeChange = (selectedOption) => {
    setSelectedAssignee(selectedOption);
    handleSendClick(selectedPriorty, selectedStatus, selectedOption);
  };
  async function fetchUsers() {
    try {
      const user = await userhttpService.userList();
      setUsers(user?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  }
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Ticket List", link: "/ticket-list" },
    { label: "Ticket Details", active: true },
  ];
  const statusOptions = [
    { value: null, label: "Select All" },
    { value: "open", label: "Open" },
    { value: "closed", label: "Closed" },
  ];
  const userOptions = user?.map((user) => ({
    value: user.id,
    label: user.name,
  }));
  const priortyOptions = [
    { value: null, label: "Select All" },
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments((prevFileList) => [...prevFileList, ...files]);
  };
  const handleRemove = (index, event) => {
    event.preventDefault();
    const updatedList = [...attachments];
    updatedList.splice(index, 1);
    setAttachments(updatedList);
  };
  const openFileDialog = () => {
    document.getElementById("hiddenFileInput").click();
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("ticket.ticketList")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Ticket Form */}
        {pageLoading ? (
          <div className="row d-flex justify-content-center">
            <UnlimitLoading width={50} height={50} />
          </div>
        ) : (
          <div className="card">
            <div className="row p-3">
              <div className="col-md-9 ">
                <div className="d-flex align-items-center mb-3">
                  <p className="fw-bold fs-6 mb-0 me-3">
                    {ticketDetails?.subject}
                  </p>
                  <p className="text-primary bg-primary bg-opacity-10 p-2 py-1 rounded">
                    {ticketDetails?.priority + " Priority"}
                  </p>
                </div>
                <div className="border rounded">
                  {ticketDetails?.ticket_comments?.map((detail, index) => (
                    <UnlimitTicketDetails
                      key={index}
                      imageSrc={detail?.commentable?.image}
                      name={detail?.commentable?.name}
                      description={detail?.comment}
                      attachments={detail.attachments}
                    />
                  ))}
                  {showReplyInput ? (
                    <div className="reply-input-container border-bottom">
                      <div className="d-flex align-items-center p-2">
                        <input
                          className="w-100 mb-2 border-0"
                          placeholder={t("inputField.placeholder.typeHere")}
                          value={replyText}
                          onChange={(e) => setReplyText(e.target.value)}
                        ></input>
                        <input
                          type="file"
                          id="hiddenFileInput"
                          className="form-control mb-2 d-none"
                          onChange={handleChange}
                        />
                        <i
                          className="fa fa-paperclip me-3"
                          onClick={openFileDialog}
                        ></i>

                        <UnlimitSubmitButton
                          className="btn btn-success"
                          label={"Send"}
                          isLoading={buttonLoading}
                          disabled={replyText == ""}
                          onClick={() =>
                            handleSendClick(
                              selectedPriorty,
                              selectedStatus,
                              selectedAssignee
                            )
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-success mt-3 me-3"
                        onClick={handleReplyClick}
                      >
                        Reply
                      </button>
                    </div>
                  )}
                  <div className="container">
                    <div className="row">
                      <ul className="col-md-4 m-3 file-list">
                        {attachments?.length > 0 && (
                          <>
                            {attachments?.map((file, index) => (
                              <li key={index}>
                                <div className="file-info">
                                  <span>{file.name}</span>
                                  <button
                                    style={{ minWidth: "24px" }}
                                    className="remove-button"
                                    onClick={(event) =>
                                      handleRemove(index, event)
                                    }
                                  >
                                    <X color="white" size={16} />
                                  </button>
                                </div>
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 bg-gray-200 p-0 sticky-column">
                <p className="fw-bold fs-6 text-center border-bottom p-3">
                  Ticket Details
                </p>
                <div className="p-3">
                  <div className="row">
                    <p className="col-5 fw-bold">
                      {t("tableColumnName.ticketNumber")}
                    </p>
                    <p className="col-7">{ticketDetails?.ticket_number}</p>
                  </div>
                  <div className="row">
                    <p className="col-5 fw-bold">
                      {t("tableColumnName.createdAt")}
                    </p>
                    <p className="col-7">
                      {moment(ticketDetails?.created_at).format(
                        "DD MMM YYYY - HH:mm:ss"
                      )}
                    </p>
                  </div>
                  <div className="unlimit-uikit-select mt-3">
                    <label className="mb-1">Priority</label>
                    <Select
                      options={priortyOptions}
                      className="select"
                      placeholder={t("inputField.placeholder.choosePriority")}
                      value={selectedPriorty}
                      onChange={handlePriorityChange}
                    />
                  </div>
                  <div className="unlimit-uikit-select mt-3">
                    <label className="mb-1">
                      {t("inputField.label.status")}
                    </label>
                    <Select
                      options={statusOptions}
                      className="select "
                      placeholder={t("inputField.placeholder.chooseStatus")}
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    />
                  </div>
                  <div className="unlimit-uikit-select mt-3">
                    <label className="mb-1">Assignee</label>
                    <Select
                      options={userOptions}
                      className="select"
                      placeholder={t("inputField.placeholder.chooseAssignee")}
                      value={selectedAssignee}
                      onChange={handleAssigneeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card-body pb-0"></div> */}
          </div>
        )}
        {/* Ticket Form End */}
      </div>
    </div>
  );
};
export default TicketDetails;
