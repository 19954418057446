import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createBrand = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.brands.create}`,
    true,
    data,
    true,
  );
};
const updateBrand = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.brands.update}`,
    true,
    data,
    true,
  );
};
const brandsList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.brands.list}`,
    true,
    true,
    data,
  );
};
const deleteBrand = async (id) => {
  const url = `${ENDPOINT.products.brands.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getBrand = async (id) => {
  const url = `${ENDPOINT.products.brands.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getBrand,
  createBrand,
  updateBrand,
  brandsList,
  deleteBrand,
};
