import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createEmployees = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.employees.create}`,
    true,
    data,
    true
  );
};
const updateEmployees = async (data, json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.employees.update}`,
    true,
    data,
    json ? false : true
  );
};
const employeesList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.peoples.employees.list}`,
    true,
    true,
    data
  );
};
const deleteEmployees = async (id) => {
  const url = `${ENDPOINT.peoples.employees.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getEmployees = async (id) => {
  const url = `${ENDPOINT.peoples.employees.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const createEmployeesDocument = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.employees.createAttachments}`,
    true,
    data,
    true
  );
};
export default {
  getEmployees,
  createEmployees,
  updateEmployees,
  employeesList,
  deleteEmployees,
  createEmployeesDocument,
};
