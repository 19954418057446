import React from 'react';
import LiveCCTVStream from '../../components/LiveCCTVStream';

const LiveStream = () => {
  return (
    <div>
      <h1>Live CCTV Stream</h1>
      <LiveCCTVStream
        src="https://example.com/live-cctv-stream"
        width={640}
        height={480}
      />
    </div>
  );
};

export default LiveStream;
