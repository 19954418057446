import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import {
  Filter,
  Plus,
  PlusCircle,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/settings/notification.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import { store } from "../../../core/redux/store";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useSelector } from "react-redux";
const NotificationList = () => {
  const { hasPermission } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const vendors = useSelector((state) => state.vendors.vendors);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const { t } = useTranslation();
  const { locations, auth } = store.getState();
  const roles = auth.roles ?? [];
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} ${t('pagination.of')} ${total} ${t('pagination.items')}`,
    },
  });
  const navigate = useNavigate();
  const getNotificationList = async (pagination = paginationParams.pagination,filters,sorter = {}) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .notificationList(data)
      .then((response) => {
        if (response.status == 200) {
          setNotificationList(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getNotificationList(pagination,nonNullFilters,sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);

  const { notificationCreate } = all_routes;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if(isFilterVisible){
      setNameFilter("")
      const filters = {};
      await getNotificationList(paginationParams.pagination,filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteNotification(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("Notification.NotificationDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getNotificationList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const notificationTypeLabels = {
    PUSH_NOTIFICATION: t("notification.push"),
    SMS: t("tableColumnName.sms"),
    EMAIL: t("tableColumnName.email"), // Add other types as needed
  };

  const columns = [
    {
      title: t('tableColumnName.srNo'),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t('inputField.label.notificationSubject'),
      dataIndex: "title",
    },
    {
      title: t('inputField.label.notificationDescription'),
      dataIndex: "body",
    },
    {
      title: t('customer.customer'),
      dataIndex: "code",
      render: (text,record) => {
        return record.customers.length
      },
    },
    {
      title: t('tableColumnName.createdAt'),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t('tableColumnName.createdBy'),
      dataIndex: "created_by",
      render: (outlet, record) =>
        record.created_by ? record.created_by.name : null,
    },
    {
      title: t('inputField.label.notificationType'),
      dataIndex: "notification_types",
      render: (notificationTypes) => {
        return notificationTypes.map((type) => (
          // <Badge key={type} style={{ backgroundColor: '#1890ff', color: '#fff', marginRight: 4 }}>
             <span key={type} className="me-2 badge badge-linesuccess">

            {notificationTypeLabels[type]}
             </span>

        ));
      },
    }
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('notification.notificationList'), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t('notification.notificationList')}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {/* {hasPermission(["notification-add"]) && ( */}
            <div className="page-btn">
              <Link to={notificationCreate} className="btn btn-added">
                <Plus className="me-2 iconsize" />
                {t('general.button.addNew')}
              </Link>
            </div>

          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
            <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                  <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t('inputField.placeholder.enterNotificationSubject')}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={notificationList}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
