import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createCustomer = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.customer.create}`,
    true,
    data,
    true,
  );
};
const updateCustomer = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.customer.update}`,
    true,
    data,
    true,
  );
};
const customerList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.peoples.customer.list}`,
    true,
    true,
    data,
  );
};
const deleteCustomer = async (id) => {
  const url = `${ENDPOINT.peoples.customer.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getCustomer = async (id) => {
  const url = `${ENDPOINT.peoples.customer.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getCustomer,
  createCustomer,
  updateCustomer,
  customerList,
  deleteCustomer,
};
