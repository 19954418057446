import { setSystemLangauge } from "../../../core/redux/reducers/systemLanguage.slice";
import systemLanguageHttpService from "../../../services/http/settings/system-language.service";
import { useDispatch } from "react-redux";

export const getSystemLanguage = async (dispatch) => {
    const data = {
        page: 1,
        per_page: 50,
      };
      try {
        const response = await systemLanguageHttpService.systemLanguageList(
          data
        );
        dispatch(setSystemLangauge(response.data.data?.data));
      } catch (error) {
        console.error("Error:", error);
      }
};

export const useGetSystemLanguage = () => {
  const dispatch = useDispatch();
  return () => getSystemLanguage(dispatch);
};