import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import authService from "../../../services/http/auth.service";
import notificationService from "../../../services/notification.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import OTPInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/LanguageSelecter";

const VerifyOtp = () => {
  const route = all_routes;
  const { t,i18n  } = useTranslation();
  const { register, handleSubmit, formState, watch } = useForm();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const password = useRef({});
  password.current = watch("password", "");
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    const LocalEmail = localStorage.getItem("reset-password-email");
    setEmail(LocalEmail);
  }, []);

  const submitData = async () => {
    try {
      setIsLoading(true);
      const res = await authService.verifyOtp(email, otp);
      if (res.data.data) {
        notificationService.successMessage(t('general.prompts.OTPVerified'));
        navigate(`${route.resetpassword}?token=${res.data.data}`);
      }else{
        notificationService.errorMessage("OTP is invalid! Please try again");
      }
      setOtp("");
    } catch (e) {
      notificationService.errorMessage("OTP is invalid! Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const resendOTP = async () => {
    try {
      setIsLoading(true);
      await authService.sendOtp(email);
      notificationService.successMessage(t('general.prompts.OTPsent'));
      setTimer(120);
      setOtp("");
    } catch (e) {
      notificationService.errorMessage(t('general.prompts.OTPsentFailed'));
    } finally {
      setIsLoading(false);
    }
  };
  const isOtpValid = otp.length === 6;
  return (
    <div className="main-wrapper">
      <div className="language-change-dropdown" style={{zIndex:99}}>
          <ul className="nav user-menu">
            <LanguageSelector />
          </ul>
        </div>
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-img-text">
            <ImageWithBasePath
              src="assets/img/Logo01.png"
              className="login-logo-img"
              alt="img"
            />
            <p>{t("general.dummyText")}</p>
          </div>
          <div className="login-img">
            <ImageWithBasePath
              src="assets/img/authentication/login-screen.jpg"
              alt="img"
            />
          </div>
          <div className="login-content">
            <form
              className="form-login-width"
              onSubmit={handleSubmit(submitData)}
            >
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div className="login-userset">
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath
                      src="assets/img/logo-white.png"
                      alt="img"
                    />
                  </Link>
                  <div className="login-userheading">
                    <h3>{t('general.OTPVerification')}</h3>
                    <h4 className="text-muted mb-2">
                      {t('general.OTPSentLabel')}
                    </h4>
                    <h4 className="fw-bold fs-6">{email}</h4>
                  </div>
                  <p>{t('general.enter6DigitOTP')}</p>
                  <div className="w-full otp-input-container justify-content-center">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span></span>}
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex justify-content-between my-2">
                    <button
                      className="resend-otp-button p-0"
                      onClick={resendOTP}
                      disabled={timer > 0}
                    >
                      {t('general.actions.resendOTP')}
                    </button>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`${route.forgotPasswordTwo}`)}
                    >
                      {t('general.actions.changeEmail')}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center timer-text mb-2">
                    <p>
                      {`${Math.floor(timer / 60)}:${
                        timer % 60 < 10 ? `0${timer % 60}` : timer % 60
                      }s`}
                    </p>
                  </div>
                  <div className="form-login">
                    <button
                      className="btn btn-login"
                      type="submit"
                      disabled={!isOtpValid}
                    >
                      {t('general.button.submit')}
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
