import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createCategory = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.categories.create}`,
    true,
    data,
    true,
  );
};
const updateCategory = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.categories.update}`,
    true,
    data,
    true,
  );
};
const categoriesList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.categories.list}`,
    true,
    true,
    data,
  );
};
const deleteCategory = async (id) => {
  const url = `${ENDPOINT.products.categories.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getCategory = async (id) => {
  const url = `${ENDPOINT.products.categories.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getCategory,
  createCategory,
  updateCategory,
  categoriesList,
  deleteCategory,
};
