import React from "react";
import ReactLoading from "react-loading";

const UnlimitLoading = (props) => {
  const { type, color, height, width } = props;
  return (
    <ReactLoading
      type={type ? type : "spin"}
      color={color ? color : "#82B632"}
      height={height}
      width={width}
    />
  );
};
export default UnlimitLoading;
