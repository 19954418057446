import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const outletStockList = async (data,id) => {
  const url = `${ENDPOINT.inventory.outletStock.listByOutlet}`.replace(":id", id);
  return await API.getMethod(
    url,
    true,
    true,
    data
  );
};
const deleteMainStock = async (id) => {
  const url = `${ENDPOINT.inventory.outletStock.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getMainStock = async (id) => {
  const url = `${ENDPOINT.inventory.outletStock.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const updateStockPrice = async (id) => {
  const url = `${ENDPOINT.inventory.outletStock.export}`.replace(":id", id);
  return await API.postMethod(url, true);
};
export default {
  getMainStock,
  outletStockList,
  deleteMainStock,
  updateStockPrice,
};
