import React from "react";

const UnlimitFilePicker = (props) => {
  const {
    label,
    id,
    type,
    register,
    rules,
    accept,
    error,
    errorMessage,
    ...attr
  } = props;
  return (
    <div className="unlimit-uikit-filepicker">
      <label className="form-label " htmlFor={id}>
        {label}
      </label>
      <input
        className="form-control form-control-lg"
        type="file"
        accept={accept}
        id={id}
        {...register(id, rules)}
        {...attr}
      />
      {error && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default UnlimitFilePicker;
