import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import {
  Filter,
  PlusCircle,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/attribute.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AttributeList = () => {
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} ${t('pagination.of')} ${total} ${t('pagination.items')}`,
    },
  });
  const navigate = useNavigate();
  const getAttributesList = async (
    pagination = paginationParams.pagination
  ) => {
    const data = {
      page: pagination?.current,
    };
    setLoading(true);
    try {
      await httpService
        .attributesList(data)
        .then((response) => {
          if (response.status == 200) {
            setAttributes(response.data.data?.data);
          }
          updatePaginationParams({
            pagination: {
              ...paginationParams.pagination,
              current: response.data.data.current_page,
              total: response.data.data.total,
              pageSize: response.data.data.per_page,
            },
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          updatePaginationParams({
            pagination: {
              ...paginationParams.pagination
            },
          });
        });
    } catch (error) {
      console.log("Error ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      ...sorter,
    });
    await getAttributesList(pagination);
  };
  useEffect(() => {
    getAttributesList();
  }, []);

  const { attributeCreate } = all_routes;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    // if(isFilterVisible && (emailFilter || cvrNoFilter)){
    //   setCvrNoFilter("")
    //   setEmailFilter("")
    //   const filters = {
    //     email: emailFilter,
    //     cvr_no: cvrNoFilter,
    //   };
    //   await getVendorsList(filters);
    // }
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteAttribute(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Attribute has been deleted successfully.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getAttributesList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const attribute = [
    { value: "chooseAttribute", label: "Choose Attribute" },
    { value: "laptop", label: "Laptop" },
    { value: "electronics", label: "Electronics" },
    { value: "shoe", label: "Shoe" },
  ];
  const status = [
    { value: "chooseStatus", label: "Choose Status" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "Attribute Name",
      dataIndex: "name",
    },
    {
      title: "Attribute Option",
      dataIndex: "attribute_option",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>Active</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>Deactivate</a>
            </span>
          );
        }
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="me-2 p-2"
              onClick={() => navigate(`/attribute/${record.id}/edit`)}
            >
              <i className="feather-edit-2"></i>
            </a>
            <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Attribute List", active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4> Attribute List </h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div className="page-btn">
              <Link to={attributeCreate} className="btn btn-added">
                <PlusCircle className="me-2 iconsize" />
                Add New
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Zap className="info-img" />
                        <Select
                          options={attribute}
                          className="select"
                          placeholder="Choose Attribute"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <i data-feather="calendar" className="info-img" />
                        <div className="input-groupicon">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />

                        <Select
                          options={status}
                          className="select"
                          placeholder="Choose Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={attributes}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default AttributeList;
