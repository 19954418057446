import React, { useEffect, useState } from "react";
import locationAPI from "../../../services/http/location.service";
import httpService from "../../../services/http/peoples/supplier.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitFilePicker from "../../../components/ui-kit/unlimit-filepicker";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import UnlimitAttachments from "../../../components/ui-kit/unlimit-attachments";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SupplierForm = () => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const { id } = useParams();
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const { locations } = store.getState();
  const { t } = useTranslation();
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [imageUrlAttachments, setImageUrlAttachments] = useState("");
  const [existingImageUrlAttachments, setExistingImageUrlAttachments] =
    useState("");
  const vendors = useSelector((state) => state.vendors.vendors);
  const watchFile = watch("attachment");
  const removeImage = () => {
    setImageUrlAttachments("");
    setExistingImageUrlAttachments("");
    setValue("attachment", "");
  };
  useEffect(() => {
    if (watchFile?.length > 0) {
      setImageUrlAttachments(URL.createObjectURL(watchFile[0]));
    }
  }, [watchFile]);
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getSupplier();
    }
  };

  useEffect(() => {
    setCountries(locations.countries);
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
  }, [id]);

  // Fetch supplier data by ID
  const getSupplier = async () => {
    try {
      setPageLoading(true);
      await httpService.getSupplier(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
          await getCities(data.country.id);
        }
      });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("contact_name", data.contact_name);
    setValue("address", data.address);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("zip_code", data.zip_code);
    setValue("description", data.description);
    setValue("city_id", data.city.id);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo_url,
    });
    setSelectedVendor(vendors?.data?.data?.find((v)=> v.id === data.vendor.id))
    setValue("outlet_id", {
      ...data.outlet,
      label: data.outlet.name,
      value: data.outlet.id,
      logo: data.outlet.image_url,
    });
    // setValue("country_id", data.country.id);
    setValue("is_active", data.is_active == 1 ? true : false);
    setExistingImageUrl(data.image);
    setExistingImageUrlAttachments(data.attachments[0]?.file);
    // Set selected country
    if (data.country?.id) {
      setCountries((prevCountries) => {
        let country = prevCountries.find(
          (item) => item.id === data.country?.id
        );
        if (country) {
          country = {
            ...country,
            label: country.name,
            value: country.id,
          };
          setValue("country_id", country);
        }
        return prevCountries;
      });
    }
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("contact_name", data.contact_name);
      formData.append("description", data.description);
      formData.append("address", data.address);
      formData.append("phone", data.phone);
      formData.append("vendor_id", data.vendor_id.id);
      if(data.outlet_id?.id) formData.append("outlet_id", data.outlet_id.id);
      formData.append("email", data.email);
      formData.append("is_active", data.is_active ? 1 : 0);
      formData.append("zip_code", data.zip_code);
      if (data.image[0]) {
        formData.append("image", data.image[0]);
      }
      if (data.country_id) {
        formData.append("country_id", data.country_id?.id);
      }
      if (data.city) {
        formData.append("city_id", data.city?.id);
      }
      if (id) {
        formData.append("id", id);
        await httpService
          .updateSupplier(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t("supplier.supplierUpdateSuccess")
              );
              setTimeout(() => {
                navigate(all_routes.supplierList);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
            console.error("Error:", error);
          });
      } else {
        await createSupplier(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new supplier
  const createSupplier = async (formData) => {
    try {
      await httpService
        .createSupplier(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              t("supplier.supplierCreateSuccess")
            );
            navigate(all_routes.supplierList);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  // Fetch cities by country ID
  const getCities = async (country_id) => {
    try {
      setCitiesLoading(true);
      setCities([]);
      await locationAPI
        .getCitiesByCountry(country_id)
        .then((response) => {
          if (response && response.status === 200) {
            setCities(response.data.data?.data);
            if (id) {
              setCities((prevCities) => {
                let city = prevCities.find(
                  (item) => item.id === getValues("city_id")
                );
                if (city) {
                  city = {
                    ...city,
                    label: city.name,
                    value: city.id,
                  };
                  setValue("city", city);
                }
                return prevCities;
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } finally {
      setCitiesLoading(false);
    }
  };

  // Handle country change event
  const onChangeCountry = (value) => {
    if (value) {
      getCities(value.id);
    }
  };
  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("supplier.suppliers"), link: "/supplier-list" },
    {
      label: id ? t("supplier.editSupplier") : t("supplier.addSupplier"),
      active: true,
    },
  ];
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setValue("outlet_id", null)
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("supplier.supplier")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Supplier Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("vendor.vendor")}
                        id="vendor_id"
                        errors={errors}
                        control={control}
                        register={register}
                        onChange={handleVendorChange}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.chooseVendor")}
                        options={vendor}
                        showLogo={true}
                        optionLabel="business_name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("outlet.outlet")}
                        id="outlet_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          // required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.chooseOutlet")}
                        options={selectedVendor?.outlet}
                        isDisabled={
                          !(
                            selectedVendor?.outlet &&
                            selectedVendor?.outlet?.length > 0
                          )
                        }
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="row mt-md-3">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.supplierName")}
                        id="name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterSupplierName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-start justify-content-between">
                      <UnlimitFileUpload
                        label={t("inputField.label.image")}
                        id="image"
                        register={register}
                        error={errors.image}
                        accept=".jpeg, .png, .jpg"
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        errorMessage={errors.image?.message}
                        onChange={handleImageChange}
                      />
                      {(id || imageUrl) && (
                        <img
                          src={
                            id
                              ? id && imageUrl
                                ? imageUrl
                                : existingImageUrl
                              : imageUrl
                          }
                          alt="Supplier Image"
                          className="img-fluid"
                          style={{ maxWidth: "50px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.contactName")}
                        id="contact_name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterContactName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Za-z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.contactNamePattern"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.contact_name}
                        errorMessage={errors.contact_name?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.phone")}
                        id="phone"
                        type="text"
                        placeholder={t("inputField.placeholder.enterPhone")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\+?\d+$/,
                            message: t("inputField.validation.phonePattern"),
                          },
                          maxLength: {
                            value: 20,
                            message: t("inputField.validation.maxLength", {
                              value: 20,
                            }),
                          },
                        }}
                        error={errors.phone}
                        errorMessage={errors.phone?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("inputField.label.email")}
                        id="email"
                        type="email"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("inputField.validation.emailPattern"),
                          },
                        }}
                        error={errors.email}
                        errorMessage={errors.email?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.address")}
                        id="address"
                        type="text"
                        placeholder={t("inputField.placeholder.enterAddress")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.address}
                        errorMessage={errors.address?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.zipCode")}
                        id="zip_code"
                        type="text"
                        placeholder={t("inputField.placeholder.enterZipCode")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[1-9][0-9]*$/,
                            message: t("inputField.validation.zipCodePattern"),
                          },
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                        }}
                        error={errors.zip_code}
                        errorMessage={errors.zip_code?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.country")}
                        id="country_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectCountry")}
                        options={countries?.filter((country) => country.flag)}
                        optionLabel="name"
                        optionValue="id"
                        onChange={onChangeCountry}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.city")}
                        id="city"
                        errors={errors}
                        control={control}
                        register={register}
                        options={cities}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectCity")}
                        optionLabel="name"
                        optionValue="id"
                        isLoading={citiesLoading}
                      />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-md-4">
                      <UnlimitToggle
                        isChecked={id ? false : true}
                        label={t("inputField.label.status")}
                        id="is_active"
                        register={register}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <UnlimitTextarea
                          label={t("inputField.label.description")}
                          id="description"
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            maxLength: {
                              value: 500,
                              message: t("inputField.validation.maxLength", {
                                value: 500,
                              }),
                            },
                          }}
                          numOfRows={3}
                          error={errors.description}
                          errorMessage={errors.description?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.supplierList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Supplier Form End */}
      </div>
    </div>
  );
};

export default SupplierForm;
