import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
// import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "../../core/json/sidebar_data";
import { store } from "../../core/redux/store";

const Sidebar = () => {
  const Location = useLocation();

  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");

  const toggleSidebar = (title) => {
    if (title == subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem) => {
    if (subitem == subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };
  function removeLastWordAfterLastDash(str) {
    const regex = /-[^-]*$/;
    return str.replace(regex, "");
  }
  const showMenuLink = (title) => {
    const link = title.link;
    // console.log(title)
    if (link) {
      return (
        <Link
          to={link}
          onClick={() => toggleSidebar(title?.label)}
          className={`${subOpen == title?.label ? "subdrop" : ""} ${
            link == Location.pathname ? "active" : ""
          }
                            `}
        >
          {title?.icon}
          <span>{title?.label}</span>
          <span className={title?.submenu ? "menu-arrow" : ""} />
        </Link>
      );
    } else {
      return (
        <a
          onClick={() => toggleSidebar(title?.label)}
          className={`${subOpen == title?.label ? "subdrop" : ""} ${
            title?.links?.includes(Location.pathname) ? "active" : ""
          }
                            `}
        >
          {title?.icon}
          <span>{title?.label}</span>
          <span className={title?.submenu ? "menu-arrow" : ""} />
        </a>
      );
    }
  };
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  let permissions = [];
  roles.forEach((obj) => {
    permissions = permissions.concat(obj.permissions);
  });
  const isAssigned = (name) => {
    return !!permissions.find((permission) => permission.name == name);
  };
  const sideBr = SidebarData(isAssigned);
  const filteredSidebarData = sideBr
    .map((mainLabel) => ({
      ...mainLabel,
      submenuItems: mainLabel.submenuItems.filter((item) => item.is_assigned),
    }))
    .filter((mainLabel) => mainLabel.submenuItems.length > 0);
  return (
    <div>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                {filteredSidebarData?.map((mainLabel, index) => (
                  <li className="submenu-open" key={index}>
                    <h6 className="submenu-hdr">{mainLabel?.label}</h6>

                    <ul>
                      {mainLabel?.submenuItems?.map((title, i) => {
                        let link_array = [];
                        title?.submenuItems?.map((link) => {
                          link_array?.push(link?.link);
                          if (link?.submenu) {
                            link?.submenuItems?.map((item) => {
                              link_array?.push(item?.link);
                            });
                          }
                          return link_array;
                        });
                        title.links = link_array;
                        return (
                          <>
                            <li className="submenu" key={i}>
                              {showMenuLink(title)}
                              <ul
                                style={{
                                  display:
                                    subOpen == title?.label ? "block" : "none",
                                }}
                              >
                                {title?.submenuItems?.map(
                                  (item, titleIndex) => (
                                    <li
                                      className="submenu submenu-two"
                                      key={titleIndex}
                                    >
                                      {/* {item.lebel} */}
                                      <Link
                                        to={item?.link}
                                        className={
                                          item?.submenuItems
                                            ?.map((link) => link?.link)
                                            .includes(Location.pathname) ||
                                          item?.link == Location.pathname
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          toggleSubsidebar(item?.label);
                                        }}
                                      >
                                        {item?.label}
                                        <span
                                          className={
                                            item?.submenu ? "menu-arrow" : ""
                                          }
                                        />
                                      </Link>
                                      <ul
                                        style={{
                                          display:
                                            subsidebar == item?.label
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        {item?.submenuItems?.map(
                                          (items, titleIndex) => (
                                            <li key={titleIndex}>
                                              {/* {item.lebel} */}
                                              <Link
                                                to={items?.link}
                                                className={`${
                                                  subsidebar == items?.label
                                                    ? "submenu-two subdrop"
                                                    : "submenu-two"
                                                } ${
                                                  items?.submenuItems
                                                    ?.map((link) => link.link)
                                                    .includes(
                                                      Location.pathname,
                                                    ) ||
                                                  items?.link ==
                                                    Location.pathname
                                                    ? "active"
                                                    : ""
                                                }`}
                                              >
                                                {items?.label}
                                              </Link>
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                    </li>
                                  ),
                                )}
                              </ul>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default Sidebar;
