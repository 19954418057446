import React, { useEffect, useRef, useState } from "react";
import UnlimitInput from "./ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import UnlimitSubmitButton from "./ui-kit/unlimit-submit-button";
import httpService from "../services/http/auth.service";
import notificationService from "../services/notification.service";
import { hydrateErrors } from "../utils/error.utils";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { register, handleSubmit, formState, setError, reset, getValues } =
    useForm();
  const { t } = useTranslation();
  const { errors } = formState;

  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef();

  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);

      const jsonData = {
        password_confirmation: data.password_confirmation,
        password: data.password,
        current_password: data.current_password,
      };
      await updatePassword(jsonData);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePassword = async (formData) => {
    try {
      const response = await httpService.updatePassword(formData);
      if (response && response.status === 200) {
        notificationService.successMessage(
          t("general.prompts.passwordUpdated")
        );
        reset();
        modalHide();
      }
    } catch (error) {
      if (error.validation_error?.errors) {
        hydrateErrors(error.validation_error?.errors, setError);
      }
    }
  };

  const modalHide = () => {
    const cancelButton = document.querySelector(".btn-cancel");
    if (cancelButton) {
      cancelButton.click();
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    const handleShow = () => {
      console.log("Handle show");
    };
    const handleHide = () => {
      reset();
    };
    if (modalElement) {
      modalElement.addEventListener("show.bs.modal", handleShow);
      modalElement.addEventListener("hide.bs.modal", handleHide);
    }
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("show.bs.modal", handleShow);
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      }
    };
  }, [reset]);

  return (
    <div>
      <div className="modal fade" id="change-password" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("general.updatePassword")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="row">
                      <div className="col-md-12">
                        <UnlimitInput
                          label={t("inputField.label.currentPassword")}
                          id="current_password"
                          type="password"
                          placeholder={t(
                            "inputField.placeholder.enterCurrentPassword"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            minLength: {
                              value: 8,
                              message: t("inputField.validation.minLength", {
                                value: 8,
                              }),
                            },
                          }}
                          error={errors.current_password}
                          errorMessage={errors.current_password?.message}
                        />
                      </div>
                      <div className="col-md-12">
                        <UnlimitInput
                          label={t("inputField.label.password")}
                          id="password"
                          type="password"
                          placeholder={t(
                            "inputField.placeholder.enterPassword"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            minLength: {
                              value: 8,
                              message: t("inputField.validation.minLength", {
                                value: 8,
                              }),
                            },
                          }}
                          error={errors.password}
                          errorMessage={errors.password?.message}
                        />
                      </div>
                      <div className="col-md-12">
                        <UnlimitInput
                          label={t("inputField.label.confirmPassword")}
                          id="password_confirmation"
                          type="password"
                          placeholder={t(
                            "inputField.placeholder.enterConfirmPassword"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            minLength: {
                              value: 8,
                              message: t("inputField.validation.minLength", {
                                value: 8,
                              }),
                            },
                            validate: (value) =>
                              value === getValues("password") ||
                              t("inputField.validation.matchPassword"),
                          }}
                          error={errors.password_confirmation}
                          errorMessage={errors.password_confirmation?.message}
                        />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        {t("general.button.cancel")}
                      </button>
                      <UnlimitSubmitButton
                        label={t("general.button.submit")}
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
