import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createThirdParty = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.thirdParty.thirdParty.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateThirdParty = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.thirdParty.thirdParty.update}`,
    true,
    data,
    json?false:true,
  );
};
const thirdPartyList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.thirdParty.thirdParty.list}`,
    true,
    true,
    data,
  );
};
const deleteThirdParty = async (id) => {
  const url = `${ENDPOINT.thirdParty.thirdParty.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getThirdParty = async (id) => {
  const url = `${ENDPOINT.thirdParty.thirdParty.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getThirdParty,
  createThirdParty,
  updateThirdParty,
  thirdPartyList,
  deleteThirdParty,
};
