import React, { useState } from "react";
import ImageWithBasePath from "../core/img/imagewithbasebath";
import DocumentModal from "./view-document";

const UnlimitTicketDetails = ({ imageSrc, name, description, attachments }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  return (
    <div className="p-3 border-bottom">
      <div className="d-flex align-items-center" >
        <ImageWithBasePath  type="link" src={imageSrc} className="rounded-circle" style={{height:"30px", width:"30px"}}/>
        <p className="fw-bold fs-6 mb-0 ms-2 text-primary">{name}</p>
      </div>
      <p className="mt-3">{description}</p>
      {attachments && attachments.length > 0 && (
        <div>
          <h6 className="mb-2">
            {attachments.length > 1 ? "Attachments" : "One Attachment"}
          </h6>
          {attachments && attachments.length > 0 && (
            <div className="d-flex">
              {attachments.map((attachment, index) => (
                <div
                  key={index}
                  onClick={() => handleViewDocument(attachment)}
                  className="me-2"
                >
                  {attachment.type === "jpg" ||
                  attachment.type === "png" ||
                  attachment.type === "jpeg" ? (
                    <div className="image-icon">
                      <img
                        src={attachment.file}
                        alt={attachment.name}
                        className="rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  ) : (
                    <div className="attachment-container">
                    <div className="pdf-icon">
                      <ImageWithBasePath
                        src="assets/img/pdf-minimal-icon.png"
                        alt="PDF Icon"
                      />
                    </div>
                    <div className="attachment-name d-flex text-black">
                      <ImageWithBasePath
                        src="assets/img/pdf-minimal-icon-2.png"
                        height={15}
                        width={15}
                        alt="PDF Icon"
                      />
                      <p className="ms-2">{attachment.name}</p>
                    </div>
                    <div className="corner-ribbon"></div>
                  </div>
                  )}
                 
                </div>
              ))}
            </div>
          )}
          <DocumentModal
            show={showModal}
            onHide={() => setShowModal(false)}
            document={selectedDocument}
          />
        </div>
      )}
    </div>
  );
};

export default UnlimitTicketDetails;
