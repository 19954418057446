import React from "react";

const UnlimitToggle = (props) => {
  const { isChecked,label, id, column, register, rules, error,errorMessage, ...attr } =
    props;
  return (
    <div className="unlimit-uikit-input">
      <div
        className={`status-toggle modal-status d-flex ${column ? "flex-column" : "align-items-center"}`}
      >
        <span className="status-label">{label}</span>
        <div className={`${column ? "mt-3" : "ms-2"}`}>
          <input
            type="checkbox"
            className="check"
            id={id}
            {...register(id, rules)}
            {...attr}
            defaultChecked={isChecked}
          />
          <label htmlFor={id} className="checktoggle" />
        </div>
        {error && <span className="error">{errorMessage}</span>}
      </div>
    </div>
  );
};

export default UnlimitToggle;
