import React from "react";
import { ConfigProvider, Table } from "antd";
import enUS from "antd/locale/en_US";
import daDK from "antd/locale/da_DK";
import { useTranslation } from "react-i18next";

const locales = {
  en: enUS,
  da: daDK,
};
const UnlimitTable = (props) => {
  const { i18n } = useTranslation();
  const currentLocale = locales[i18n.language] || locales.da;
  return (
    <ConfigProvider locale={currentLocale}>
      <Table
        className="table datanew dataTable no-footer"
        scroll={{
          x: 1000
        }}
        {...props}
        pagination={props.pagination}
      />
    </ConfigProvider>
  );
};

export default UnlimitTable;
