import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createTagType = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.tagType.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateTagType = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.tagType.update}`,
    true,
    data,
    json?false:true,
  );
};
const tagTypesList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.tagType.list}`,
    true,
    true,
    data,
  );
};
const deleteTagType = async (id) => {
  const url = `${ENDPOINT.products.tagType.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getTagType = async (id) => {
  const url = `${ENDPOINT.products.tagType.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getTagType,
  createTagType,
  updateTagType,
  tagTypesList,
  deleteTagType,
};
