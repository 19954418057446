import { createSlice } from "@reduxjs/toolkit";
import locationService from "../../../services/http/location.service";
const initialState = {
  countries: [],
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const { setCountries, syncCountries } = locationsSlice.actions;

export default locationsSlice.reducer;
