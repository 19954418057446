import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createCampaign = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.campaign.create}`,
    true,
    data,
    true,
  );
};
const campaignFromCatalog = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.campaign.fromCatalog}`,
    true,
    data,
    true,
  );
};
const updateCampaign = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.campaign.update}`,
    true,
    data,
    json?false:true,
  );
};
const campaignList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.campaign.list}`,
    true,
    true,
    data,
  );
};
const deleteCampaign = async (id) => {
  const url = `${ENDPOINT.products.campaign.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getCampaign = async (id) => {
  const url = `${ENDPOINT.products.campaign.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getCampaign,
  createCampaign,
  updateCampaign,
  campaignList,
  deleteCampaign,
  campaignFromCatalog
};
