import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createSystemLanguage = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.systemLanguage.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateSystemLanguage = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.systemLanguage.update}`,
    true,
    data,
    json?false:true,
  );
};
const updateSystemLanguageJson = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.systemLanguage.jsonUpdate}`,
    true,
    data,
    json?false:true,
  );
};
const systemLanguageList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.systemLanguage.list}`, true, true, data);
};
const getSystemLanguageJson = async (id,type,vendor) => {
  const url = `${ENDPOINT.settings.systemLanguage.jsonShow}`.replace(":id", id).replace(":type", type);
  const urlAPP = `${ENDPOINT.settings.systemLanguage.jsonShowAPP}`.replace(":id", id).replace(":type", type).replace(":vendorId", vendor);
  return await API.getMethod(type == "APP" ? urlAPP : url, true);
};
const deleteSystemLanguage = async (id) => {
  const url = `${ENDPOINT.settings.systemLanguage.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getSystemLanguage = async (id) => {
  const url = `${ENDPOINT.settings.systemLanguage.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getSystemLanguage,
  createSystemLanguage,
  updateSystemLanguage,
  systemLanguageList,
  deleteSystemLanguage,
  updateSystemLanguageJson,
  getSystemLanguageJson
};
