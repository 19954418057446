import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createPledgePrice = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.pledgePrice.create}`,
    true,
    data,
    json?false:true,
  );
};
const updatePledgePrice = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.pledgePrice.update}`,
    true,
    data,
    json?false:true,
  );
};
const pledgePriceList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.pledgePrice.list}`,
    true,
    true,
    data,
  );
};
const deletePledgePrice = async (id) => {
  const url = `${ENDPOINT.products.pledgePrice.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getPledgePrice = async (id) => {
  const url = `${ENDPOINT.products.pledgePrice.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getPledgePrice,
  createPledgePrice,
  updatePledgePrice,
  pledgePriceList,
  deletePledgePrice,
};
