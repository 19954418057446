import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/product.service";
import usePagination from "../../../custom-hooks/usePagination";
import { Link, useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import { useFieldArray, useForm } from "react-hook-form";
import ProductFromCatalogSearch from "./product-from-catalog-search";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { Checkbox } from "antd";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { all_routes } from "../../../Router/all_routes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { hydrateErrors } from "../../../utils/error.utils";
import notificationService from "../../../services/notification.service";
import { useTranslation } from "react-i18next";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

const ProductFromCatalog = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [catalogList, setCatalogList] = useState([]);
  const [isListVisible, setIsListVisible] = useState(false);
  const [selectedCatalogItems, setSelectedCatalogItems] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { control, handleSubmit, register, formState, setError } = useForm();
  const { errors } = formState;
  const { fields, append } = useFieldArray({
    control,
    name: "catalog_variants",
  });
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();

  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  useEffect(() => {
    setVendor(vendors?.data?.data);
  }, []);
  const handleCheckboxChange = (record, checked) => {
    if (checked) {
      setSelectedCatalogItems([...selectedCatalogItems, record]);
    } else {
      setSelectedCatalogItems(
        selectedCatalogItems.filter((item) => item.id !== record.id)
      );
    }
  };
  const handleSelectAll = (checked) => {
    const selectedItems = checked ? [...catalogList] : [];
    setSelectedCatalogItems(selectedItems);
  };
  const columns = [
    {
      title: <Checkbox onChange={(e) => handleSelectAll(e.target.checked)} />,
      dataIndex: "index",
      align: "center",
      render: (value, item, index) => (
        <Checkbox
          style={{ color: "red" }}
          checked={selectedCatalogItems.some(
            (selectedItem) => selectedItem.id === item.id
          )}
          onChange={(e) => handleCheckboxChange(item, e.target.checked)}
        />
      ),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "catalog.category.ean",
      render: (text, record) => record?.ean ?? "N/A",
    },
    {
      title: t("tableColumnName.productName"),
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.product_images[0]?.file}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.category"),
      dataIndex: "catalog.category.name",
      render: (text, record) => record?.category?.name ?? "N/A",
    }, 
     {
      title: t("inputField.label.brand"),
      dataIndex: "catalog.brand.name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.brand?.image}
            alt="Brand"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {text}
        </div>
      ),
    },
    {
      title: t("tableColumnName.sku"),
      dataIndex: "sku",
      render: (text, record, index) => (
        <UnlimitInput
          id={`sku_${index}`}
          type="text"
          placeholder={t("inputField.placeholder.enterSKU")}
          register={register}
          rules={{
            required:
              selectedCatalogItems.some((item) => item.id === record.id) &&
              t("inputField.validation.required"),
            maxLength: {
              value: 18,
              message: t("inputField.validation.maxLength", {
                value: 18,
              }),
            },
            minLength: {
              value: 6,
              message: t("inputField.validation.minLength", {
                value: 6,
              }),
            },
          }}
          error={errors?.[`sku_${index}`]}
          errorMessage={errors?.[`sku_${index}`]?.message}
        />
      ),
    },

    {
      title: t("tableColumnName.minOrderQty"),
      dataIndex: "min_order_qty",
      render: (text, record, index) => (
        <UnlimitInput
          id={`min_order_qty_${index}`}
          type="number"
          placeholder={t("inputField.placeholder.enterMinOrderQty")}
          register={register}
          rules={{
            required:
              selectedCatalogItems.some((item) => item.id === record.id) &&
              t("inputField.validation.required"),
            min: {
              value: 1,
              message: t("inputField.validation.minValue", {
                min: 1,
              }),
            },
          }}
          error={errors?.[`min_order_qty_${index}`]}
          errorMessage={errors?.[`min_order_qty_${index}`]?.message}
        />
      ),
    },
    {
      title: t("inputField.label.profit%"),
      dataIndex: "profit",
      render: (text, record, index) => (
        <UnlimitInput
          id={`profit_${index}`}
          type="number"
          placeholder={t("inputField.placeholder.enterValue")}
          register={register}
          rules={{
            pattern: {
              value: /^[0-9]+(\.[0-9]{1,2})?$/,
              message: t("inputField.validation.invalidProfit"),
            },
            min: {
              value: 0,
              message: t("inputField.validation.minValue", {
                min: 0,
              }),
            },
            max: {
              value: 100,
              message: t("inputField.validation.maxValue", {
                max: 100,
              }),
            },
          }}
          step="0.01"
          error={errors?.[`profit_${index}`]}
          errorMessage={errors?.[`profit_${index}`]?.message}
        />
      ),
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              onClick={() => navigate(`/catalog/${record.id}/details`)}
            >
              <i className="feather-eye"></i>
            </a>
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("product.product"), link: "/product-list" },
    { label: t("product.addProductFromCatalog"), active: true },
  ];

  const MySwal = withReactContent(Swal);
  const onSubmit = (data) => {
    const formData = new FormData();

    if (selectedCatalogItems.length < 1) {
      return MySwal.fire({
        title: t("inputField.validation.warning"),
        text: t("inputField.validation.selectOneItem"),
        className: "btn btn-warning",
        confirmButtonText: t("general.button.ok"),
        customClass: {
          confirmButton: "btn btn-warning",
        },
      });
    }

    // Append catalog_variants array to formData
    for (let i = 0; i < selectedCatalogItems.length; i++) {
      const sku = data[`sku_${i}`];
      const profit = data[`profit_${i}`];
      const minOrderQty = data[`min_order_qty_${i}`];
      if (sku !== undefined && minOrderQty !== undefined) {
        // Construct the keys for each catalog variant
        formData.append(`catalog_variants[${i}][vendor_id]`, data.vendor_id.id);
        formData.append(
          `catalog_variants[${i}][catalog_id]`,
          catalogList[i].id
        );
        formData.append(`catalog_variants[${i}][sku]`, sku);
        formData.append(`catalog_variants[${i}][profit]`, profit);
        formData.append(`catalog_variants[${i}][min_order_qty]`, minOrderQty);
      }
    }

    productFromCatalog(formData);
  };
  const productFromCatalog = async (formData) => {
    setIsLoading(true);
    try {
      await httpService
        .productFromCatalog(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              t("product.productCreateSuccess")
            );
            navigate(all_routes.productList);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("product.addProductFromCatalog")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <ProductFromCatalogSearch
            setIsListVisible={setIsListVisible}
            setCatalogList={setCatalogList}
          />
          {/* /Search */}
          {catalogList.length > 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-4 mb-3">
                <UnlimitSelect
                  label={t("inputField.placeholder.chooseVendor")}
                  id={`vendor_id`}
                  errors={errors}
                  control={control}
                  register={register}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t("inputField.placeholder.chooseVendor")}
                  options={vendor}
                  showLogo={true}
                  optionLabel="business_name"
                  optionValue="id"
                />
              </div>

              <div className="card table-list-card">
                <div className="card-body">
                  {/* /Filter */}
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={catalogList}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <Link to={all_routes.productList} className="me-2">
                  <button type="button" className="btn btn-cancel">
                    {t("general.button.cancel")}
                  </button>
                </Link>
                <UnlimitSubmitButton
                  isLoading={isLoading}
                  label={t("general.button.submit")}
                />
              </div>
            </form>
          ) : (
            <>
              {isListVisible && (
                <div className="pt-4 ps-3">
                  <span className="fw-bold fs-6">No Results Found!</span>
                </div>
              )}
            </>
          )}
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default ProductFromCatalog;
