import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createRole = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.userManage.role.create}`,
    true,
    data,
    true,
  );
};
const updateRole = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.userManage.role.update}`,
    true,
    data,
    true,
  );
};
const roleList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.userManage.role.list}`,
    true,
    true,
    data,
  );
};
const deleteRole = async (id) => {
  const url = `${ENDPOINT.userManage.role.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getRole = async (id) => {
  const url = `${ENDPOINT.userManage.role.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getRole,
  createRole,
  updateRole,
  roleList,
  deleteRole,
};
