import React, { memo } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import PDFViewer from "./pdf-viewer";

const DocumentModal = ({ show, onHide, document }) => {
  if (!document) {
    return null;
  }

  const { type, name, file } = document;
  const isPdf = type === "pdf";

  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isPdf ? (
          <PDFViewer pdfUrl={file} />
        ) : (
          <img src={file} alt={name} className="img-fluid" />
        )}
      </Modal.Body>
    </Modal>
  );
};

DocumentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  document: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    file: PropTypes.string,
  }),
};

export default memo(DocumentModal);
