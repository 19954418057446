import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/peoples/customers.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import usePagination from "../../../custom-hooks/usePagination";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
const CustomersList = () => {
  const { hasPermission, roleType } = useRoleAndPerm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState("");
  const vendors = useSelector((state) => state.vendors.vendors);
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getCustomerList = async (
    pagination = paginationParams.pagination,
    filters
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
    };
    setLoading(true);
    await httpService
      .customerList(data)
      .then((response) => {
        if (response.status == 200) {
          setCustomers(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, sorter) => {
    // cpr, name, email, phone r id
    const filters = {
      name: nameFilter,
      id: idFilter,
      email: emailFilter,
      phone: phoneFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value) // Double negation for truthy check
    );
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getCustomerList(pagination, nonNullFilters);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      setIdFilter("");
      setNameFilter("");
      setEmailFilter("");
      setPhoneFilter("");
      const filters = {};
      await getCustomerList(paginationParams.pagination, filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteCustomer(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("customer.customerDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getCustomerList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("customer.customerCode"),
      dataIndex: "customer_code",
    },
    {
      title: t("customer.loyaltyID"),
      dataIndex: "loyalty_id",
    },
    {
      title: t("tableColumnName.name"),
      dataIndex: "name",
      render: (text, record) => record.first_name + " " + record.last_name,
    },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    // {
    //   title: t("tableColumnName.email"),
    //   dataIndex: "email",
    // },
    // {
    //   title: t("tableColumnName.redeemPoints"),
    //   dataIndex: "points",
    // },
    {
      title: t("tableColumnName.availablePoints"),
      dataIndex: "loyalty_points",
      align:"center"
    },
    {
      title: t("tableColumnName.country"),
      key: "country",
      dataIndex: "country_id",
      sorter: true,
      render: (text, record) => (
        <div className="d-flex align-items-center">
          {record.country?.iso2 && (
            <UnlimitCountryIcon iso2={record.country?.iso2} />
          )}
          <div className="ms-2">{record.country?.name}</div>
        </div>
      ),
    },
    {
      title: t("customer.userType"),
      // dataIndex: "cpr", Danish or international
      render: (text, record) =>
        record.cpr ? (
          <span className="badge badge-linesuccess">
            <a>Danish</a>
          </span>
        ) : (
          <span className="badge badge-linewarning">
            <a>International</a>
          </span>
        ),
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) =>
        record.is_active == 1 ? (
          <span className="badge badge-linesuccess">
            <a>{t("general.actions.active")}</a>
          </span>
        ) : (
          <span className="badge badge-linedanger">
            <a>{t("general.actions.deactive")}</a>
          </span>
        ),
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["customer-view", "customer-delete"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["customer-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/customers/${record.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
            {hasPermission(["customer-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("customer.customerList"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("customer.customerList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
            <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder={t("customer.loyaltyID")}
                          className="form-control form-control-sm formsearch-custom ps-2"
                          onChange={(e) => setIdFilter(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleTableChange();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterName")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={emailFilter}
                        onChange={(e) => setEmailFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder={t("inputField.placeholder.enterPhone")}
                          className="form-control form-control-sm formsearch-custom ps-2"
                          value={phoneFilter}
                          onChange={(e) => setPhoneFilter(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleTableChange();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={customers}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default CustomersList;
