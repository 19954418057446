import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import Iphone from "./Iphone.js";
import './3dStyles.css'
import { useNavigate } from "react-router-dom";
export default function ThreedFileWindowIphone({height,id}) {
  const navigate = useNavigate();
  return (
    <div style={{height, background: "linear-gradient(292deg, #131416 0%, rgb(79 79 79) 49.26%, #121315 99.52%)"}} >
      <img src="/assets/img/icons/maximize-1-svgrepo-com 1.svg" alt="" className=" expand-button color-primary" onClick={()=> navigate(`/3d-sample-catalog/${id}/view`)}/>
      <Canvas className="canvas">
        <OrbitControls enableZoom={false} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[-2, 5, 2]} />
        <Suspense fallback={null}>
          <Iphone />
        </Suspense>
      </Canvas>
    </div>
  );
}
