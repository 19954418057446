import React from 'react';

const LiveCCTVStream = ({ src, width, height }) => {
  return (
    <div>
      <iframe
        src={src}
        width={width}
        height={height}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title="Live CCTV Stream"
      ></iframe>
    </div>
  );
};

export default LiveCCTVStream;
