import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import {
  Filter,
  Globe,
  Plus,
  Smartphone,
  Upload,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import httpService from "../../../services/http/settings/system-language.service";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment/moment";
import SystemLanguageDetail from "../../../components/system-language-detail";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitCountryIcon from "../../../components/ui-kit/unlimit-country-icon";
import { useTranslation } from "react-i18next";
import { useGetSystemLanguage } from "../../../services/http/side-Effects/systemLanguageActions";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useSelector } from "react-redux";
const SystemLanguageList = () => {
  const vendors = useSelector((state) => state.vendors.vendors);
  const [vendor, setVendor] = useState([]);
  const { hasPermission } = useRoleAndPerm();
  const [systemLanguage, setSystemLanguage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [systemLanguages, setSystemLanguages] = useState([]);
  const fetchSystemLanguage = useGetSystemLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getSystemLanguagesList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .systemLanguageList(data)
      .then((response) => {
        if (response.status == 200) {
          setSystemLanguages(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
        fetchSystemLanguage();
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getSystemLanguagesList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    setVendor(vendors?.data?.data);
    getSystemLanguagesList();
  }, []);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      setNameFilter("");
      const filters = {};
      await getSystemLanguagesList(paginationParams.pagination, filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteSystemLanguage(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("systemLanguage.systemLanguage"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getSystemLanguagesList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onHideModal = async () => {
    console.log("hide modal");
    setSystemLanguage(null);
    await getSystemLanguagesList();
  };
  const editSystemLanguage = (row) => {
    setSystemLanguage(row);
    const addButton = document.querySelector(".btn-added");
    if (addButton) {
      addButton.click();
    }
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("language.language"),
      key: "country_name",
      dataIndex: "name",
      render: (text, record) => {
        return (
          <div className="d-flex">
            {record.country?.logo && <UnlimitCountryIcon iso2={record?.iso2} />}
            <div>{record?.name}</div>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: t("tableColumnName.shortCode"),
      dataIndex: "short_code",
      sorter: true,
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
      sorter: true,
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission([
        "json-translation-create",
        "language-delete",
        "language-edit",
      ]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["json-translation-create"]) && (
              <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
                <p
                  className="mb-0 p-2 nav-link dropdown-toggle language-dropdown-header d-flex align-items-center"
                  data-bs-toggle="dropdown"
                  role="button"
                  style={{ width: "auto" }}
                >
                  <a href="#">
                    <Smartphone />
                  </a>
                </p>
                <div className="dropdown-menu dropdown-menu-right scrollable-menu">
                  {vendor.map((ven) => {
                    return (
                      <p
                        key={ven.id}
                        onClick={() =>
                          navigate(`/system-language-Json/${record.id}/1/${ven.id}`)
                        }
                        style={{ cursor: "pointer" }}
                        className="p-2 d-flex align-items-center "
                      >
                        <ImageWithBasePath
                          alt={text}
                          type="link"
                          src={ven.logo_url}
                          style={{ width: 30, height: 30, marginRight: 10 }}
                        />
                        <p>{ven.business_name}</p>
                      </p>
                    );
                  })}
                </div>
              </li>
            )}
            {hasPermission(["json-translation-create"]) && (
              <a
                className="me-2 p-2"
                onClick={() => navigate(`/system-language-Json/${record.id}/2/0`)}
              >
                <Globe />
              </a>
            )}
            {hasPermission(["language-edit"]) && (
              <a
                className="me-2 p-2"
                onClick={() => editSystemLanguage(record)}
              >
                <i className="feather-edit-2"></i>
              </a>
            )}
            {hasPermission(["language-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("systemLanguage.systemLanguage"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("systemLanguage.systemLanguage")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div
              className="page-btn"
              style={{
                display: hasPermission(["language-add"]) ? "block" : "none",
              }}
            >
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add-systemLanguage"
                className="btn btn-added"
              >
                <Plus className="me-2 iconsize" />
                {t("general.button.addNew")}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterLanguage")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={systemLanguages}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <SystemLanguageDetail
        onHide={onHideModal}
        systemLanguage={systemLanguage}
      />
    </div>
  );
};

export default SystemLanguageList;
