import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createOutlet = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.outlet.outlet.create}`,
    true,
    data,
    true,
  );
};
const updateOutlet = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.outlet.outlet.update}`,
    true,
    data,
    json?false:true,
  );
};
const outletList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.outlet.outlet.list}`,
    true,
    true,
    data,
  );
};
const deleteOutlet = async (id) => {
  const url = `${ENDPOINT.outlet.outlet.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getOutlet = async (id) => {
  const url = `${ENDPOINT.outlet.outlet.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const createOutletDocument = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.outlet.outlet.createAttachments}`,
    true,
    data,
    true,
  );
};
export default {
  getOutlet,
  createOutlet,
  updateOutlet,
  outletList,
  deleteOutlet,
  createOutletDocument
};
