import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createUOM = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.UOM.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateUOM = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.UOM.update}`,
    true,
    data,
    json?false:true,
  );
};
const uomList = async (data) => {
  return await API.getMethod(`${ENDPOINT.products.UOM.list}`, true, true, data);
};
const deleteUOM = async (id) => {
  const url = `${ENDPOINT.products.UOM.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getUOM = async (id) => {
  const url = `${ENDPOINT.products.UOM.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getUOM,
  createUOM,
  updateUOM,
  uomList,
  deleteUOM,
};
