import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/product.service";
import { useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitStepper from "../../../components/ui-kit/unlimit-stepper";
import ProductStep1 from "./product-step1";
import ProductStep2 from "./product-step2";
import { useTranslation } from "react-i18next";

const ProductForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(new FormData());
  const [activeStep, setActiveStep] = useState(0);
  const [existingData, setExistingData] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const { t } = useTranslation();
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    if (id) {
      setPageLoading(true)
      await getProduct();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
  }, [id]);

  // Fetch product data by ID
  const getProduct = async () => {
    await httpService.getProduct(id).then(async (response) => {
      if (response && response.status === 200) {
        const data = response.data.data;
        setExistingData(data);
      }
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setPageLoading(false)
    })
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('product.product'), link: "/product-list" },
    { label: id ? t('product.editProduct') : t('product.addProduct'), active: true },
  ];

  if (activeStep === 0) {
    breadcrumbItems.push({ label: t('general.general'), active: true });
  } else if (activeStep === 1) {
    breadcrumbItems.push({ label: t('catalog.productImages'), active: true });
  } else if (activeStep === 2) {
    breadcrumbItems.push({ label: t('breadcrumbs.variants'), active: true });
  }

  const steps = [
    { title: t('general.general'), component: ProductStep1 },
    { title: t('catalog.productImages'), component: ProductStep2 },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{id ? t('product.editProduct') : t('product.addProduct')}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <UnlimitStepper
          steps={steps}
          setActiveStep={setActiveStep}
          formData={formData}
          setFormData={setFormData}
          activeStep={activeStep}
          id={id}
          existingData={existingData}
          isLoading={pageLoading}
        />
      </div>
    </div>
  );
};

export default ProductForm;