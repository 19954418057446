import API from "./base.service";
import { ENDPOINT } from "../constants.service";

const getCountries = async (data = { page: 1, per_page: 500 }) => {
  return await API.getMethod(
    `${ENDPOINT.locations.countries_list}`,
    true,
    true,
    data,
  );
};
const getCities = async (data) => {
  return await API.getMethod(`${ENDPOINT.locations.cities_list}`, true);
};
const getCitiesByCountry = async (id) => {
  const data= {
    per_page:10000
  }
  const url = `${ENDPOINT.locations.cities_by_country}`.replace(":id", id);
  return await API.getMethod(url, true,true,data);
};
export default {
  getCountries,
  getCities,
  getCitiesByCountry,
};
