import { useSelector } from 'react-redux';
import outletHttpService from "../services/http/stores/outlet.service";
import React, { useEffect, useState } from "react";
const useRoleAndPerm = () => {
    const auth = useSelector(state => state.auth);
    const roles = auth.roles ?? [];
    const roleType = roles[0]?.role_type?.toLowerCase();
    const [outlets, setOutlets] = useState([]);
    async function fetchOutlet() {
        try {
          const outlet = await outletHttpService.outletList();
          setOutlets(outlet?.data?.data?.data);
        } catch (error) {
          console.error("Error fetching outlet data:", error);
        }
      }
      useEffect(()=>{
        fetchOutlet()
      },[])
    const vendors = useSelector(state => state.vendors.vendors);
    const hasPermission = (requiredPermissions) => {
        return requiredPermissions.some(permissionName =>
            roles[0]?.permissions.some(permission => permission.name === permissionName)
        );
    };
    
    const isVendorOrOutlet = roleType === "vendor" || roleType === "outlet";
    const getRedirectLink = (linkType) => {
        switch (linkType) {
            case "vendor":
                if (roleType === "vendor" && vendors?.data?.data[0]?.id) {
                    return vendors?.data?.data.length > 0 ? 'vendor-list' : `/vendor/${vendors?.data?.data[0]?.id}/view`;
                }
                return "/vendor-list";
            case "outlet":
                if (roleType === "outlet" && outlets[0]?.id) {
                    return outlets.length > 0 ? 'outlet-list': `/outlets/${outlets[0]?.id}/details`;
                }
                return "/outlets-list";
            default:
                return "/";
        }
    };
    return { hasPermission, isVendorOrOutlet,roleType,getRedirectLink };
};

export default useRoleAndPerm;
