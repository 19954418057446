import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createPermission = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.userManage.permission.create}`,
    true,
    data,
    true,
  );
};
const updatePermission = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.userManage.permission.update}`,
    true,
    data,
    true,
  );
};
const permissionList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.userManage.permission.list}`,
    true,
    true,
    data,
  );
};
const deletePermission = async (id) => {
  const url = `${ENDPOINT.userManage.permission.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getPermission = async (id) => {
  const url = `${ENDPOINT.userManage.permission.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getPermission,
  createPermission,
  updatePermission,
  permissionList,
  deletePermission,
};
