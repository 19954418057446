import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  Filter,
  Plus,
  PlusCircle,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import { DatePicker } from "antd";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/brand.service";
import { useNavigate } from "react-router-dom";
import usePagination from "../../../custom-hooks/usePagination";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import ConfirmationAlert from "../../../components/confirm-alert";
import { useTranslation } from "react-i18next";
import { store } from "../../../core/redux/store";
import useRoleAndPerm from "../../../utils/permissionFunction";
const BrandList = () => {
  const { hasPermission } = useRoleAndPerm();
  const { brandcreate } = all_routes;
  const [outlets, setOutlets] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [descFilter, setDescFilter] = useState("");
  const { t } = useTranslation();
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      setNameFilter("");
      setDescFilter("");
      const filters = {};
      await getBrandsList(paginationParams.pagination, filters);
    }
  };
  const navigate = useNavigate();
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.image"),
      dataIndex: "image",
      render: (text, record) => (
        <span className="productimgname">
          <a className="product-img stock-img">
            <ImageWithBasePath
              type="link"
              alt={record.name ?? ""}
              src={record.image}
            />
          </a>
        </span>
      ),
      width: "15%",
    },
    {
      title: t("brand.brand"),
      dataIndex: "name",
      sorter:true
    },
    {
      title: t("brand.brandDescription"),
      dataIndex: "description",
      render: (text) => text.length > 70 ? `${text.substring(0, 70)}...` : text,
    },
    ...(hasPermission(["brand-edit", "brand-delete"]) ? [{
      title: t("tableColumnName.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
          {hasPermission(["brand-edit"]) && (
            <a
              className="me-2 p-2"
              onClick={() => navigate(`/brand/${record.id}/edit`)}
            >
              <i className="feather-edit-2"></i>
            </a>
            )}
             {hasPermission(["brand-delete"]) && (
            <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />)}
          </div>
        </td>
      ),
    }]:[])
  ];
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteBrand(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("brand.brandDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getBrandsList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getBrandsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .brandsList(data)
      .then((response) => {
        if (response.status == 200) {
          setBrands(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      description: descFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getBrandsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getBrandsList();
  }, []);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("brand.brand"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("brand.brands")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["brand-add"]) && (
            <div className="page-btn">
              <Link to={brandcreate} className="btn btn-added">
                <Plus className="me-2 iconsize" />
                {t("general.button.addNew")}
              </Link>
            </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                <input
                  type="text"
                  placeholder={t("inputField.placeholder.enterName")}
                  className="form-control form-control-sm formsearch"
                />
                <Link to className="btn btn-searchset">
                  <i data-feather="search" className="feather-search" />
                </Link>
              </div> */}
                </div>
              </div>
              {/* /Filter */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.placeholder.enterBrandName")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder={t("inputField.placeholder.enterBrandDescription")}
                          className="form-control form-control-sm formsearch-custom ps-2"
                          value={descFilter}
                          onChange={(e) => setDescFilter(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleTableChange();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={brands}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandList;
