import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import Iphone from "./Iphone.js";
import "./3dStyles.css";
import { useNavigate, useParams } from "react-router-dom";
import { Minimize2 } from "react-feather";
export default function ThreedFileWindowIphoneExpanded() {
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div className="page-wrapper">
      <div
        style={{
          height: "86vh",
          background:
            "linear-gradient(292deg, #131416 0%, rgb(79 79 79) 49.26%, #121315 99.52%)",
        }}
      >
        <div
          className="minimize-button"
          onClick={() => navigate(`/catalog/${id}/details`)}
        >
          <Minimize2 size={36} />
        </div>
        <Canvas className="canvas">
          <OrbitControls enableZoom={false} />
          <ambientLight intensity={0.5} />
          <directionalLight position={[-2, 5, 2]} />
          <Suspense fallback={null}>
            <Iphone />
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
}
