import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/peoples/contact.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import designaionHttpService from "../../../services/http/peoples/designation.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  const [vendor, setVendor] = useState([]);
  const [contactType, setContactType] = useState([]);
  const [outlets, setOutlets] = useState([]);

  const vendors = useSelector((state) => state.vendors.vendors);

  async function fetchOutlet() {
    try {
      const outlet = await outletHttpService.outletList();
      setOutlets(outlet?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  }
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getContact();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
    if (id) {
      getContact();
    }
    fetchOutlet();
    fetchContactType();
  }, [id]);

  const getContact = async () => {
    try {
      setPageLoading(true);
      await httpService.getContact(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
        }
      });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("address", data.address);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("description", data.description);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo,
    });
    setValue("outlet_id", {
      ...data.outlet,
      label: data.outlet.name,
      value: data.outlet.id,
    });
    setValue("contact_type", {
      ...data.contact,
      label: data.contact.name,
      value: data.name.id,
    });
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const requestData = {
        name: data.name,
        description: data.description,
        phone: data.phone,
        email: data.email,
        vendor_id: data.vendor_id.id,
        outlet_id: data.outlet_id.id,
        contact_type: data.contact_type.id
      };
  
      if (id) {
        requestData.id = id;
        await httpService
          .updateContact(requestData,true)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                "Contact updated successfully!"
              );
              setTimeout(() => {
                navigate(all_routes.contactList);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createContact(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new contact
  const createContact = async (formData) => {
    await httpService
      .createContact(formData,true)
      .then((response) => {
        if (response && response.status === 200) {
          notificationService.successMessage(
            t("contactList.contactListCreateSuccess")
          );
          navigate(all_routes.contactList);
        }
      })
      .catch((error) => {
        if (error.validation_error.errors) {
          hydrateErrors(error.validation_error.errors, setError);
        }
      });
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("contactList.contactList"), link: "/contact-list" },
    {
      label: id
        ? t("contactList.editContactList")
        : t("contactList.addContactList"),
      active: true,
    },
  ];

  function fetchContactType() {
    designaionHttpService
      .designationsList()
      .then((response) => {
        const contactType = response?.data?.data?.data;
        setContactType(contactType);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("contactList.contactList")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Contact Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("vendor.vendor")}
                        id="vendor_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t(
                          "inputField.placeholder.enterVendorName"
                        )}
                        options={vendor}
                        showLogo={true}
                        optionLabel="business_name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("outlet.outlet")}
                        id="outlet_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.chooseOutlet")}
                        options={outlets}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("inputField.label.contactType")}
                        id="contact_type"
                        errors={errors}
                        control={control}
                        register={register}
                        options={contactType}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t(
                          "inputField.placeholder.chooseContactType"
                        )}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.name")}
                        id="name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterContactName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Za-z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.contactNamePattern"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.phone")}
                        id="phone"
                        type="text"
                        placeholder={t("inputField.placeholder.enterPhone")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\+?\d+$/,
                            message: t("inputField.validation.phonePattern"),
                          },
                          maxLength: {
                            value: 20,
                            message: t("inputField.validation.maxLength", {
                              value: 20,
                            }),
                          },
                        }}
                        error={errors.phone}
                        errorMessage={errors.phone?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.email")}
                        id="email"
                        type="email"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("inputField.validation.emailPattern"),
                          },
                        }}
                        error={errors.email}
                        errorMessage={errors.email?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <UnlimitTextarea
                          label={t("inputField.label.description")}
                          id="description"
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            maxLength: {
                              value: 500,
                              message: t("inputField.validation.maxLength", {
                                value: 500,
                              }),
                            },
                          }}
                          numOfRows={3}
                          error={errors.description}
                          errorMessage={errors.description?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.contactList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
