import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/catalog.service";
import { useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitStepper from "../../../components/ui-kit/unlimit-stepper";
import CatalogStep1 from "./catalog-step1";
import CatalogStep2 from "./catalog-step2";
import { useTranslation } from "react-i18next";

const CatalogForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [formData, setFormData] = useState(new FormData());
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [existingData, setExistingData] = useState(null);
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    if (id) {
      await getCatalog();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
  }, [id]);

  // Fetch catalog data by ID
  const getCatalog = async () => {
    setIsLoading(true)
    await httpService.getCatalog(id).then(async (response) => {
      if (response && response.status === 200) {
        const data = response.data.data;
        setExistingData(data);
      }
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setIsLoading(false)
    })
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('catalog.catalog'), link: "/catalog-list" },
  ];
  if (activeStep === 0) {
    breadcrumbItems.push({ label: t('general.general'), active: true });
  } else if (activeStep === 1) {
    breadcrumbItems.push({ label: t('catalog.productImages'), active: true });
  }
  const steps = [
    { title: t('general.general'), component: CatalogStep1 },
    { title: t('catalog.productImages'), component: CatalogStep2 },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{id? t('catalog.editCatalog'):t('catalog.addCatalog')}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <UnlimitStepper
          steps={steps}
          setActiveStep={setActiveStep}
          formData={formData}
          setFormData={setFormData}
          activeStep={activeStep}
          id={id}
          existingData={existingData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default CatalogForm;
