import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/purchases/purchase.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
const PurchaseList = () => {
  const { hasPermission,roleType } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getPurchaseList = async (pagination = paginationParams.pagination) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
    };
    setLoading(true);
    await httpService
      .purchaseList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getPurchaseList(pagination);
  };

  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    // if(isFilterVisible && (emailFilter || cvrNoFilter)){
      //   setCvrNoFilter("")
      //   setEmailFilter("")
    //   const filters = {
    //     email: emailFilter,
    //     cvr_no: cvrNoFilter,
    //   };
    //   await getVendorsList(filters);
    // }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deletePurchase(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("purchase.purchaseDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getPurchaseList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewFile = () => {
    navigate(all_routes.purchaseUpload);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "order_no",
      render: (text, record) => {
        return record?.order_no ?? "";
      },
    },
    {
      title: t("tableColumnName.purchaseDate"),
      dataIndex: "purchase_date",
      render: (text) => {
        return moment(text).format("DD MMM YYYY");
      },
      sorter: true,
    },
    {
      title: t("supplier.supplier"),
      dataIndex: "supplier",
      render: (text, record) => (
          <Link
          to={`/supplier/${record.supplier_id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
          >
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.supplier?.image}
            alt="Contact"
            style={{ width: 30, height: 30, marginRight: 10 }}
            />
          {record?.supplier?.name}
        </div>
        </Link>
      ),
    },
    {
      title: "No. of Products",
      dataIndex: "total_items",
      align:"center",
      render: (text, record) => {
        return record?.total_items ?? "";
      },
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <Link
    //       to={`/vendor/${record.vendor_id}/view`}
    //       className=" d-flex align-items-center edit-icon p-2"
    //     >
    //       <div className="d-flex align-items-center">
    //         <ImageWithBasePath
    //           type="link"
    //           src={record.vendor?.logo}
    //           alt="Contact"
    //           style={{ width: 30, height: 30, marginRight: 10 }}
    //         />
    //         {record?.vendor?.business_name}
    //       </div>
    //     </Link>
    //   ),
    // },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },

    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["purchase-view", "purchase-delete"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["purchase-view"]) && (
              <Link
                to={`/purchase/${record.id}/details`}
                className="me-2 edit-icon p-2"
              >
                <i className="feather-eye"></i>
              </Link>
            )}
            {hasPermission(["purchase-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("purchase.purchases"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("purchase.purchaseList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["purchase-add"]) && (
              <div className="page-btn">
                <button onClick={addNewFile} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.actions.importFromFile")}
                </button>
              </div>
            )}
            <div className="page-btn">
              {/* <button onClick={addNewPurchase} className="btn btn-added">
                <Plus className="me-2 iconsize" />
                {t('general.button.addNew')}
              </button> */}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
            <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterSupplierName"
                        )}
                        className="form-control form-control-sm formsearch-custom ps-2"
                      />
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <Link
                          className="btn btn-filters ms-auto px-2"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <i
                            data-feather="search"
                            className="feather-search me-0 fs-6"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default PurchaseList;
