import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createSupplier = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.supplier.create}`,
    true,
    data,
    true,
  );
};
const createSupplierAttachments = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.supplier.createAttachments}`,
    true,
    data,
    true,
  );
};
const updateSupplier = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.supplier.update}`,
    true,
    data,
    json? false : true,
  );
};
const supplierList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.peoples.supplier.list}`,
    true,
    true,
    data,
  );
};
const deleteSupplier = async (id) => {
  const url = `${ENDPOINT.peoples.supplier.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getSupplier = async (id) => {
  const url = `${ENDPOINT.peoples.supplier.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getSupplier,
  createSupplier,
  updateSupplier,
  supplierList,
  deleteSupplier,
  createSupplierAttachments
};
