
export const truncateMiddle = (str, maxLength = 20, separator = '...') => {
    if (str.length <= maxLength) return str;
    
    const sepLen = separator.length,
          charsToShow = maxLength - sepLen,
          frontChars = Math.ceil(charsToShow / 2),
          backChars = Math.floor(charsToShow / 2);
          
    return str.substring(0, frontChars) + 
           separator + 
           str.substring(str.length - backChars);
  };