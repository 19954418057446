import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/price-line.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
import ProductSalePriceDialog from "../product/product-sale-price-dialog";
import { useSelector } from "react-redux";
import Select from "react-select";
const PriceLineList = () => {
  const { hasPermission, roleType } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [priceLine, setPriceLine] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [eanFilter, setEanFilter] = useState("");
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [selectedPriceLine, setSelectedPriceLine] = useState("");
  const { t } = useTranslation();
  const vendors = useSelector((state) => state.vendors.vendors);
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getPriceLineList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .priceLineList(data)
      .then((response) => {
        if (response.status == 200) {
          setPriceLine(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      product_name: nameFilter,
      ean: eanFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getPriceLineList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  const { priceLineCreate } = all_routes;
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      setNameFilter("");
      setEanFilter("");
      const filters = {};
      await getPriceLineList(paginationParams.pagination, filters);
    }
  };

  const onHideModal = async () => {
    await getPriceLineList();
  };
  const onClickEditPrice = (priceLine) => {
    setSelectedPriceLine(priceLine);
    const editPrice = document.querySelector(".btn-edit-price");
    if (editPrice) {
      editPrice.click();
    }
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => (
        <Link
          to={
            record.catalog?.id ? `/catalog/${record.catalog?.id}/details` : "#"
          }
          className=" d-flex align-items-center edit-icon"
        >
          {record?.catalog?.ean}
        </Link>
      ),
      // sorter: true,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <Link
          to={
            record.catalog?.product?.id
              ? `/product/${record.catalog?.product?.id}/details`
              : "#"
          }
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.catalog?.image}
              alt="Product"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record.catalog?.name}
          </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.unitSalePrice"),
      dataIndex: "sale_price",
      align: "right",
      render: (text, record) => {
        return (
          <>
            {Number(text)?.toFixed(2)}
            <i
              onClick={() => onClickEditPrice(record)}
              className="feather-edit-2 text-light ms-3 cursor-pointer bg-gradient"
              title="Update Sale Price"
            ></i>
          </>
        );
      },
    },
    {
      title: t("tableColumnName.lastCostPrice"),
      dataIndex: "unit_cost",
      align: "right",
      render: (text, record) =>
        Number(record?.purchase_item?.unit_cost)?.toFixed(2),
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["price-line-view"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["price-line-view"]) && (
              <button
                className="view-history"
                onClick={() =>
                  navigate(`/price-line/${record.catalog?.id}/details`)
                }
              >
                View History
              </button>
            )}
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("priceLine.priceLineList"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <Link
        style={{ visibility: "hidden" }}
        to="#"
        data-bs-toggle="modal"
        data-bs-target="#edit-product-price"
        className="btn-edit-price"
      ></Link>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("priceLine.priceLine")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["price-line-add"]) && (
              <div className="page-btn">
                <button
                  onClick={() => navigate(all_routes.PriceLineFromPurchase)}
                  className="btn btn-added"
                >
                  <Plus className="me-2 iconsize" />
                  {t("general.button.setPrices")}
                </button>
              </div>
            )}
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top justify-content-start align-items-end">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("inputField.label.EAN")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={eanFilter}
                        onChange={(e) => setEanFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder={t("tableColumnName.productName")}
                        className="form-control form-control-sm formsearch-custom ps-2"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleTableChange();
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={() => handleTableChange()}
                        >
                          <i
                            data-feather="search"
                            className="feather-search m-0"
                          />{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={priceLine}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductSalePriceDialog
        onHide={onHideModal}
        salePrice={selectedPriceLine?.sale_price ?? 0}
        id={selectedPriceLine?.id}
      />
    </div>
  );
};

export default PriceLineList;
