import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export default function Error404(){
    const navigate = useNavigate();
    return(
        <>
        <div className="page-wrapper">
        <Result
        status="404"
        title="404"
        className="mt-md-5 mt-3"
        subTitle={
            <>
           <p className="fw-bold fs-5 mb-2">Oops! The page youre looking for doesnt exist.</p>
           <p className="mb-2 fw-bold">It might have been moved or deleted.</p>
           <p className="mb-2">Go back to the <a href="/">homepage</a> or try searching for what you need.</p>
            </>
        }
        extra={<Button type="primary"  onClick={()=>navigate("/")}>Home Page</Button>}
        
        />
        </div>
        </>
    )
}