import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createProductGroup = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.productGroup.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateProductGroup = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.products.productGroup.update}`,
    true,
    data,
    json?false:true,
  );
};
const productGroupList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.productGroup.list}`,
    true,
    true,
    data,
  );
};
const deleteProductGroup = async (id) => {
  const url = `${ENDPOINT.products.productGroup.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getProductGroup = async (id) => {
  const url = `${ENDPOINT.products.productGroup.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getProductGroup,
  createProductGroup,
  updateProductGroup,
  productGroupList,
  deleteProductGroup,
};
