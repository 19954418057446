import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import purchaseHttpService from "../../../services/http/purchases/purchase.service";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/inventory/distribution.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import outletService from "../../../services/http/stores/outlet.service";
import useRoleAndPerm from "../../../utils/permissionFunction";

const DistributionList = () => {
  const navigate = useNavigate();
  const { hasPermission, roleType } = useRoleAndPerm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [distributions, setDistributions] = useState([]);
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
  const [outlet, setOutlet] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const [eanFilter, seteanFilter] = useState("");
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getDistributionList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .distributionList(data)
      .then((response) => {
        if (response.status == 200) {
          setDistributions(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      ean: eanFilter,
      purchase_id: selectedPurchaseId?.value,
      outlet_id: selectedOutletId?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getDistributionList(pagination, nonNullFilters, sorter);
  };

  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    fetchPurchase();
    fetchOutlet();
    setVendor(vendors?.data?.data);
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
  }, []);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    if (isFilterVisible) {
      seteanFilter("");
      setSelectedPurchaseId(null);
      setSelectedOutletId(null);
      const filters = {};
      await getDistributionList(paginationParams.pagination, filters);
    }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteDistribution(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("distribution.distributionDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getDistributionList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewDistribution = () => {
    navigate(all_routes.DistributionFromMainStock);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      sorter: true,
      render: (text, record) => (
        <Link
          to={`/catalog/${record.catalog?.id}/details`}
          className=" d-flex align-items-center edit-icon"
        >
          {record.ean}
        </Link>
      ),
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <Link
          to={`/product/${record.catalog?.product?.id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              src={record?.catalog?.main_product_image}
              type="link"
              alt={t("tableColumnName.productName")}
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record?.catalog?.name}
          </div>
        </Link>
      ),
      sorter: true,
    },
    {
      title: t("tableColumnName.initialQuantity"),
      dataIndex: "initial_quantity",
      align: "center",
    },
    {
      title: t("tableColumnName.availableQuantity"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: t("tableColumnName.outlets"),
      dataIndex: "outlet.name",
      hidden: roleType === "outlet",
      render: (text, record) => (
        <Link
          to={`/outlets/${record.outlet?.id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              src={record.outlet?.image_url}
              type="link"
              alt="Variant"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            <span>{record.outlet?.name}</span>
          </div>
        </Link>
      ),
    },
    // {
    //   title: t("tableColumnName.vendor"),
    //   dataIndex: "vendor.logo",
    //   hidden: roleType === "vendor" || roleType === "outlet",
    //   render: (text, record) => (
    //     <Link
    //       to={`/vendor/${record.vendor.id}/view`}
    //       className=" d-flex align-items-center edit-icon p-2"
    //     >
    //       <div className="d-flex align-items-center">
    //         <ImageWithBasePath
    //           src={record.vendor?.logo_url}
    //           type="link"
    //           alt="Variant"
    //           style={{ width: 30, height: 30, marginRight: 10 }}
    //         />
    //         <span>{record.vendor?.business_name}</span>
    //       </div>
    //     </Link>
    //   ),
    // },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "purchase",
      align: "center",
      render: (text, record) => {
        return record.purchase?.order_no ?? "";
      },
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["distribution-delete"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["distribution-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("distribution.distributionList"), active: true },
  ];
  async function fetchPurchase() {
    try {
      const purchase = await purchaseHttpService.purchaseList();
      setPurchase(purchase?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchOutlet() {
    try {
      const outlet = await outletService.outletList();
      setOutlet(outlet?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  const purchaseOptions = purchase?.map((purchase) => ({
    value: purchase.id,
    label: purchase.order_no,
  }));
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  const outletOptions = outlet?.map((vendor) => ({
    value: vendor.id,
    label: vendor.name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("distribution.stockdistributions")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["distribution-add"]) && (
              <div className="page-btn">
                <button onClick={addNewDistribution} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="row" style={{ padding: "24px 24px 0" }}>
                <div className="mb-0 col-lg-4 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("inputField.placeholder.chooseVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <>
                          <img
                            src={logo_url}
                            alt=""
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 10,
                            }}
                          />
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder={t("inputField.placeholder.enterEAN")}
                          className="form-control form-control-sm formsearch-custom ps-2"
                          value={eanFilter}
                          onChange={(e) => seteanFilter(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleTableChange();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 ">
                      <div className="unlimit-uikit-select">
                        <Select
                          options={purchaseOptions}
                          className="select custom-select"
                          placeholder={t(
                            "inputField.placeholder.selectpurchaseRefernce"
                          )}
                          value={selectedPurchaseId}
                          onChange={(selectedOption) =>
                            setSelectedPurchaseId(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 ">
                      <div className="unlimit-uikit-select">
                        <Select
                          options={outletOptions}
                          className="select custom-select"
                          placeholder={t("inputField.placeholder.chooseOutlet")}
                          value={selectedOutletId}
                          onChange={(selectedOption) =>
                            setSelectedOutletId(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <button
                          className="btn btn-filters col-auto ms-auto px-2"
                          style={{ width: "fit-content" }}
                          onClick={handleTableChange}
                        >
                          <i
                            data-feather="search"
                            className="feather-search me-0 fs-6"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={distributions}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default DistributionList;
