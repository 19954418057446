import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createLoyaltySettings = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.loyalty.loyaltySettings.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateLoyaltySettings = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.loyalty.loyaltySettings.update}`,
    true,
    data,
    json?false:true,
  );
};
const loyaltySettingsList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.loyalty.loyaltySettings.list}`,
    true,
    true,
    data,
  );
};
const deleteLoyaltySettings = async (id) => {
  const url = `${ENDPOINT.loyalty.loyaltySettings.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getLoyaltySettings = async (id) => {
  const url = `${ENDPOINT.loyalty.loyaltySettings.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getLoyaltySettings,
  createLoyaltySettings,
  updateLoyaltySettings,
  loyaltySettingsList,
  deleteLoyaltySettings,
};
