import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/inventory/main-stock.service";
import productHttpService from "../../../services/http/products/product.service";
import variantHttpService from "../../../services/http/products/variant.service";
import supplierHttpService from "../../../services/http/peoples/supplier.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";

const MainStockForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  const [vendor, setVendor] = useState([]);
  const [variant, setVarient] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [products, setProducts] = useState([]);

  const vendors = useSelector((state) => state.vendors.vendors);

  async function fetchProduct() {
    const product = await productHttpService.productList();
    setProducts(product?.data?.data?.data);
  }
  async function fetchVarient(id) {
    const variant = await variantHttpService.variantsList();
    const newV = variant?.data?.data?.data?.filter((v) => v.product?.id === id);
    setVarient(newV);
  }
  async function fetchSupplier() {
    const supplier = await supplierHttpService.supplierList();
    const activeSuppliers = supplier?.data?.data?.data?.filter(supplier => supplier.is_active == '1');
    setSupplier(activeSuppliers);
  }
  const onChangeProduct = (value) => {
    if (value) {
      fetchVarient(value.id);
    }
  };
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getMainStock();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
    if (id) {
      getMainStock();
    }
    fetchProduct();
    fetchSupplier();
  }, [id]);

  const getMainStock = async () => {
    try {
      setPageLoading(true);
      await httpService.getMainStock(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
        }
      });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("purchase_id", data.purchase_id);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo,
    });
    setValue("product_id", {
      ...data.product,
      label: data.product.name,
      value: data.product.id,
    });
    setValue("variant_id", {
      ...data.variant,
      label: data.variant.name,
      value: data.name.id,
    });
    setValue("supplier_id", {
      ...data.variant,
      label: data.variant.name,
      value: data.name.id,
    });
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    console.log(data);
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("vendor_id", data.vendor_id.id);
      formData.append("product_id", data.product_id.id);
      formData.append("variant_id", data.variant_id.id);
      formData.append("supplier_id", data.supplier_id.id);
      formData.append("purchase_id", data.purchase_id);
      if (id) {
        formData.append("id", id);
        await httpService
          .updateMainStock(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                "MainStock updated successfully!"
              );
              setTimeout(() => {
                navigate(all_routes.mainStockList);
              }, 1000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        await createMainStock(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new mainStock
  const createMainStock = async (formData) => {
    try {
      await httpService
        .createMainStock(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              "MainStock created successfully!"
            );
            navigate(all_routes.mainStockList);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Main Stock", link: "/main-stock-list" },
    { label: id ? "Edit Main Stock" : "Add Main Stock", active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">MainStock</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* MainStock Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Vendor"
                        id="vendor_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Choose Vendor"
                        options={vendor}
                        showLogo={true}
                        optionLabel="business_name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Product"
                        id="product_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Select Product"
                        options={products}
                        optionLabel="name"
                        optionValue="id"
                        onChange={onChangeProduct}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Select Varient"
                        id="variant_id"
                        errors={errors}
                        control={control}
                        register={register}
                        options={variant}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Select Varient"
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Select Supplier"
                        id="supplier_id"
                        errors={errors}
                        control={control}
                        register={register}
                        options={supplier}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Select Supplier"
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label="Purchase Reference"
                        id="purchase_id"
                        type="text"
                        placeholder="Enter Purchase Reference"
                        register={register}
                        rules={{
                          required: "This Field is required.",
                        }}
                        error={errors.purchase_id}
                        errorMessage={errors.purchase_id?.message}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.mainStockList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        Cancel
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={id ? "Update" : "Submit"}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* mainStock Form End */}
      </div>
    </div>
  );
};

export default MainStockForm;
