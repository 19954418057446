import API from "./base.service";
import { ENDPOINT } from "../constants.service";

const login = async (email, password) => {
  return await API.postMethod(
    ENDPOINT.login,
    false,
    {
      email,
      password,
    },
    false,
    false,
  );
};
const signup = async (data) => {
  return await API.postMethod(ENDPOINT.users.signUp, false, data);
};
const forgetPassword = async (email) => {
  return await API.postMethod(ENDPOINT.users.forgetPassword, false, {
    email,
  });
};
const sendOtp = async (email) => {
  return await API.postMethod(ENDPOINT.users.sendOtp, false, {
    email,
  });
};
const setNewPassword = async (data,token) => {
  return await API.postMethodCustomHeader(ENDPOINT.users.setNewPassword, token,data);
};
const verifyOtp = async (email,otp) => {
  return await API.postMethod(ENDPOINT.users.verifyOtp, false, {
    email,
    otp
  });
};

const verificationOtp = async (email, otp) => {
  return await API.postMethod(ENDPOINT.users.verificationOtp, false, {
    email,
    otp,
  });
};
const resetPassword = async (email, password, password_confirmation) => {
  return await API.postMethod(ENDPOINT.users.resetPassword, false, {
    email,
    password,
    password_confirmation,
  });
};
const updatePassword = async (data) => {
  return await API.putMethod(ENDPOINT.users.updatePassword, true, data, false);
};
const getCurrentUserProfile = async () => {
  return await API.getMethod(ENDPOINT.users.currentUserProfile, true);
};

const updateUser = async (data) => {
  return await API.putMethod(ENDPOINT.users.updateUser, true, data, true);
};

const logout = async () => {
  return await API.getMethod(ENDPOINT.logout, true);
};
export default {
  login,
  signup,
  forgetPassword,
  verificationOtp,
  resetPassword,
  getCurrentUserProfile,
  updateUser,
  logout,
  sendOtp,
  verifyOtp,
  setNewPassword,
  updatePassword
};
