import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import outletService from "../../../services/http/stores/outlet.service";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import httpService from "../../../services/http/inventory/outlet-stock.service";
import usePagination from "../../../custom-hooks/usePagination";
import { Link, useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import withReactContent from "sweetalert2-react-content";
import notificationService from "../../../services/notification.service";
import Select from "react-select";
import moment from "moment";
import { useSelector } from "react-redux";
const OutletStockList = () => {
  const { hasPermission, roleType } = useRoleAndPerm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [purchaseIsLoading, setPurchaseIsLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [outletStocks, setOutletStocks] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getOutletStockList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      // ...filters,
      group_by: "ean",
      ...sorter,
    };
    setLoading(true);
    await httpService
      .outletStockList(data, selectedOutletId?.value)
      .then((response) => {
        if (response.status == 200) {
          setOutletStocks(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      outlet_id: selectedOutletId?.value,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getOutletStockList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedOutletId?.value) {
      handleTableChange();
    }
  }, [selectedOutletId]);
  useEffect(() => {
    if (selectedVendor?.value) {
      setPurchaseIsLoading(true);
      fetchOutlet();
    }
  }, [selectedVendor]);
  async function fetchOutlet() {
    try {
      const outlet = await outletService.outletList({
        vendor_id: selectedVendor.value,
      });
      setOutlet(outlet?.data?.data?.data);
      if (outlet?.data?.data?.data && outlet?.data?.data?.data.length > 0) {
        setSelectedOutletId({
          value: outlet?.data?.data?.data[0].id,
          label: outlet?.data?.data?.data[0].name,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPurchaseIsLoading(false);
    }
  }
  useEffect(() => {
    setVendor(vendors?.data?.data);
    //Temp Code replace it with commented code afterwords
    let selectedVendor = vendors?.data?.data[0];

    if (vendors?.data?.data?.length) {
      const vendor = vendors.data.data.find((vendor) =>
        vendor.business_name.toLowerCase().includes("near")
      );
      if (vendor) {
        selectedVendor = vendor;
      }
    }

    setSelectedVendor({
      ...selectedVendor,
      value: selectedVendor?.id,
      label: selectedVendor?.business_name,
    });
    /////////////////////////////
    // setSelectedVendor({
    //   value: vendors?.data?.data[0]?.id,
    //   label: vendors?.data?.data[0]?.business_name,
    // });
  }, []);

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => (
        <Link
          to={`/catalog/${record.catalog?.id}/details`}
          className=" d-flex align-items-center edit-icon"
        >
          {record.ean}
        </Link>
      ),
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "image",
      render: (text, record) => (
        <Link
          to={`/product/${record.catalog?.product?.id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.catalog?.main_product_image}
              alt="Outlet"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record.catalog?.name}
          </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.vendor"),
      dataIndex: "vendor.logo",
      hidden: roleType === "vendor" || roleType === "outlet",
      render: (text, record) => (
        <Link
          to={`/vendor/${record.vendor?.id}/view`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.vendor?.logo_url}
              alt="Contact"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record.vendor?.business_name}
          </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.availableQuantity"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: t("tableColumnName.salePrice"),
      align: "center",
      render: (text, record) => (
        <div>{Number(record?.price_line?.sale_price ?? 0)?.toFixed(2)}</div>
      ),
    },
    {
      title: t("tableColumnName.costPrice"),
      align: "center",
      render: (text, record) => (
        <div>
          {Number(record?.catalog?.product?.cost_price ?? 0)?.toFixed(2)}
        </div>
      ),
    },
    /* {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    }, */
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["product-view"]),
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              onClick={() =>
                navigate(`/product/${record.catalog?.product?.id}/details`)
              }
            >
              <i className="feather-eye"></i>
            </a>
          </div>
        </td>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("outletStock.outletStockList"), active: true },
  ];

  const MySwal = withReactContent(Swal);
  const showConfirmationAlert = ({ title, text }) => {
    MySwal.fire({
      title: title || t("outlet.areYouSureToUpdateStockPrice"),
      text: text || t("general.prompts.confirmRevert"),
      showCancelButton: true,
      cancelButtonText: t("general.button.cancel"),
      confirmButtonText: t("general.button.confirm"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        onConfirm();
      } else {
        MySwal.close();
      }
    });
  };

  const onConfirm = async () => {
    try {
      const response = await httpService.updateStockPrice(
        selectedOutletId?.value
      );
      if (response && response.status === 200) {
        notificationService.successMessage(response.data?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const outletOptions = outlet?.map((outlet) => ({
    value: outlet.id,
    label: outlet.name,
  }));
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("outletStock.outletStockList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {
              <div className="page-btn">
                <div className="btn btn-added" onClick={showConfirmationAlert}>
                  <Plus className="me-2 iconsize" />
                  {t("product.updateDigitalTagPrice")}
                </div>
              </div>
            }
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {/* /Filter */}
              <div
                className={`card visible mt-md-4 mb-md-3`}
                id="filter_inputs"
                style={{ display: "block" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-4 col-sm-4 col-12 ">
                      <div className="unlimit-uikit-select">
                        <label className="mb-2">{t("vendor.vendor")}</label>
                        <Select
                          options={vendorOptions}
                          className="select custom-select"
                          placeholder={t("inputField.placeholder.chooseVendor")}
                          value={selectedVendor}
                          formatOptionLabel={({label,logo_url}) => (
                            <><img
                                src={logo_url}
                                alt=""
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginRight: 10,
                                }}
                              />
                              <span style={{ marginLeft: 10 }}>{label}</span>
                            </>
                          )}
                          onChange={(selectedOption) =>
                            setSelectedVendor(selectedOption)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-12 ">
                      <div className="unlimit-uikit-select">
                        <label className="mb-2">{t("outlet.outlet")}</label>
                        <Select
                          options={outletOptions}
                          className="select custom-select"
                          placeholder={t("inputField.placeholder.chooseOutlet")}
                          value={selectedOutletId}
                          onChange={(selectedOption) =>
                            setSelectedOutletId(selectedOption)
                          }
                          isLoading={purchaseIsLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={outletStocks}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default OutletStockList;
