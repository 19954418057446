import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/catalog.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetailsRow from "../../../components/ui-kit/unlimit-field-details-row";
import usePagination from "../../../custom-hooks/usePagination";
import notificationService from "../../../services/notification.service";
import UnlimitImageGallery from "../../../components/ui-kit/unlimit-image-gallery";
import ThreedFileWindow from "../../sample/3d-window";
import { useForm } from "react-hook-form";
import { all_routes } from "../../../Router/all_routes";
import ThreedFileWindowIphone from "../../sample/sample-iphone/3d-window";
import { useTranslation } from "react-i18next";

export default function CatalogDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const [catalog, setCatalog] = useState(null);
  const navigate = useNavigate();
  const getCatalog = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getCatalog(id);
      if (response && response.status === 200) {
        setCatalog(response.data.data);
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCatalog();
    }
    setValue("is_active" , true)
    setValue("default" , true)
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Catalog", link: "/catalog-list" },
    { label: "Product Catalog Details", active: true },
  ];

  const data = [
    { field: t("inputField.label.EAN"), data: catalog?.ean },
    { field: t("brand.brand"), data: catalog?.brand?.name },
    { field: t("inputField.label.productCategory"), data: catalog?.category?.name },
    { field: t("inputField.label.receiptLabelText"), data: catalog?.receipt_label_text },
    { field: t("inputField.label.shortDescription"), data: catalog?.short_description },
    { field: t("inputField.label.productInformation"), data: catalog?.product_information },
    { field: t("inputField.label.uomCode1"), data: catalog?.uom_code_1?.name },
    { field: t("inputField.label.uomCode1Value"), data: catalog?.uom_code_1_value },
    { field: t("inputField.label.uomCode2"), data: catalog?.uom_code_2?.name },
    { field: t("inputField.label.uomCode2Value"), data: catalog?.uom_code_2_value },
    {
      field: t("inputField.label.VATableProduct"),
      data: catalog?.is_vat_product ? "Yes" : "No",
    },
    { field: t("inputField.label.vat" )+ " %", data: catalog?.vat },
    {
      field: t("inputField.label.securityableProduct"),
      data: catalog?.is_security_product ? "Yes" : "No",
    },
    { field: t("inputField.label.pledgeType"), data: catalog?.pledge_type?.material },
    { field: t("inputField.label.pledgePrice"), data: catalog?.pledge_type?.amount },
    {
      field: t("inputField.label.extraSecurityCheck"),
      data: catalog?.extra_security_check ? "Yes" : "No",
    },
    {
      field: t("inputField.label.compoundProduct"),
      data: catalog?.is_compound_product ? "Yes" : "No",
    },
    { field: t("inputField.label.DigitalTextLine") + " 1", data: catalog?.text_line_1 },
    { field: t("inputField.label.DigitalTextLine") + " 2", data: catalog?.text_line_2 },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">Product Catalog Details</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            catalog && (
              <>
                <div className="row">
                  <div className="col-md-9">
                    <div className="card container-fluid">
                      <div className="d-flex my-3 mx-2">
                        <h3 className="me-3">{catalog?.name}</h3>
                        <span
                          className={`badge ${
                            !isActive ? "badge-linesuccess" : "badge-danger"
                          }`}
                        >
                          <a>{!isActive ? "Active" : "Deactive"}</a>
                        </span>
                      </div>
                      {data.map(({ field, data }) => (
                        <UnlimitFieldDetailsRow
                          key={field}
                          heading={field}
                          details={data}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <UnlimitImageGallery
                        urls={{file:catalog?.main_product_image,is_active:1}}
                        isDefaultValue={catalog?.main_product_image}
                        register={register}
                        disabled={true}
                        totalLength={catalog?.product_images?.length}
                        navigateTo={`/catalog/${id}/image-details`}
                      />
                    </div>
                    <div className="card">
                      <ThreedFileWindowIphone height={"400px"} id={id}/>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
}
