import { useState } from "react";

const usePagination = (initialParams) => {
  const [paginationParams, setPaginationParams] = useState(initialParams);

  const updatePaginationParams = (newParams) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      ...newParams,
    }));
  };

  return {
    paginationParams,
    updatePaginationParams,
  };
};

export default usePagination;
