// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-container {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
}

.attachment-container .pdf-icon {
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.attachment-container .pdf-icon img {
      margin-bottom: 20px;
    width: 40px;
    height: auto;
  }
  
  .attachment-container .attachment-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    background: #f5f5f5;
    color: white;
    font-size: 12px;
    text-align: center;
  }
  
  .attachment-container .corner-ribbon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 20px solid red;
    border-right: 20px solid white;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/support/contact/TicketDetails.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,WAAW;AACf;;AAEA;MACM,mBAAmB;IACrB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,QAAQ;IACR,SAAS;IACT,0BAA0B;IAC1B,8BAA8B;EAChC","sourcesContent":[".attachment-container {\n    display: inline-block;\n    position: relative;\n    width: 150px;\n    height: 100px;\n    border-radius: 4px;\n    overflow: hidden;\n}\n\n.attachment-container .pdf-icon {\n    border: 1px solid #ddd;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 100%;\n}\n\n.attachment-container .pdf-icon img {\n      margin-bottom: 20px;\n    width: 40px;\n    height: auto;\n  }\n  \n  .attachment-container .attachment-name {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    padding: 5px;\n    background: #f5f5f5;\n    color: white;\n    font-size: 12px;\n    text-align: center;\n  }\n  \n  .attachment-container .corner-ribbon {\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    width: 0;\n    height: 0;\n    border-top: 20px solid red;\n    border-right: 20px solid white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
