// UnlimitFileUpload.js
import React from "react";
import UnlimitInput from "./unlimit-input";

const UnlimitFileUpload = ({ label, id, register, error,rules, errorMessage,onChange,accept }) => {
    const handleFileChange = (event) => {
        onChange(event); // Pass the event object to the parent handler
      };
  return (
    <div>
      <UnlimitInput
        label={label}
        style={{"padding":"8.5px"}}
        id={id}
        type="file"
        register={register}
        error={error}
        rules={rules} 
        errorMessage={errorMessage}
        onChange={handleFileChange}
        accept={accept}
      />
    </div>
  );
};

export default UnlimitFileUpload;
