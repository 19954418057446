import React from "react";
import { useTranslation } from "react-i18next";

const UnlimitSubmitButton = ({ isLoading, onClick, disabled, label }) => {
  const { t } = useTranslation();
  return (
    <button
      className="btn btn-submit me-2"
      disabled={isLoading || disabled}
      onClick={onClick}
    >
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      <span className="ms-1">
        {isLoading ? t("general.button.saving") : label || t("general.button.save")}
      </span>
    </button>
  );
};

export default UnlimitSubmitButton;
