import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createVariant = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.variants.create}`,
    true,
    data,
    true,
  );
};
const updateVariant = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.variants.update}`,
    true,
    data,
    true,
  );
};
const variantsList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.variants.list}`,
    true,
    true,
    data,
  );
};
const deleteVariant = async (id) => {
  const url = `${ENDPOINT.products.variants.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getVariant = async (id) => {
  const url = `${ENDPOINT.products.variants.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getVariant,
  createVariant,
  updateVariant,
  variantsList,
  deleteVariant,
};
