import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/peoples/employees.service";
import designationHttpService from "../../../services/http/peoples/designation.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitFilePicker from "../../../components/ui-kit/unlimit-filepicker";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { useSelector } from "react-redux";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import UnlimitAttachments from "../../../components/ui-kit/unlimit-attachments";
import { useTranslation } from "react-i18next";

const EmployeesForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState("");
  const [designationLoading, setDesignationLoading] = useState(false);
  const [outletLoading, setOutletLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [vendor, setVendor] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [imageUrlAttachments, setImageUrlAttachments] = useState("");
  const watchFile = watch("attachment");

  useEffect(() => {
    if (watchFile?.length > 0) {
      setImageUrlAttachments(URL.createObjectURL(watchFile[0]));
    }
  }, [watchFile]);

  const vendors = useSelector((state) => state.vendors.vendors);

  async function fetchOutlet() {
    try {
      const outlet = await outletHttpService.outletList();
      setOutlets(outlet?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    } finally {
      setOutletLoading(false);
    }
  }
  async function fetchDesignation() {
    try {
      const designation = await designationHttpService.designationsList();
      setDesignations(designation?.data?.data?.data);
    } catch (err) {
      console.error("Error fetching designation data:", err);
    } finally {
      setDesignationLoading(false);
    }
  }
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getEmployees();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
    setDesignationLoading(true);
    setOutletLoading(true);
    fetchOutlet();
    fetchDesignation();
  }, [id]);

  // Fetch employees data by ID
  const getEmployees = async () => {
    try {
      setPageLoading(true);
      await httpService.getEmployees(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
        }
      });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("address", data.address);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("is_active", data.is_active == 1 ? true : false);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo,
    });
    setValue("outlet_id", {
      ...data.outlet,
      label: data.outlet.name,
      value: data.outlet.id,
    });
    setValue("designation_id", {
      ...data.designation_id,
      label: data.designation_id.name,
      value: data.designation_id.id,
    });
    setValue("description", data.description);
    setExistingImageUrl(data.image);
  };
  const { auth } = store.getState();
  // Handle form submission
  const onSubmitForm = async (data) => {
    const user = auth.user;

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("created_by", user.id);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("vendor_id", data.vendor_id.id);
      formData.append("is_active", data.is_active ? 1 : 0);
      formData.append("outlet_id", data.outlet_id.id);
      formData.append("designation_id", data.designation_id.id);
      if (data.image[0]) {
        formData.append("image", data.image[0]);
      }
      if (id) {
        formData.append("id", id);
        await httpService
          .updateEmployees(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t("employee.employeeUpdateSuccess")
              );
              setTimeout(() => {
                navigate(all_routes.employeesList);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createEmployees(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new employees
  const createEmployees = async (formData) => {
    try {
      await httpService
        .createEmployees(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              t("employee.employeeCreateSuccess")
            );
            navigate(all_routes.employeesList);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("employee.employees"), link: "/employees-list" },
    {
      label: id ? t("employee.editEmployee") : t("employee.addEmployee"),
      active: true,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("employee.employees")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Employees Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("vendor.vendor")}
                        id="vendor_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.chooseVendor")}
                        options={vendor}
                        showLogo={true}
                        optionLabel="business_name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("outlet.outlet")}
                        id="outlet_id"
                        errors={errors}
                        control={control}
                        isLoading={outletLoading}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.chooseOutlet")}
                        options={outlets}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("tableColumnName.designation")}
                        id="designation_id"
                        errors={errors}
                        control={control}
                        isLoading={designationLoading}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t(
                          "inputField.placeholder.chooseDesignation"
                        )}
                        options={designations}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.employeeName")}
                        id="name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterEmployeeName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Za-z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.contactNamePattern"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-start justify-content-between">
                      <UnlimitFileUpload
                        label={t("inputField.label.image")}
                        id="image"
                        register={register}
                        error={errors.image}
                        accept=".jpeg, .png, .jpg"
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        errorMessage={errors.image?.message}
                        onChange={handleImageChange}
                      />
                      {(id || imageUrl) && (
                        <img
                          src={
                            id
                              ? id && imageUrl
                                ? imageUrl
                                : existingImageUrl
                              : imageUrl
                          }
                          alt={t("inputField.label.image")}
                          className="img-fluid"
                          style={{ maxWidth: "70px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.phone")}
                        id="phone"
                        type="text"
                        placeholder={t("inputField.placeholder.enterPhone")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\+?\d+$/,
                            message: t("inputField.validation.phonePattern"),
                          },
                          maxLength: {
                            value: 20,
                            message: t("inputField.validation.maxLength", {
                              value: 20,
                            }),
                          },
                        }}
                        error={errors.phone}
                        errorMessage={errors.phone?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.email")}
                        id="email"
                        type="email"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("inputField.validation.emailPattern"),
                          },
                        }}
                        error={errors.email}
                        errorMessage={errors.email?.message}
                      />
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-md-4">
                      <UnlimitToggle
                        isChecked={id ? false : true}
                        label={t("inputField.label.status")}
                        id="is_active"
                        register={register}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <UnlimitTextarea
                          label={t("inputField.label.description")}
                          id="description"
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            maxLength: {
                              value: 500,
                              message: t("inputField.validation.maxLength", {
                                value: 500,
                              }),
                            },
                          }}
                          numOfRows={3}
                          error={errors.description}
                          errorMessage={errors.description?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.employeesList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Employees Form End */}
      </div>
    </div>
  );
};

export default EmployeesForm;
