import React from 'react';
import { GoogleMap, Marker, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '200px'
};

const id = process.env.REACT_APP_GOOGLE_MAPS_API_SECRET;
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_ID;

function MapWithLocation({ country, city, center, setCenter }) {
  const { isLoaded } = useJsApiLoader({
    id,
    googleMapsApiKey,
    libraries: ['places'] // Add the 'places' library
  });
  
  const [map, setMap] = React.useState(null);
  const [searchBox, setSearchBox] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    map.addListener("click", (event) => {
      const clickedLat = event.latLng.lat();
      const clickedLng = event.latLng.lng();
      setCenter({ lat: clickedLat, lng: clickedLng });
    });
  }, [setCenter]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onSearchBoxLoad = ref => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    if (!searchBox) return;
    const places = searchBox.getPlaces();
    if (places?.length === 0) return;

    const place = places[0];
    const location = place.geometry.location;
    setCenter({ lat: location.lat(), lng: location.lng() });
  };

  return isLoaded ? (
    <div style={{position:"relative"}}>
      <StandaloneSearchBox
        onLoad={onSearchBoxLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search for places"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            marginLeft: "-120px",
            top: "10px",
            zIndex:"99"
          }}
        />
      </StandaloneSearchBox>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {center && <Marker position={center} />}
      </GoogleMap>
    </div>
  ) : <></>;
}

export default React.memo(MapWithLocation);
