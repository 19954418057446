import React from "react";

const UnlimitFieldDetails = (props) => {
  const { heading, details } = props;
  return (
    <div className="d-flex flex-column align-items-start overflow-hidden">
      <p className="mb-0 fs-14 text-gray-heading">{heading}</p>
      <p className="fw-bolder fs-6 w-100">{details || "-"}</p>
    </div>
  );
};

export default UnlimitFieldDetails;
