import React from "react";
import { Link } from "react-router-dom";

const UnlimitBreadcrumb = ({ items }) => {
  return (
    <ul className="breadcrumb">
      {items.map((item, index) => (
        <li key={index} className={`breadcrumb-item ${index === items.length - 1 ? 'active' : ''}`}>
          {item.link ? (
            <Link to={item.link}>
              {item.icon && <i className={item.icon}></i>}
              {item.label}
            </Link>
          ) : (
            <>
              {item.icon && <i className={item.icon}></i>}
              {item.label}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default UnlimitBreadcrumb;
