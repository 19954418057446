import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/uom.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { Link } from "react-router-dom/dist";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";

const UomForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { register, handleSubmit, formState, setValue, setError } = useForm();
  const { errors } = formState;
  const { id } = useParams();

  const getUOM = async () => {
    await httpService
      .getUOM(id)
      .then((response) => {
        if (response && response.status == 200) {
          const data = response.data.data;
          setValue("name", data.name);
          setValue("code", data.code);
          setValue("is_active", data.is_active == 1 ? true : false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      setPageLoading(true);
      getUOM();
    }
  }, [id]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const requestData = {
        name:data.name,
        code:data.code,
        is_active:data.is_active ? 1:0
      };
      if (id) {
        requestData.id = id;
        await updateUOM(requestData);
      } else {
        await createUOM(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const updateUOM = async (formData) => {
    await httpService
      .updateUOM(formData,true)
      .then((response) => {
        if (response.status == 200) {
          notificationService.successMessage(t("UOM.UOMUpdateSuccess"));
          setTimeout(() => {
            navigate(all_routes.uomList);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.validation_error.errors) {
          hydrateErrors(error.validation_error.errors, setError);
        }
      });
  };
  const createUOM = async (formData) => {
    try {
      const response = await httpService.createUOM(formData,true);
      if (response && response.status == 200) {
        notificationService.successMessage(t("UOM.UOMCreateSuccess"));
        setTimeout(() => {
          navigate(all_routes.uomList);
        }, 500);
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("UOM.UOM"), link: "/uom-list" },
    { label: !id ? t("UOM.addUOM") : t("UOM.editUOM"), active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("UOM.UOM")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body add-product pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <UnlimitInput
                          label={t("UOM.UOMName")}
                          id="name"
                          type="text"
                          placeholder={t("inputField.placeholder.enterUOMName")}
                          register={register}
                          rules={{
                            required: {
                              value: true,
                              message: t("inputField.validation.required"),
                            },
                            pattern: {
                              value: /^[a-zA-Z-]*$/,
                              message: t(
                                "inputField.validation.alphabetsAndHyphen"
                              ),
                            },
                            maxLength: {
                              value: 50,
                              message: t("inputField.validation.maxLength", {
                                value: 50,
                              }),
                            },
                          }}
                          error={errors.name}
                          errorMessage={errors.name?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <UnlimitInput
                          label={t("tableColumnName.shortCode")}
                          id="code"
                          type="text"
                          placeholder={t("inputField.placeholder.unitName")}
                          register={register}
                          rules={{
                            required: {
                              value: true,
                              message: t("inputField.validation.required"),
                            },

                            maxLength: {
                              value: 50,
                              message: t("inputField.validation.maxLength", {
                                value: 50,
                              }),
                            },
                          }}
                          error={errors.code}
                          errorMessage={errors.code?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-blocks mb-3">
                        <UnlimitToggle
                          isChecked={id ? false : true}
                          label={t("inputField.label.status")}
                          id="is_active"
                          register={register}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <Link to={all_routes.uomList}>
                      <button type="button" className="btn btn-cancel me-2">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UomForm;
