import React, { useState } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const UnlimitMultiSelect = ({
  label,
  id,
  options,
  control,
  onChange,
  register,
  errors,
  rules,
}) => {
  const [allSelected, setAllSelected] = useState(false);

  const mappedOptions = options.map((op) => ({
    value: op.id,
    label: op.name,
  }));

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.length === mappedOptions.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }

    if (onChange) {
      onChange(selectedOptions);
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setAllSelected(false);
      handleSelectChange([]);
    } else {
      setAllSelected(true);
      handleSelectChange(mappedOptions);
    }
  };

  const customOptions = [
    {
      value: "select_all",
      label: allSelected ? "Unselect All" : "Select All",
    },
    ...mappedOptions,
  ];

  return (
    <div className="unlimit-uikit-select">
      {label && <label className="mb-2" htmlFor={id}>{label}</label>}
      <Controller
        name={id}
        control={control}
        register={register}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            isMulti
            options={customOptions}
            onChange={(selected) => {
              if (selected && selected.some((option) => option.value === "select_all")) {
                handleSelectAll();
              } else {
                handleSelectChange(selected);
                field.onChange(
                  selected.filter((option) => option.value !== "select_all")
                );
              }
            }}
            value={
              allSelected
                ? mappedOptions
                : field.value
            }
          />
        )}
      />
      {errors[id] && <span className="error">{errors[id].message}</span>}
    </div>
  );
};

export default UnlimitMultiSelect;
