import React from "react";
import { UploadCloud } from "feather-icons-react/build/IconComponents";
import { useTranslation } from "react-i18next";

const UnlimitProfilePicture = (props) => {
  const {
    label,
    id,
    register,
    rules,
    error,
    errorMessage,
    accept,
    fileName,
    onChange,
    eid,
    imageUrl,
    existingImageUrl,
    handleRemoveImage,
  } = props;
  /*const [selectedFileName, setSelectedFileName] = useState(null);
  const handleFileChange = (event) => {
    console.log("Handle change file##");
    const selectedFile = event.target.files[0];
    setSelectedFileName(selectedFile ? selectedFile.name : null);
  };*/
  const { t } = useTranslation();
  return (
    <div
      className="unlimit-uikit-attachments d-flex flex-column justify-content-center align-items-start "
      style={{ height: "100%" }}
    >
      <label className="form-label" htmlFor={id}>
        {label}
      </label>
      <div
        className="image-upload d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <input
          id={id}
          {...register(id, rules)}
          type="file"
          accept={accept}
          onChange={onChange}
        />
        <div
          className="image-uploads d-flex flex-column justify-content-center align-items-center "
          style={{ height: "100%" }}
        >
          {eid || imageUrl ? (
            <>
                <img
                  src={
                    eid
                      ? eid && imageUrl
                        ? imageUrl
                        : existingImageUrl
                      : imageUrl
                  }
                  alt="Image"
                  className="ProfilePictureImg"
                />
              <span
                className="badge align-self-center badge-danger mt-2"
                onClick={handleRemoveImage}
                style={{zIndex:300}}
              >
                <a>Remove Image X</a>
              </span>
            </>
          ) : (
            <>
              <UploadCloud className="text-primary" />
              <h4>{t("general.dragAndDrop")}</h4>
              <h5 className="mt-2">{fileName ?? ""}</h5>
            </>
          )}
        </div>
      </div>
      <div>
        {error && (
          <span className="error" style={{ color: "red" }}>
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

export default UnlimitProfilePicture;
