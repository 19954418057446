import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useForm } from "react-hook-form";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { all_routes } from "../../../Router/all_routes";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { useState } from "react";
import { Edit2 } from "feather-icons-react/build/IconComponents";

export default function MyProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  function onSubmitForm(data) {
  }
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("address", data.address);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("description", data.description);
    setExistingImageUrl(data.image);
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Profile", active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /product list */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body">
              <div className="profile-set">
                <div className="profile-head"></div>
                <div className="profile-top">
                  <div className="profile-content">
                    <div className="profile-contentimg">
                      <ImageWithBasePath
                        src="assets/img/customer/customer5.jpg"
                        alt="img"
                        id="user profile image"
                      />
                      <div className="profileupload">
                        <input type="file" id="imgInp" />
                        <Link to="#">
                          <Edit2 className="text-white iconsize" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-contentname">
                      <h2>Sarmad Siddiqi</h2>
                      <h4>Update your profile photo & personal details</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <UnlimitInput
                    label="First Name"
                    id="firstname"
                    type="firstname"
                    placeholder="Enter First Name"
                    register={register}
                    rules={{
                      required: "This Field is required.",
                    }}
                    error={errors.firstname}
                    errorMessage={errors.firstname?.message}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <UnlimitInput
                    label="Last Name"
                    id="lastname"
                    type="text"
                    placeholder="Enter Last Name"
                    register={register}
                    rules={{
                      required: "This Field is required.",
                    }}
                    error={errors.lastname}
                    errorMessage={errors.lastname?.message}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <UnlimitInput
                    label="Email"
                    id="email"
                    type="email"
                    placeholder="Enter Email"
                    register={register}
                    rules={{
                      required: "This Field is required.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Enter a valid email address.",
                      },
                    }}
                    error={errors.email}
                    errorMessage={errors.email?.message}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <UnlimitInput
                    label="Phone"
                    id="phone"
                    type="text"
                    placeholder="Enter Phone No."
                    register={register}
                    rules={{
                      required: "This Field is required.",
                      pattern: {
                        value:
                          /^\+?\d+$/,
                        message: "Enter a valid phone number.",
                      },
                      maxLength: { value: 20, message: "This field cannot accept more than 20 characters." }
                    }}
                    error={errors.phone}
                    errorMessage={errors.phone?.message}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <UnlimitInput
                    label="User Name"
                    id="username"
                    type="text"
                    placeholder="Enter User Name"
                    register={register}
                    rules={{
                      required: "This Field is required.",
                    }}
                    error={errors.username}
                    errorMessage={errors.username?.message}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <UnlimitInput
                    label="Password"
                    id="password"
                    type="password"
                    placeholder="Enter Password"
                    register={register}
                    rules={{
                      required: "This field is required.",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long.",
                      },
                      pattern: {
                        value:
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                        message:
                          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
                      },
                    }}
                    error={errors.password}
                    errorMessage={errors.password?.message}
                  />
                </div>
                <div className="col-12 mt-2">
                  <Link to={all_routes.contactList} className="me-2">
                    <button type="button" className="btn btn-cancel">
                      Cancel
                    </button>
                  </Link>
                  <UnlimitSubmitButton isLoading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
