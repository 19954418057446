import React from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";

const UnlimitFieldDetailsRow = (props) => {
  const { heading, details, update, onClick, classes } = props;
  const { t } = useTranslation();
  return (
    <div className={`row border-bottom py-2 px-2 `}>
      <p className="col-4 fw-bolder fs-14 mb-0 ">{heading}</p>
      <p className={`${classes} col-8 fs-6`}>
        {details || "-"}
        {update && (
          <i
            onClick={() => onClick()}
            className="feather-edit-2 text-primary ms-2 cursor-pointer"
            title="Update Sale Price"
          ></i>
        )}
      </p>
    </div>
  );
};

export default UnlimitFieldDetailsRow;
