import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/product-group.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import UnlimitAttachement from "../../../components/ui-kit/unlimit-attachments";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { Link } from "react-router-dom/dist";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useTranslation } from "react-i18next";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import { useSelector } from "react-redux";

const ProductGroupForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit, formState, setValue, setError,control } = useForm();
  const { errors } = formState;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const vendors = useSelector((state) => state.vendors.vendors);
  const getProductGroup = async () => {
    await httpService
      .getProductGroup(id)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          setValue("name", data.name);
          setValue("number", data.number);
          setValue("is_active", data.is_active == 1 ? true : false);
          setValue("vendor_id", {
            ...data.vendor,
            label: data.vendor?.business_name,
            value: data.vendor?.id,
            logo: data.vendor?.logo,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      }).finally(()=>{
        setPageLoading(false)
      });
  };
  useEffect(() => {
    setVendor(vendors?.data?.data);
    if (id) {
      setPageLoading(true)
      getProductGroup();
    }
  }, [id]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const requestData = {
        name:data.name,
        number:data.number,
        is_active:data.is_active? 1:0,
        vendor_id: data.vendor_id?.id
      };
      if (id) {
        requestData.id = id
        await updateProductGroup(requestData);
      } else {
        await createProductGroup(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const createProductGroup = async (formData) => {
    try {
      await httpService
        .createProductGroup(formData,true)
        .then((response) => {
          if (response.status == 200) {
            notificationService.successMessage(
              t('productGroup.productGroupCreateSuccess')
            );
            setTimeout(() => {
              navigate(all_routes.productGroupList);
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
      } catch (error) {
        console.error("Error:", error);
    }
  };
  const updateProductGroup = async (formData) => {
    try {
      await httpService
        .updateProductGroup(formData,true)
        .then((response) => {
          if (response.status == 200) {
            notificationService.successMessage(
              t('productGroup.productGroupUpdateSuccess')
            );
            setTimeout(() => {
              navigate(all_routes.productGroupList);
            }, 1000);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
      } catch (error) {
        console.error("Error:", error);
      }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('productGroup.productGroup'), link: "/product-group-list" },
    { label: id ? t('productGroup.editProductGroup') : t('productGroup.addProductGroup'), active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">
                {id ? t('productGroup.editProductGroup') : t('productGroup.addProductGroup')}
              </h3>
              <h6></h6>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body add-product pb-0">
            {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
              <div>
              <div className="row">
              <div className="col-md-4">
                  <UnlimitSelect
                    label={t("vendor.vendor")}
                    id="vendor_id"
                    errors={errors}
                    control={control}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    placeholder={t("inputField.placeholder.chooseVendor")}
                    options={vendor}
                    showLogo={true}
                    optionLabel="business_name"
                    optionValue="id"
                  />
                </div>
                <div className="col-lg-4">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitInput
                      label={t('tableColumnName.groupName')}
                      id="name"
                      type="text"
                      placeholder={t('inputField.placeholder.enterName')}
                      register={register}
                      rules={{
                        required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                      }}
                      error={errors.name}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitInput
                      label={"Group Number"}
                      id="number"
                      type="number"
                      placeholder={t('inputField.placeholder.enterNumber')}
                      register={register}
                      rules={{
                        required: t("inputField.validation.required"),
                          maxLength: {
                            value: 9,
                            message: t("inputField.validation.maxLength", {
                              value: 9,
                            }),
                          },
                          validate: value => {
                            if (!/^\d+$/.test(value)) {
                              return t("inputField.validation.integer");
                            }
                            return true;
                          }
                      }}
                      error={errors.number}
                      errorMessage={errors.number?.message}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks">
                  <UnlimitToggle
                    isChecked={id ? false : true}
                    label={t('inputField.label.status')}
                    id="is_active"
                    register={register}
                  />
                </div>
              </div>
              <div className="col-lg-12 d-flex mb-4">
                <div className="me-3">
                  <Link to={all_routes.productGroupList}>
                    <button type="button" className="btn btn-cancel">
                    {t("general.button.cancel")}
                    </button>
                  </Link>
                </div>
                <UnlimitSubmitButton
                  label={id ? t("general.button.update")
                    : t("general.button.submit")}
                  isLoading={isLoading}
                />
              </div>
            </div>)}
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
    </div>
  );
};

export default ProductGroupForm;
