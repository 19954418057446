import React from "react";

const UnlimitRadio = ({
  name,
  id,
  value,
  label,
  onChange,
  register,
  rules,
  ...attr
}) => {
  return (
    <div className="ui-kit-unlimit-radio">
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        {...register(name, rules)}
        {...attr}
      />
      <label htmlFor={id} className="radio-label">
        {label}
      </label>
    </div>
  );
};
export default UnlimitRadio;
