import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createDistribution = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.inventory.distribution.create}`,
    true,
    data,
    false,
  );
};
const updateDistribution = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.inventory.distribution.update}`,
    true,
    data,
    true,
  );
};
const distributionList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.inventory.distribution.list}`,
    true,
    true,
    data,
  );
};
const deleteDistribution = async (id) => {
  const url = `${ENDPOINT.inventory.distribution.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getDistribution = async (id) => {
  const url = `${ENDPOINT.inventory.distribution.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const getMainStockItems = async (id,vendorId) => {
  const url = `${ENDPOINT.inventory.distribution.showMainStockList}`.replace(":id", id).replace(":vendorId", vendorId);
  return await API.getMethod(url, true);
};
export default {
  getDistribution,
  createDistribution,
  updateDistribution,
  distributionList,
  deleteDistribution,
  getMainStockItems
};
