import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createUser = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.userManage.user.create}`,
    true,
    data,
    true,
  );
};
const updateUser = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.userManage.user.update}`,
    true,
    data,
    true,
  );
};
const userList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.userManage.user.list}`,
    true,
    true,
    data,
  );
};
const deleteUser = async (id) => {
  const url = `${ENDPOINT.userManage.user.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getUser = async (id) => {
  const url = `${ENDPOINT.userManage.user.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getUser,
  createUser,
  updateUser,
  userList,
  deleteUser,
};
