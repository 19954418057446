import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createContact = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.contact.create}`,
    true,
    data,
    json ? false : true,
  );
};
const updateContact = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.contact.update}`,
    true,
    data,
    json ? false :true,
  );
};
const contactList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.peoples.contact.list}`,
    true,
    true,
    data,
  );
};
const deleteContact = async (id) => {
  const url = `${ENDPOINT.peoples.contact.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getContact = async (id) => {
  const url = `${ENDPOINT.peoples.contact.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getContact,
  createContact,
  updateContact,
  contactList,
  deleteContact,
};
