import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/inventory/main-stock.service";
import usePagination from "../../../custom-hooks/usePagination";
import { Link, useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useForm } from "react-hook-form";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/notification.service";
import { useTranslation } from "react-i18next";
import { hydrateErrors } from "../../../utils/error.utils";
import MainStockFromPurchaseSearch from "./main-stock-from-purchase-search";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

const MainStockFromPurchase = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseItemsList, setPurchaseItemsList] = useState([]);
  const [filteredItemsList, setFilteredItemsList] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [initialQuantities, setInitialQuantities] = useState({});
  const [productNameFilter, setProductNameFilter] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [eanFilter, setEanFilter] = useState(null);
  const { control, handleSubmit, register, formState, setError, clearErrors } =
    useForm();
  const { errors } = formState;
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (purchaseItemsList?.items) {
      const quantities = {};
      const initialQuantities = {};
      purchaseItemsList?.items?.forEach((item) => {
        quantities[item.id] = item.quantity;
        initialQuantities[item.id] = 0;
      });
      setInitialQuantities(quantities);
      setQuantities(initialQuantities);
    }
  }, [purchaseItemsList]);

  useEffect(() => {
    filterItems();
  }, [productNameFilter, eanFilter, purchaseItemsList]);

  const filterItems = () => {
    const filtered = purchaseItemsList?.items?.filter((item) => {
      return (
        String(item?.ean)
          .toLowerCase()
          .includes(String(eanFilter)?.toLowerCase()) ||
        item?.catalog?.name
          ?.toLowerCase()
          .includes(productNameFilter?.toLowerCase())
      );
    });
    setFilteredItemsList(
      !productNameFilter && !eanFilter ? purchaseItemsList?.items : filtered
    );
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  const handleQuantityChange = (id, value) => {
    const initialQuantity = initialQuantities[id] || 0;
    if (value > initialQuantity) {
      setError(`quantity_${id}`, {
        type: "manual",
        message: t("inputField.validation.quantityExceedsInitial"),
      });
      setIsSubmitDisabled(true);
    } else {
      clearErrors(`quantity_${id}`);
      setIsSubmitDisabled(false);
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [id]: value,
      }));
    }
    checkForErrors();
  };
  const checkForErrors = () => {
    const hasErrors = Object.values(errors).some(
      (error) => error !== undefined
    );
    setIsSubmitDisabled(hasErrors);
  };
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record, index) => (
        <div>
          {record.ean}
          {errors?.main_stock?.length > 0 && errors?.main_stock[index]?.ean && (
            <span className="error">
              {errors?.main_stock[index]?.ean?.message}
            </span>
          )}
        </div>
      ),
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt={t("tableColumnName.productName")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.catalog?.name}
        </div>
      ),
    },
   /*  {
      title: t("tableColumnName.salePrice"),
      dataIndex: "sale_price",
      align: 'right',
      render: (text, record) =>  Number(text)?.toFixed(2),
    }, */
    {
      title: t("tableColumnName.receivingQuantity"),
      dataIndex: "quantity",
      render: (text, record) => (
        <UnlimitInput
          id={`quantity_${record.id}`}
          type="number"
          defaultValue={0}
          borderError={true}
          register={register}
          rules={{
            min: {
              value: 0,
              message: t("inputField.validation.minValue", {
                min: 0,
              }),
            },
          }}
          error={errors?.[`quantity_${record.id}`]}
          // errorMessage={errors?.[`quantity_${record.id}`]?.message}
          onChange={(e) =>
            handleQuantityChange(record.id, parseInt(e.target.value))
          }
        />
      ),
    },
    {
      title: t("tableColumnName.remainingQuantity"),
      dataIndex: "remaining_quantity",
      align:"center",
      render: (text, record) => {
        const initialQuantity = initialQuantities[record.id] || 0;
        const inputQuantity = quantities[record.id] || 0;
        const remainingQuantity = initialQuantity - inputQuantity;
        return <span>{remainingQuantity}</span>;
      },
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("mainStock.mainStock"), link: all_routes.mainStockList },
    { label: t("mainStock.addMainStockFromPurchase"), active: true },
  ];

  const onSubmit = () => {
    setIsLoading(true);
    const priceline = purchaseItemsList?.items?.map((item) => {
        const quantity = quantities[item.id] || 0;
        return {
          ean: item.ean,
          purchase_id: item.purchase_id,
          purchase_item_id: item.id,
          quantity: quantity,
          vendor_id: purchaseItemsList?.vendor?.id,
        };
      })
      .filter((p) => p.quantity > 0);
    if (priceline.length <= 0) {
      setIsLoading(false);
      notificationService.errorMessage(t("general.prompts.atleastOneStock"));
      return;
    }
    if (Object.keys(errors).length === 0) {
      const payload = {
        main_stock: priceline,
      };
      createMainStockFromPurchase(JSON.stringify(payload));
    }
  };

  const createMainStockFromPurchase = async (formData) => {
    httpService
      .createMainStock(formData)
      .then((response) => {
        if (response && response.status === 200) {
          notificationService.successMessage(
            t("mainStock.mainStockCreateSuccess")
          );
          navigate(all_routes.mainStockList);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.validation_error?.errors) {
          // hydrateErrors(error.validation_error?.errors, setError);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("mainStock.mainStock")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <MainStockFromPurchaseSearch
            setPurchaseItemsList={setPurchaseItemsList}
            setLoading={setLoading}
          />
          {purchaseItemsList?.items?.length > 0 && (
            <div className="row filters mb-3">
              <div className="col-lg-3 col-sm-4 col-12 mb-2">
                <input
                  type="text"
                  id="productNameFilter"
                  placeholder={t("tableColumnName.productName")}
                  className="form-control form-control-sm formsearch-custom ps-2"
                  value={productNameFilter}
                  onChange={(e) => setProductNameFilter(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-sm-4 col-12 mb-2">
                <input
                  type="text"
                  placeholder={t("inputField.placeholder.enterEAN")}
                  className="form-control form-control-sm formsearch-custom ps-2"
                  value={eanFilter}
                  onChange={(e) => setEanFilter(e.target.value)}
                />
              </div>
            </div>
          )}
          {filteredItemsList?.length > 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card table-list-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={filteredItemsList}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <Link to={all_routes.mainStockList} className="me-2">
                  <button type="button" className="btn btn-cancel">
                    {t("general.button.cancel")}
                  </button>
                </Link>
                <UnlimitSubmitButton
                  disabled={isSubmitDisabled}
                  isLoading={isLoading}
                  label={t("general.button.submit")}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainStockFromPurchase;
