import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createDesignation = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.designations.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateDesignation = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.peoples.designations.update}`,
    true,
    data,
    json?false:true,
  );
};
const designationsList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.peoples.designations.list}`,
    true,
    true,
    data,
  );
};
const deleteDesignation = async (id) => {
  const url = `${ENDPOINT.peoples.designations.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getDesignation = async (id) => {
  const url = `${ENDPOINT.peoples.designations.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getDesignation,
  createDesignation,
  updateDesignation,
  designationsList,
  deleteDesignation,
};
