import ReactCountryFlag from "react-country-flag";
import React from "react";

const UnlimitCountryIcon = ({ iso2, width, height }) => {
  return (
    <ReactCountryFlag
      style={{
        width: width ? width : "24px",
        height: height ? height : "24px",
      }}
      svg
      className="emojiFlag"
      countryCode={iso2}
    />
  );
};
export default UnlimitCountryIcon;
