import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createMainStock = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.inventory.mainStock.create}`,
    true,
    data,
    false,
  );
};
const createMainStockFromPurchase = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.inventory.mainStock.create}`,
    true,
    data,
    true,
  );
};
const updateMainStock = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.inventory.mainStock.update}`,
    true,
    data,
    true,
  );
};
const mainStockList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.inventory.mainStock.list}`,
    true,
    true,
    data,
  );
};
const deleteMainStock = async (id) => {
  const url = `${ENDPOINT.inventory.mainStock.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getMainStock = async (id) => {
  const url = `${ENDPOINT.inventory.mainStock.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getMainStock,
  createMainStock,
  updateMainStock,
  mainStockList,
  deleteMainStock,
  createMainStockFromPurchase
};
