import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createTimezone = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.settings.timezone.create}`,
    true,
    data,
    true,
  );
};
const updateTimezone = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.settings.timezone.update}`,
    true,
    data,
    true,
  );
};
const timezoneList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.timezone.list}`, true, true, data);
};
const deleteTimezone = async (id) => {
  const url = `${ENDPOINT.settings.timezone.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getTimezone = async (id) => {
  const url = `${ENDPOINT.settings.timezone.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getTimezone,
  createTimezone,
  updateTimezone,
  timezoneList,
  deleteTimezone,
};
